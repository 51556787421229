import { Formulario, Input, ImageInput } from "eureka-design"
import { useUploadImageLugaresMutation, useCrearDomicilioMutation } from "../../js/service/lugares.js";
import { useCrearFranquiciaMutation } from "../../js/service/franquicias.js";
import { useEffect, useState } from 'react';
import { handlerMutation } from "../../js/util.js"


export default function FormFranquicias({ UpProps, franquicia, domicilio, prevUrl, prevFile, nombre, calle, interior, entrecalles, referencias, usuario }) {
    const [idFranquicia, setIdFranquicia] = useState(franquicia);
    const [idDomicilio, setIdDomicilio] = useState(domicilio);

    const [CrearFranquicia, { isLoading: inputIsloading, isError: inputIsError, isSuccess: inputIsSuccess }] = useCrearFranquiciaMutation();
    const [CrearDomicilio, { isLoading: domLoading, isError: domIsError, isSuccess: domIsSuccess }] = useCrearDomicilioMutation();
    const [uploadFoto, { isLoading: imageisLoading, isError: imageIsError, isSuccess: imageIsSuccess }] = useUploadImageLugaresMutation();

    const handlerInput = (value, nombre) => {
        handlerMutation(CrearFranquicia, { id: idFranquicia, body: { [nombre]: value, tipo: "Franquicia", usuario: usuario.id } }, (r) => {
            if (r?.mensaje?.id) {
                setIdFranquicia(r.mensaje.id)
                UpProps({ franquicia: r.mensaje.id })
            }
        })
        UpProps({ [nombre]: value })
    }

    const handlerDom = (value, nombre) => {
        handlerMutation(CrearDomicilio, { id: idDomicilio, body: { [nombre]: value, usuario: usuario.id } }, (r) => {
            if (r?.mensaje?.id) {
                setIdDomicilio(r.mensaje.id)
                UpProps({ domicilio: r.mensaje.id })
                handlerInput(r?.mensaje?.id, "domicilio")
            }
        })
        UpProps({ [nombre]: value })
    }

    const handlerPhoto = (e) => {
        handlerMutation(uploadFoto, { id: idFranquicia, file: e.target.files[0], carpeta: "ft_franquicias", nombre: "portada" }, (r) => {
            if (r?.mensaje?.id) {
                setIdFranquicia(r.mensaje.id)
                UpProps({ portada: r.mensaje.id })
            }
        })
        UpProps({ prevFile: e.target.files[0] })
    }

    return (
        <Formulario
            titulo="Agrega una franquicia"
            isLoading={[inputIsloading, domLoading, imageisLoading]}
            isError={[inputIsError, imageIsError, domIsError]}
            isSuccess={[inputIsSuccess, imageIsSuccess, domIsSuccess]}>
            <ImageInput
                width={250}
                height={250}
                changeFunction={handlerPhoto}
                preview={prevUrl}
                file={prevFile}
            >
            </ImageInput>
            <Input
                min={5}
                max={255}
                changeFunction={handlerInput}
                isLoading={inputIsloading}
                placeholder={"Nombre"}
                nombre={"nombre"}
                type={"text"}
                value={nombre}
                iconLeft={"fa-file-contract"}
            />
            <div style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
                width: "100%"
            }}>
                <Input
                    min={5}
                    max={150}
                    changeFunction={handlerDom}
                    isLoading={inputIsloading}
                    placeholder={"Calle"}
                    nombre={"calle"}
                    type={"text"}
                    value={calle}
                    iconLeft={" fa-road"}
                />
                <Input
                    min={1}
                    max={5}
                    changeFunction={handlerDom}
                    isLoading={inputIsloading}
                    placeholder={"Numero exterior"}
                    nombre={"interior"}
                    type={"text"}
                    value={interior}
                    iconLeft={"fa-building"}
                />
            </div>

            <Input
                min={0}
                max={255}
                changeFunction={handlerDom}
                isLoading={inputIsloading}
                placeholder={"Entre calles"}
                nombre={"entrecalles"}
                type={"text"}
                value={entrecalles}
                iconLeft={"fa-road-circle-exclamation"}
            />
            <Input
                min={0}
                max={255}
                changeFunction={handlerDom}
                isLoading={inputIsloading}
                placeholder={"Referencias"}
                nombre={"referencias"}
                type={"textarea"}
                value={referencias}
                iconLeft={" fa-map-marker-alt"}
            />
        </Formulario>
    )
}
