// export const config = {
//     keyAPICrufdek: 'qoQ1DpMEyAB5YtCYwag2nRYkffA21pYFEYGF55PY',
//     crufdekURLV1: 'https://crud.eksuite.com/api/v1/CRUFDEK',
//     crufdekURL: 'https://crud.eksuite.com/api/v2/CRUFDEK',
//     crufdekFileURL: 'https://crud.eksuite.com/api/GetFile',
//     dataBaseVersionURL: 'https://crud.eksuite.com/api',
//     expCookie: { cantidad: 15, tipo: DIA },
//     inactividad: { cantidad: 15, tipo: DIA },
//     tiempoRefrescar: { cantidad: 50, tipo: MIN },
//     roles: ['administrador general', 'administrador eventos', 'administrador lugares', "administrador franquicias", "cliente"],
// }

import { DIA, MIN } from 'account-react-eurekasigma'

export const DOMINIO = window.location.origin
export const config = {
    keyAPICrufdek: 'qoQ1DpMEyAB5YtCYwag2nRYkffA21pYFEYGF55PY',
    crufdekURL: 'https://crud.eksuite.com/api/v2/CRUFDEK',
    crufdekURLV1: 'https://crud.eksuite.com/api/v1/CRUFDEK',
    crufdekFileURL: 'https://crud.eksuite.com/api/GetFile',
    keyAccount: 'Sc5YuIoIapS0xKGEOkhaNnTBdUuzrvaRFNhxvKdW',
    colorPrimario: '#362AD8',
    colorSecundario: '#36A7FF',
    colorBlanco: '#FFF',
    colorNegro: '#000',
    expCookie: { cantidad: 15, tipo: DIA },
    inactividad: { cantidad: 15, tipo: DIA },
    tiempoRefrescar: { cantidad: 240, tipo: MIN },
    nomCookieUsuario: 'usuario',
    nomCookieToken: 'tokenSesion',
}