
import { Card, FolioSection, Button, Input } from "eureka-design"
import { useState, useEffect } from "react"

export default function PasarelaPago({ UpProps, Push, ShowAlert, usuario }) {
    const [metodosPago, setMetodosPago] = useState([])
    const [metodoSeleccionado, setMetodoSeleccionado] = useState(null)
    const [nuevoMetodo, setNuevoMetodo] = useState({
        tarjeta: "",
        titular: "",
        mes: "",
        year: "",
        cvv: ""
    })

    return (
        <div className="pasarela-pagos">
            <Card>
                <div className="pasarela-pagos__header">
                    <div className="pasarela-pagos__total">
                        $ 30.000
                    </div>
                </div>
                <div className="pasarela-pagos__body">
                    <div className="pasarela-pagos__detalle">
                        <FolioSection titulo={"Nombre"}>
                            <span>Jahel Ornelas Guillen</span>
                        </FolioSection>
                        <FolioSection titulo={"Fecha"}>
                            <span>21 de Marzo del 2024</span>
                        </FolioSection>
                        <FolioSection titulo={"Concepto"}>
                            <span>Pago de servicios</span>
                        </FolioSection>
                    </div>
                    <div className="pasarela-pagos_metodos">
                        <h4>Elige un método de pago</h4>
                        <div className="pasarela-pagos_metodos-item">
                            <input type="radio" />
                            <div className="pasarela-pagos_metodo">
                                **** **** **** 1234
                            </div>
                            <div className="pasarela-pagos_tipo">
                                <i className="fa-brands fa-cc-visa" />
                            </div>
                        </div>
                        <Input
                            value={nuevoMetodo.titular}
                            changeFunction={(value, nombre) => {
                                setNuevoMetodo({
                                    ...nuevoMetodo,
                                    titular: value
                                })
                            }}
                            placeholder={"Nombre del titular"}
                        />
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px"
                        }}>

                            <Input
                                value={nuevoMetodo.tarjeta}
                                changeFunction={(value, nombre) => {
                                    setNuevoMetodo({
                                        ...nuevoMetodo,
                                        tarjeta: value
                                    })
                                }}
                                placeholder={"Numero de tarjeta"}
                            />
                            <Input
                                changeFunction={(value, nombre) => {
                                    setNuevoMetodo({
                                        ...nuevoMetodo,
                                        cvv: value
                                    })
                                }}
                                value={nuevoMetodo.cvv}
                                placeholder={"CVV"}
                            />

                        </div>

                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px"
                        }}>

                            <Input
                                value={nuevoMetodo.mes}
                                changeFunction={(value, nombre) => {
                                    setNuevoMetodo({
                                        ...nuevoMetodo,
                                        mes: value
                                    })
                                }}
                                placeholder={"Mes de vencimiento"}
                            />
                            <Input
                                value={nuevoMetodo.year}
                                changeFunction={(value, nombre) => {
                                    setNuevoMetodo({
                                        ...nuevoMetodo,
                                        year: value
                                    })
                                }}
                                placeholder={"Año de vencimiento"}
                            />
                        </div>

                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            justifyContent: "flex-end"
                        }}>

                            <Button texto="Agregar nuevo metodo"
                                onClick={() => {
                                    if (nuevoMetodo.titular === "" || nuevoMetodo.tarjeta === "" || nuevoMetodo.cvv === "" || nuevoMetodo.mes === "" || nuevoMetodo.year === "") {
                                        ShowAlert({
                                            titulo: "Espera...",
                                            mensaje: "Debes llenar todos los campos para agregar un nuevo método de pago",
                                            icono: "warning",
                                            time: 5
                                        })

                                    }
                                }}

                            >

                            </Button>
                        </div>
                    </div>
                    <div className="pasarela-pagos__footer">
                        <Button texto="Realizar Pago" width="100%"
                        >

                        </Button>
                    </div>

                </div>
            </Card>
        </div>
    )
}