import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { config } from "../../config/config"
import { EkQuery } from "../query"

const TABLA_USUARIO = "EurekaAcUsuario"

export const usuarioApi = createApi({
    reducerPath: 'usuarioApi',
    baseQuery: fetchBaseQuery({ baseUrl: config.crufdekURL }),
    tagTypes: ['Usuarios'],
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        obtenerUsuario: builder.query({
            query: (data) => {
                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${data.token}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: new EkQuery(TABLA_USUARIO)
                        .where(data.col, '=', data.val)
                        .first()
                }
            },
        }),
    })
})

export const {
    useObtenerUsuarioQuery,
} = usuarioApi