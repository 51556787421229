import { AccountEurekaSigma } from 'account-react-eurekasigma'
import logo from '../img/logosv2/3.png'
import logoPiePagina from '../img/logosv2/Logo2.png'
import { DOMINIO, config } from '../config/config'

const TABLA_USUARIO = 'EurekaAcUsuario'

const InicioSesion = () => {
    const parametrosURL = new URLSearchParams(window.location.search)
    const inactivo = parametrosURL.get('inactivo')

    const configuracion = {
        logo: logo,
        colorPrincipal: config.colorPrimario,
        serviciosAccesoMostrar: ['google', 'microsoft'],
        redireccion: `${DOMINIO}/procesar_credenciales`,
        keyAccount: config.keyAccount,
        key: config.keyAPICrufdek,
        tabla: TABLA_USUARIO,
        aceptaRegistros: 1,
        inactivo: inactivo ? inactivo : 0,
    }

    return (
        <AccountEurekaSigma config={configuracion} />
    )
}

export default InicioSesion