import { createBrowserRouter } from "react-router-dom";
import InicioSesion from "../pages/InicioSesion";
// import ComprobacionSesion from "../pages/ComprobacionSesion";
import Inicio from "../pages/Inicio";
import TerminarSesion from "../pages/TerminarSesion";
import ProcesarCredenciales from "../pages/ProcesarCredenciales";
import { NotFound } from "eureka-design";
// import NotFound from "../pages/Errors/NotFound";
// import Navegador from "../pages/layouts/navegador";
// import Login from "../pages/layouts/login";

export const router = createBrowserRouter([
    {
        path: "/",
        element: <Inicio />,
        // errorElement: <NotFound />,

    },
    {
        path: "/iniciar_sesion",
        element: <InicioSesion />,
        // errorElement: <NotFound />,
    },
    {
        path: '/procesar_credenciales',
        element: <ProcesarCredenciales />,
        // errorElement: <NotFound />
    },
    {
        path: '/cerrar_sesion',
        element: <TerminarSesion />,
        // errorElement: <NotFound />
    },

])