import nouser from "../img/nouser.jpg"
import { config } from "../config/config"

export const MESES = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
]

export const handlerMutation = async (mutacion, body, endQuery, errorQuery) => {
    if (mutacion && typeof mutacion === "function") {
        return await mutacion(body).unwrap()
            .then(endQuery ? endQuery : (data) => { })
            .catch((error) => { if (error?.status === 401) errorQuery ? errorQuery(error) : console.log(error) })
    }
}

export const capitalize = (cadena = '') => {
    cadena = cadena.toLowerCase()
    let firstLetter = cadena.charAt(0)
    let firstLetterCap = firstLetter.toUpperCase()
    let remainingLetters = cadena.slice(1)
    return firstLetterCap + remainingLetters
}

export const obtenerFoto = (usuario = {}) => {
    if (usuario?.foto) {
        return `${config.crufdekFileURL}/ft_usuario/${usuario.foto}`
    }
    else {
        return nouser
    }
}

export const obtenerRol = (usuario = {}) => {
    if (usuario?.eureka_cr_rol_usuarios[0]?.eureka_cr_rol) {
        return usuario.eureka_cr_rol_usuarios[0].eureka_cr_rol.nombre
    }
    else {
        return null
    }
}

export const numberFormat = (number = 0.0, decimals = 2, decPoint = '.', thousandsSep = ',', dollarSimb = null) => {
    number = number ? number : 0
    number = Number(number).toFixed(decimals)
    let numberStr = String(number)
    let posDec = numberStr.indexOf('.')
    let numberIntStr = numberStr.slice(0, posDec)
    let decimalsStr = numberStr.slice(posDec).replace('.', decPoint)
    let digitsCount = 0
    let numberFormat = []


    for (let i = numberIntStr.length - 1; i >= 0; i--) {
        if (digitsCount === 3) {
            digitsCount = 0
            numberFormat.push(thousandsSep)
        }

        digitsCount++
        numberFormat.push(numberIntStr[i])
    }
    numberStr = numberFormat.reverse().join('') + decimalsStr

    if (dollarSimb) {
        return `${dollarSimb} ${numberStr}`
    }
    else {
        return numberStr
    }
}

export const isObjectEmpty = (objectName) => {
    return Object.keys(objectName).length === 0
}

export const adapatarDataTabla = (data = [], config = []) => {
    if (data.length === 0 || config.length === 0) return data
    else {
        return data.map((dato) => {
            let datoAdaptado = {
                ...dato
            }
            let valor = null
            let profundidades = null
            let profundidad = null
            let j = null

            for (let i = 0; i < config.length; i++) {
                profundidades = config[i].profundidad

                for (j = 0; j < profundidades.length; j++) {
                    profundidad = profundidades[j]
                    if (valor) {
                        valor = (profundidad === 'array') ? valor.length : valor[profundidad]
                    }
                    else {
                        valor = datoAdaptado[profundidad]
                    }
                }
                datoAdaptado[config[i].nomNuevoCampo] = valor
                valor = null
            }

            return datoAdaptado
        })
    }

}