import { configureStore } from "@reduxjs/toolkit";
// import { setupListeners } from '@reduxjs/toolkit/query'
// import NavegatorReducer from "../js/feactures/NavegatorSlice";
import HandlerQuerySlice from "../js/feactures/HandlerQuerySlice";
import sesionSlice from "../js/feactures/SesionSlice";
import { usuarioApi } from "../js/service/usuario";
import { usuariosApi } from "../js/service/usuarios";
import { rolApi } from "../js/service/rol";
import { eventosApi } from "../js/service/eventos";
import { lugaresApi } from "../js/service/lugares";
import { franquiciasApi } from "../js/service/franquicias";
import { productosApi } from "../js/service/productos";
import { fiscalApi } from "../js/service/fiscal";
import { cuentaFiscalApi } from "../js/service/fiscal";
export const store = configureStore({
    reducer: {
        // navegadorSlice: NavegatorReducer,
        handlerQuery: HandlerQuerySlice,
        sesionSlice: sesionSlice,
        [usuarioApi.reducerPath]: usuarioApi.reducer,
        [usuariosApi.reducerPath]: usuariosApi.reducer,
        [rolApi.reducerPath]: rolApi.reducer,
        [eventosApi.reducerPath]: eventosApi.reducer,
        [lugaresApi.reducerPath]: lugaresApi.reducer,
        [franquiciasApi.reducerPath]: franquiciasApi.reducer,
        [productosApi.reducerPath]: productosApi.reducer,
        [fiscalApi.reducerPath]: fiscalApi.reducer,
        [cuentaFiscalApi.reducerPath]: cuentaFiscalApi.reducer,
    },
    middleware:
        (getdefaultMiddleware) =>
            getdefaultMiddleware({
                serializableCheck: false
            }).concat([
                usuarioApi.middleware,
                usuariosApi.middleware,
                rolApi.middleware,
                eventosApi.middleware,
                lugaresApi.middleware,
                franquiciasApi.middleware,
                productosApi.middleware,
                fiscalApi.middleware,
                cuentaFiscalApi.middleware,
            ])
    // middleware: (getDefaultMiddleware) => [...getDefaultMiddleware({
    //     serializableCheck: false
    // }), productosApi.middleware],

});



