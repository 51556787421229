import { Formulario, Input, ImageInput } from "eureka-design"
import { useCrearProductosMutation, useUploadImageMultimediaMutation } from "../../js/service/productos.js";
import { useEffect, useState } from 'react';
import { handlerMutation } from "../../js/util.js"


export default function FormProductos({ UpProps, producto, prevUrl, prevFile, nombre, clave, costo, franquicia,
    categoria = null, usuario }) {
    const [idProducto, setIdProducto] = useState(producto);

    const [CrearProducto, { isLoading: inputIsloading, isError: inputIsError, isSuccess: inputIsSuccess }] = useCrearProductosMutation();
    const [uploadFoto, { isLoading: imageisLoading, isError: imageIsError, isSuccess: imageIsSuccess }] = useUploadImageMultimediaMutation();

    const handlerInput = (value, nombre) => {
        handlerMutation(CrearProducto, {
            id: idProducto, body: {
                [nombre]: value,
                sucursal: franquicia,
                nivel: categoria,
                usuario: usuario.id
            }
        }, (r) => {
            if (r?.mensaje?.id) {
                setIdProducto(r.mensaje.id)
                UpProps({ producto: r.mensaje.id })
            }
        })
        UpProps({ [nombre]: value })
    }

    const handlerPhoto = (e) => {
        handlerMutation(uploadFoto, { id: null, file: e.target.files[0], carpeta: "ft_productos", nombre: "archivo" }, (r) => {
            if (r?.mensaje?.id) {
                setIdProducto(r.mensaje.id)
                handlerInput(r?.mensaje?.id, "foto")
            }
        })
        UpProps({ prevFile: e.target.files[0] })
    }

    return (
        <Formulario
            titulo="Agrega un Producto"
            isLoading={[inputIsloading, imageisLoading]}
            isError={[inputIsError, imageIsError]}
            isSuccess={[inputIsSuccess, imageIsSuccess]}>
            <ImageInput
                width={250}
                height={250}
                changeFunction={handlerPhoto}
                preview={prevUrl}
                file={prevFile}
            >
            </ImageInput>
            <Input
                min={2}
                max={255}
                changeFunction={handlerInput}
                isLoading={inputIsloading}
                placeholder={"Nombre"}
                nombre={"nombre"}
                type={"text"}
                value={nombre}
                iconLeft={"fa-file-contract"}
            />
            <Input
                min={2}
                max={255}
                changeFunction={handlerInput}
                isLoading={inputIsloading}
                placeholder={"Clave de producto"}
                nombre={"clave"}
                type={"text"}
                value={clave}
                iconLeft={" fa-file-lines"}
            />
            <Input
                max={9999}
                changeFunction={handlerInput}
                isLoading={inputIsloading}
                placeholder={"Costo"}
                nombre={"costo"}
                type={"precio"}
                value={costo}
                iconLeft={" fa-dollar-sign"}
            />
        </Formulario>
    )
}
