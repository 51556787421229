import { Tabla, buildData, ChipEstatus, ChipContact } from "eureka-design"
import { useGetEventosGruposQuery } from "../../js/service/eventos"
import { useState, useEffect } from "react"

export default function GruposEventos({ UpProps, Push, getPermiso, busqueda = null, roles, usuario }) {
    const [search, setSearch] = useState(busqueda)
    const { data, isLoading, isFetching } = useGetEventosGruposQuery({ search: search, roles, usuario })
    const [sinEstadiosKey, setSinEstadiosKey] = useState(0);
    const [pendientesKey, setPendientesKey] = useState(0);
    const [canceladosKey, setCanceladosKey] = useState(0);
    const [activosKey, setActivosKey] = useState(0);

    useEffect(() => {
        if (data) {
            const sinEstadios = data.filter((item) => item.eureka_bs_sucursale === null)
            if (sinEstadios.length > 0) {
                setSinEstadiosKey(sinEstadios.length)
            }
            const pendientes = data.filter((item) => item.estatus === "lleno")
            if (pendientes.length > 0) {
                setPendientesKey(pendientes.length)
            }
            const cancelados = data.filter((item) => item.estatus === "inactivo")
            if (cancelados.length > 0) {
                setCanceladosKey(cancelados.length)
            }
            const activos = data.filter((item) => item.estatus === "activo" && item.eureka_bs_sucursale)
            if (activos.length > 0) {
                setActivosKey(activos.length)
            }
        }
    }, [data, setSinEstadiosKey, setPendientesKey, setCanceladosKey])

    const getTotalEventos = (item) => {
        let total = 0;
        item?.eureka_bs_agrupaciones?.forEach((contador) => {
            total += 1;
        })
        return total;
    }

    const BotonesRow = () => {
        const botones = [];
        if (getPermiso("cu_eventos")) {
            botones.push([
                {
                    icono: "fa-solid fa-file-edit",
                    tooltip: "Editar",
                    mutacion: (item) => {
                        if (roles.RolGeneral) {
                            return true;
                        } else if (roles.RolLugares || roles.RolEventos) {
                            return item.usuario === usuario.id;
                        }
                    },
                    onClick: (item) => {
                        item = item.item;
                        Push('formGrupos', {
                            sucursal: item.eureka_bs_sucursale?.id ?? null,
                            grupo: item.id, portada: null, nombre: item.nombre, descripcion: item.descripcion,
                            precio: item.precio ?? null,
                            estadioName: item.eureka_bs_sucursale?.nombre ?? null,
                            prevFile: undefined,
                            usuario: { id: item.eureka_ac_usuario?.id ?? null },
                            prevUrl: `https://crud.eksuite.com/api/GetFile/${item.eureka_cr_multimedia?.carpeta}/${item.eureka_cr_multimedia?.archivo}`
                        })
                    },
                }
            ])
        }
        // botones.push({
        //     icono: "fa-solid fa-user-check",
        //     tooltip: "Asistencias",
        //     onClick: (item) => {
        //         item = item.item;
        //         Push('asistencias', { evento: item.id })
        //     },
        // })
        return botones;
    }

    return <Tabla
        rowSize="small"
        busqueda={{
            placeholder: "Buscar grupos...",
            value: search,
            fn: (value) => {
                setSearch(value === "" ? null : value)
                UpProps({ busqueda: value === "" ? null : value })
            }
        }}
        checkbox={false}
        onClickRow={(item) => {
            Push("perfilEventosGrupos", { grupo: item.item.id })
        }}
        keys={[
            {
                text: "Todos",
                detalle: data && data.length > 0 ? data.length : 0,
                filtro: () => true
            },

            {
                text: "Activos",
                detalle: activosKey,
                filtro: item => {
                    return item.item.estatus === "activo" && item.item.eureka_bs_sucursale;
                }
            },
            {
                text: "Cancelados",
                detalle: canceladosKey,
                filtro: item => {
                    return item.estatus === "inactivo";
                }
            },
            {
                text: "Sin estadio",
                detalle: sinEstadiosKey,
                filtro: item => {
                    return item.eureka_bs_sucursale === null;
                }
            },
            {
                text: "Saturados",
                detalle: pendientesKey,
                filtro: item => {
                    return item.estatus === "lleno";
                }
            },

        ]}
        botonesHead={getPermiso("cu_eventos") ? [
            {
                icono: "fa-solid fa-plus",
                tooltip: "Agregar grupo",
                onClick: (item) => {
                    Push("formGrupos", { sucursal: null, grupo: null, portada: null, nombre: null, descripcion: null, precio: null, prevFile: undefined, estadioName: null, prevUrl: null })
                }
            }
        ] : []}
        headers={[
            { text: "Grupo", key: "nombre", type: "text" },
            { text: "Tot. eventos", key: "eventos", type: "precio" },
            { text: "Estadio", key: "sucursales", type: "text" },
            { text: "", key: "estatus", type: "text" }
        ]}
        botonesRow={BotonesRow()}
        data={buildData(data ?? [], [
            { key: "nombre", type: "text" },
            {
                key: "eventos", type: "eventos", value: (item) => {
                    return getTotalEventos(item)
                }, text: (item) => {
                    return getTotalEventos(item)
                }
            },
            {
                key: "sucursales", type: "text", text: (item) => {
                    if (item?.eureka_bs_sucursale) {
                        return <ChipContact texto={item?.eureka_bs_sucursale?.nombre}
                            onClick={() => {
                                Push("lugar", { perfilEstadio: item?.eureka_bs_sucursale?.id }, "Lugares")
                            }}
                            error={"/img/noimage.jpg"}
                            imagen={`https://crud.eksuite.com/api/GetFile/ft_estadios/${item.eureka_bs_sucursale?.portada}/imagen`} />
                    }
                },
                value: (item) => item?.eureka_bs_sucursale?.nombre ?? ""
            },
            {
                key: "estatus", type: "text",
                value: (item) => {
                    let estatus = item.estatus;

                    if (!item.eureka_bs_sucursale) {
                        estatus = "Sin estadio"
                    }
                    return estatus;
                },
                text: (item) => {
                    let chip;
                    if (item.estatus === "activo") {
                        chip = <ChipEstatus texto={"Activo"} tipo="Aceptado" />
                    }

                    if (item.estatus === "cancelado") {
                        chip = <ChipEstatus texto={"Cancelado"} tipo="Error" />
                    }

                    if (item.estatus === "lleno") {
                        chip = <ChipEstatus texto={"Saturado"} tipo="Pendiente" />
                    }

                    if (!item.eureka_bs_sucursale) {
                        chip = <ChipEstatus texto={"Sin estadio"} tipo="Pendiente" />
                    }
                    return chip;
                }
            }

        ])}
    >

    </Tabla >
}
