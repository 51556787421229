import { Tabla, buildData, ChipEstatus, Miniatura, ChipContact } from "eureka-design"
import { useGetSucursalesQuery } from "../../js/service/franquicias";
import { config } from "../../config/config";
import { useState, useEffect } from "react"

export default function Sucursales({ UpProps, Push, ChangeScene, busqueda, franquicia, usuario, roles }) {
    const [search, setSearch] = useState(busqueda)
    const { data, isLoading, isFetching } = useGetSucursalesQuery({ search: search, franquicia: franquicia, usuario, roles });
    const [pendientesKey, setPendientesKey] = useState(0);
    const [canceladosKey, setCanceladosKey] = useState(0);
    const [activosKey, setActivosKey] = useState(0);

    useEffect(() => {
        if (data) {

            const pendientes = data.filter((item) => item.estatus === "nuevo")
            if (pendientes.length > 0) {
                setPendientesKey(pendientes.length)
            }
            const cancelados = data.filter((item) => item.estatus === "inactivo")
            if (cancelados.length > 0) {
                setCanceladosKey(cancelados.length)
            }
            const activos = data.filter((item) => item.estatus === "activo")
            if (activos.length > 0) {
                setActivosKey(activos.length)
            }
        }
    }, [data, setPendientesKey, setCanceladosKey])


    const Headers = () => {
        const headers = [];
        headers.push({ text: "", key: "portada", type: "text", size: "small" })
        headers.push({ text: "Nombre", key: "nombre", type: "text" })
        headers.push({ text: "Num. Local", key: "numerolocal", type: "text" })
        headers.push({ text: "Lugar", key: "lugar", type: "text" })
        if (!franquicia && roles.RolLugares && !roles.RolFranquicias) {
            headers.push({ text: "Franquicia", key: "franquicia", type: "text" })
        }
        headers.push({ text: "Transacciones", key: "transaccion", type: "text" })
        headers.push({ text: "", key: "estatus", type: "text" })
        return headers;
    }

    const DataRows = () => {
        const Rows = [];
        Rows.push(
            {
                key: "portada", size: "small", type: "text", text: (item) => {
                    return <Miniatura
                        avatar={true}
                        error={"/img/noimage.jpg"}
                        src={item?.portada ? `${config.crufdekFileURL}/ft_sucursales/${item?.portada}/imagen` : "/img/noimage.jpg"}
                    />
                },
            },
            {
                key: "nombre", type: "text", text: (item) => {
                    if (!item.nombre || item.nombre.length === 0) {
                        return "---"
                    } else {
                        return item.nombre
                    }
                },
                value: (item) => {
                    return item.nombre;
                },
            },
            {
                key: "numerolocal", type: "text",
            },
            {
                key: "lugar", type: "text",
                value: (item) => {
                    return item.eureka_bs_local?.nombre ?? "---";
                }, text: (item) => {
                    if (item.eureka_bs_local) {
                        return <ChipContact
                            texto={item.eureka_bs_local?.nombre}
                            onClick={() => {
                                Push("lugar", { perfilEstadio: item.eureka_bs_local?.id }, "Lugares")

                            }}
                            error={"/img/noimage.jpg"}
                            imagen={`https://crud.eksuite.com/api/GetFile/ft_estadios/${item.eureka_bs_local?.portada}`}
                        />
                    } else {
                        return "--"
                    }
                }
            },
        );
        if (!franquicia && roles.RolLugares && !roles.RolFranquicias) {
            Rows.push(
                {
                    key: "franquicia", type: "text", text: (item) => {

                        if (item.eureka_bs_franquicia) {
                            return <ChipContact
                                texto={item.eureka_bs_franquicia.nombre}
                                onClick={() => {
                                    Push("usuario", { perfilUser: item.eureka_bs_franquicia.id }, "Usuarios")
                                }}
                                error={"/img/nouser.jpg"}
                                imagen={`https://crud.eksuite.com/api/GetFile/ft_franquicias/${item.eureka_bs_franquicia.portada}`}
                            />
                        } else {
                            return "--"
                        }
                    },
                    value: (item) => {
                        return item.eureka_bs_franquicia?.nombre ?? "--";
                    },
                }
            )
        }
        Rows.push(
            {
                key: "transaccion", type: "text",
                value: (item) => {
                    return item.eureka_st_transacciones_sucursals?.length;
                }, text: (item) => {
                    return item.eureka_st_transacciones_sucursals?.length + " transacciones";
                }
            },

            {
                key: "estatus", type: "text",
                value: (item) => {
                    let estatus = item.estatus;
                    return estatus;
                },
                text: (item) => {
                    let chip;
                    if (item.estatus === "activo") {
                        chip = <ChipEstatus texto={"Aceptado"} tipo="Aceptado" />
                    }

                    if (item.estatus === "inactivo") {
                        chip = <ChipEstatus texto={"Cancelado"} tipo="Error" />
                    }

                    if (item.estatus === "nuevo") {
                        chip = <ChipEstatus texto={"Pendiente"} tipo="Pendiente" />
                    }


                    return chip;
                }
            }
        )
        return Rows;
    }

    return <Tabla
        rowSize="small"
        busqueda={{
            placeholder: "Buscar sucursal...",
            value: search,
            fn: (value) => {
                setSearch(value === "" ? null : value)
                UpProps({ busqueda: value === "" ? null : value })
            }
        }}
        onClickRow={(item) => {
            // Push("franquicia", { perfilFranquicia: item.item.id })
        }}
        checkbox={false}
        keys={[
            {
                text: "Todos",
                detalle: data && data.length > 0 ? data.length : 0,
                filtro: () => true
            },
            {
                text: "Pendientes",
                detalle: pendientesKey,
                filtro: (item) => {
                    return item.item.estatus === "nuevo";
                }
            },
            {
                text: "Aceptados",
                detalle: activosKey,
                filtro: (item) => item.item.estatus === "activo"
            },
            {
                text: "Cancelados",
                detalle: canceladosKey,
                filtro: (item) => item.item.estatus === "inactivo"
            }
        ]}
        botonesHead={[
            {
                icono: "fa-solid fa-plus",
                tooltip: "Agregar sucursal",
                onClick: (item) => {
                    Push("formSucursales", {
                        franquicia: franquicia, sucursal: null, giro: null, numerolocal: null, nombre: null,
                    })
                }
            }
        ]}
        headers={Headers()}
        botonesRow={[
            {
                icono: "fa-solid fa-file-edit",
                tooltip: "Editar",
                onClick: (item) => {
                    item = item.item;
                    Push('formSucursales', {
                        franquicia: item.eureka_bs_franquicia?.id ?? null,
                        sucursal: item.id,
                        franquiciaName: item.eureka_bs_franquicia?.nombre ?? null,
                        estadioName: item.eureka_bs_local?.nombre ?? null,
                        giro: item.giro,
                        numerolocal: item.numerolocal,
                        nombre: item.nombre,
                        usuario: { id: item.eureka_ac_usuario?.id ?? null },
                        prevFile: undefined,
                        prevUrl: `https://crud.eksuite.com/api/GetFile/ft_sucursales/${item.portada}`

                    })
                },
            }
        ]}
        data={buildData(data ?? [], DataRows())}
    >

    </Tabla >
}
