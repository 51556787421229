import { useEffect, useState, useRef } from 'react';
import { useGetZonasQuery, useUpdateCoberturaMutation } from '../../js/service/lugares';
import { useCrearAreaEventoMutation, useGetAreasEventoQuery } from "../../js/service/eventos";
import { handlerMutation } from "../../js/util.js"
import { Formulario, Input, ButtonIcon, ModalForm } from "eureka-design";

export default function Mapas({ lugar, type, eventoId, sucursalId, ScreenBack, ShowAlert }) {
    const { data: dataZonas, isSuccess } = useGetZonasQuery({ lugar });
    const { data: dataEventoAreas } = useGetAreasEventoQuery({ eventoId });
    const [crearAreaEvento, { data: dataCrearArea, isLoading: areaLoading, isError: areaError, isSuccess: areaSuccess }] = useCrearAreaEventoMutation();
    const [updateCobertura, { data: dataSucursalUpdate, isLoading: sucursalLoading, isError: sucursalError, isSuccess: sucursalSuccess }] = useUpdateCoberturaMutation();
    const seccionRefs = useRef({});
    const [idPrecioZona, setIdPrecioZona] = useState(null);
    const [idAreaSucursal, setIdAreaSucursal] = useState(null);
    const [handlerArea, setHandlerArea] = useState({});
    const [preciosZonas, setPreciosZonas] = useState({});
    const [modalPrecios, setModalPrecios] = useState({ estatus: false, precio: null });
    const [modalCoberturas, setModalCoberturas] = useState({ estatus: false, zona: null, seccion: null });
    const [modalAsientos, setModalAsientos] = useState({ estatus: false, asientos: [], seccion: null });
    const [mapaActivo, setMapaActivo] = useState(true);

    useEffect(() => {
        if (isSuccess) {
            if (!dataZonas || dataZonas.length === 0) {
                ShowAlert({
                    titulo: "No encontrado...",
                    mensaje: "Este lugar no tiene mapas cargados",
                    icono: "error",
                    time: 3
                });
                ScreenBack();
            }
        }
    }, [isSuccess]);

    useEffect(() => {
        if (dataZonas) {
            let areas = {}

            dataZonas.forEach(e => {
                let color = "";
                let color_secundario = "";

                if (e.nombre === "Zona A") {
                    color = "#1F6B10";
                    color_secundario = "#1f6b109f";
                } else if (e.nombre === "Zona B") {
                    color = "#CBA30F";
                    color_secundario = "#cba20f93";
                } else if (e.nombre === "Zona C") {
                    color = "#981414";
                    color_secundario = "#98141491";
                } else if (e.nombre === "Zona D") {
                    color = "#0C4871";
                    color_secundario = "#0c497194";
                } else {
                    setMapaActivo(false);
                }
                let secciones = {};
                e.eureka_bs_areas_sucursales.forEach(sec => {
                    secciones[sec.nombre] = { estatus: true, id: sec.id, idcobertura: null, asientos: [] }
                    sec.eureka_bs_sucursal_coberturas.forEach(cobertura => {
                        if (cobertura.local == sucursalId && cobertura.area == sec.id) {
                            secciones[sec.nombre].estatus = false;
                            secciones[sec.nombre].idcobertura = cobertura.id;
                        }
                    });
                    secciones[sec.nombre].asientos = buildAsientos(sec.eureka_bs_asientos_areas);
                });
                areas[e.nombre] = {
                    color: color,
                    color_secundario: color_secundario,
                    border_card: "",
                    pieza: null,
                    active: false,
                    expanded: false,
                    item: e,
                    secciones: secciones,
                    precio: 0
                }
            });
            console.log(areas)
            setHandlerArea(areas);

        }
    }, [dataZonas]);

    useEffect(() => {
        if (dataEventoAreas) {
            console.log("dataEventoAreas", dataEventoAreas)
            const areas = { ...preciosZonas };
            dataEventoAreas.forEach(item => {
                areas[item.eureka_bs_areas_sucursales.nombre] = {};
                areas[item.eureka_bs_areas_sucursales.nombre].precio = item.costo;
                areas[item.eureka_bs_areas_sucursales.nombre].areaId = item.id ?? null;
                areas[item.eureka_bs_areas_sucursales.nombre].areaIdLugar = item.eureka_bs_areas_sucursales?.id == null;
            });
            setPreciosZonas(areas);
        }
    }, [dataEventoAreas]);

    useEffect(() => {
        if (dataCrearArea && dataCrearArea.mensaje != undefined && dataCrearArea.mensaje.id != undefined) {
            setIdPrecioZona(dataCrearArea.mensaje.id)
        }
    }, [dataCrearArea]);


    const groupBy = (list, keyGetter) => {
        const map = new Map();
        list.forEach((item) => {
            const key = keyGetter(item);
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            } else {
                collection.push(item);
            }
        });
        return map;
    }

    const buildAsientos = (areas) => {

        let asientosObj = groupBy(areas, item => item.fila);
        let asientos = [];

        asientosObj.forEach((value, key, ma) => {
            asientos.push({ fila: key, total: value })
        });

        return asientos;
    }


    const handlerAreasHover = (zona, pieza, estatus) => {
        const areas = { ...handlerArea };
        Object.entries(areas).forEach(([key, value]) => {
            areas[key].active = false;
            areas[key].pieza = null;
        });
        if (areas[zona]) {
            areas[zona].active = estatus;
            areas[zona].pieza = pieza;
        }

        setHandlerArea(areas)
    }


    const handlerAreasFill = (zona, pieza) => {
        if (handlerArea[zona]) {
            const secciones = { ...handlerArea[zona].secciones };
            const length = Object.keys(secciones).length;
            if (secciones[pieza] && secciones[pieza].estatus || length === 0) {
                if (handlerArea[zona]?.active && handlerArea[zona].pieza !== pieza) {
                    return handlerArea[zona].color_secundario
                } return handlerArea[zona].color
            } else {
                if (handlerArea[zona]?.active && handlerArea[zona].pieza !== pieza || handlerArea[zona].pieza === pieza) {
                    return handlerArea[zona].color
                }
                return handlerArea[zona].color_secundario
            }
        } return null
    }

    const MouseOver = (zona) => {
        const areas = { ...handlerArea };
        Object.entries(areas).forEach(([key, value]) => {
            areas[key].active = true;
            areas[key].pieza = null;
        });
        if (areas[zona]) {
            areas[zona].active = false;
            areas[zona].border_card = areas[zona].color;
        }

        setHandlerArea(areas)
    }

    const MouseOut = (zona) => {
        const areas = { ...handlerArea };
        Object.entries(areas).forEach(([key, value]) => {
            areas[key].active = false;
            areas[key].pieza = null;
        });
        areas[zona].border_card = "";
        setHandlerArea(areas)
    }

    const seleccionarCobertura = (zona, pieza) => {
        setModalCoberturas({ estatus: true, zona: zona, seccion: pieza });
    }

    const seleccionarPrecio = (precio, areaLugar) => {
        setModalPrecios({ estatus: true, precio: precio?.precio ?? 0 });

        if (precio?.areaId) setIdPrecioZona(precio.areaId); else setIdPrecioZona(null);
        if (areaLugar) setIdAreaSucursal(areaLugar); else setIdAreaSucursal(null);
    }

    const SeccionElement = (index, titulo, asientos, stateArea, zona, filas) => {
        let estilo = {};
        const Area = stateArea ? stateArea : {};

        if (Area.pieza === titulo) {
            estilo = {
                borderColor: Area.color
            }
        } else {
            estilo = { '--bColor-seccion': Area.color }
        }
        if (Area.secciones && Area.secciones[titulo] && !Area.secciones[titulo].estatus) {
            estilo = {
                borderColor: null
            }
        }
        return (
            <div key={index} className="mapa-estadio__seccion"
                onMouseOver={e => {

                }}
                onMouseOut={e => {

                }}
                onClick={e => {
                    seleccionarCobertura(zona, titulo);

                    setModalAsientos({
                        estatus: true, asientos: filas, seccion: titulo
                    })
                }}
                style={estilo}>
                <div className="mapa-estadio__seccion-titulo" style={{ color: Area.color }}>
                    {titulo}
                </div>
                <div className="mapa-estadio__seccion-detalle">
                    {asientos.length} asientos
                </div>
            </div>
        );
    }

    const ZonaElement = (index, zona, precioArea, secciones, stateArea) => {
        const Area = stateArea ? stateArea : {};
        const seccion = seccionRefs[`zona${index}`] ? seccionRefs[`zona${index}`] : undefined;
        return <div key={index} className="mapa-estadio__zona-content">
            <div className="mapa-estadio__zona-card"
                data-border={Area.color}
                style={{ '--bColor-zona': Area.color }}
                onClick={e => {
                    const areas = { ...handlerArea };
                    if (areas[zona.nombre].expanded) {
                        areas[zona.nombre].expanded = false
                    } else {
                        areas[zona.nombre].expanded = true
                    }
                    setHandlerArea(areas)
                }}
                onMouseOver={e => MouseOver(zona.nombre)}
                onMouseOut={e => MouseOut(zona.nombre)}>
                <div className="mapa-estadio__zona-titulo" style={{ color: Area.color }}>
                    {zona.nombre}
                </div>
                {!type && eventoId != null ? <div className="mapa-estadio__zona-precio">
                    ${precioArea && precioArea.precio ? precioArea.precio : 0}
                </div> : undefined}
                <div className="mapa-estadio__zona-detalle">
                    {secciones.length} secciones
                </div>
                {!type && eventoId != null ?

                    <ButtonIcon
                        icono=" fa-dollar-sign"
                        tooltip="Editar precio"
                        select={true}
                        onClick={e => {
                            e.stopPropagation();

                            seleccionarPrecio(precioArea, zona.id)
                        }}

                    ></ButtonIcon>
                    : undefined}
            </div>
            <div ref={e => seccionRefs[`zona${index}`] = e} style={{ maxHeight: Area.expanded ? `${seccion.scrollHeight}px` : 0 }} className="mapa-estadio__secciones-hidden"
            >
                <div className="mapa-estadio__secciones" >
                    {
                        secciones.map((item, index) => {

                            return SeccionElement(index,
                                item.nombre,
                                item.eureka_bs_asientos_areas,
                                stateArea,
                                zona.nombre,
                                buildAsientos(item.eureka_bs_asientos_areas)
                                // stateArea.color, stateArea.border_card
                            )
                        })
                    }
                </div>
            </div>

        </div>
    }

    const handlerPrecios = (value, nombre) => {
        handlerMutation(crearAreaEvento, { id: idPrecioZona, body: { [nombre]: value, eventos: eventoId, area: idAreaSucursal } })
    };

    const ModalPrecios = () => {

        return modalPrecios.estatus ? <ModalForm
            show={modalPrecios.estatus}
            titulo={`Edita el precio de la zona `}
            isLoading={[areaLoading]}
            isSuccess={[areaSuccess]}
            isError={[areaError]}
            onClose={() => {
                setModalPrecios({
                    estatus: false,
                    precio: null
                })
            }}
        >
            <Input
                max={100000}
                nombre="costo"
                value={modalPrecios?.precio ?? 0}
                placeholder="Precio de la zona"
                type="number"
                isLoading={areaLoading}
                changeFunction={handlerPrecios}
            />
        </ModalForm> : undefined

    }

    const ModalAsientos = () => {

        return modalAsientos.estatus ? <ModalForm
            show={modalAsientos.estatus}
            titulo={`Asientos disponibles seccion: ${modalAsientos.seccion}`}
            isLoading={[areaLoading]}
            isSuccess={[areaSuccess]}
            isError={[areaError]}
            onClose={() => {
                setModalAsientos({
                    estatus: false,
                    asientos: [],
                    seccion: null
                })
            }}
        >
            {modalAsientos.asientos.map((asiento, i_asiento) => {
                return <div className="zonas-section__asientos" key={i_asiento}>
                    <span className="zonas-section__asientos-fila">{asiento.fila}</span>
                    <div className="zonas-section__asientos-row">
                        {
                            asiento.total.map((silla, i) => {
                                return <div className="zonas-section__asientos-asiento" key={i}>
                                    {silla.asiento}
                                </div>
                            })
                        }

                    </div>
                    {/* <ButtonIcon
                       onClick={() => {
                           let ids = []
                           asiento.total.forEach((item) => {
                               ids.push(item.id)
                           })
                           borrarAsientos(ids)
                       }}
                       tooltip="Borrar"
                       icono="fa-solid fa-trash"
                       select={true}></ButtonIcon> */}
                </div>
            })}
        </ModalForm> : undefined

    }


    const ModalCoberturas = () => {

        if (modalCoberturas.estatus) {
            const seccion = handlerArea[modalCoberturas.zona].secciones[modalCoberturas.seccion];
            let quest = seccion?.estatus ? `¿Quieres que esta seccion pertenezca a la cobertura de la sucursal?` : `¿Deseas remover esta seccion de la cobertura?`;
            return <div className="modal">
                <Formulario
                    titulo={quest}
                    isLoading={sucursalLoading}
                    isSuccess={sucursalSuccess}
                    isError={sucursalError}
                    style={{
                        position: "absolute",
                        height: "max-content",
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        margin: "auto"
                    }}>
                    <div style={{ alignItems: "baseline" }} className="flex-box2">
                        <button
                            onClick={e => {
                                e.preventDefault();
                                if (!seccion.estatus) {
                                    updateCobertura({ id: seccion.idcobertura, bodyData: {}, type: "borrar" })
                                } else {
                                    updateCobertura({ id: null, bodyData: { local: sucursalId, area: seccion.id } })
                                }

                                if (type && type === "sucursal") {
                                    const areas = { ...handlerArea };
                                    const secciones = { ...areas[modalCoberturas.zona].secciones };
                                    secciones[modalCoberturas.seccion].estatus = !secciones[modalCoberturas.seccion]?.estatus;
                                    setHandlerArea(areas)
                                }
                                setModalCoberturas({ estatus: false, zona: null, seccion: null })
                            }}
                            className="button button--text-link">
                            {seccion?.estatus ? "Aplicar cobertura" : "Remover cobertura"}
                        </button>
                        <button
                            onClick={e => { e.preventDefault(); setModalCoberturas({ estatus: false, zona: null, seccion: null }) }}
                            className="button button--text-link--negative">
                            Cerrar
                        </button>
                    </div>
                </Formulario>
            </div>
        }
    }

    return (
        <>
            {ModalPrecios()}
            {ModalCoberturas()}
            {ModalAsientos()}
            <div className="mapa-estadio">
                <div className="mapa-estadio__zonas-box">
                    {
                        dataZonas ? dataZonas.map((item, index) => {
                            console.log(preciosZonas, handlerArea)
                            if (item.nombre === "Zona A") {
                                return ZonaElement(index, item, preciosZonas["Zona A"], item.eureka_bs_areas_sucursales, handlerArea["Zona A"])
                            } else if (item.nombre === "Zona B") {
                                return ZonaElement(index, item, preciosZonas["Zona B"], item.eureka_bs_areas_sucursales, handlerArea["Zona B"])
                            } else if (item.nombre === "Zona C") {
                                return ZonaElement(index, item, preciosZonas["Zona C"], item.eureka_bs_areas_sucursales, handlerArea["Zona C"])
                            } else if (item.nombre === "Zona D") {
                                return ZonaElement(index, item, preciosZonas["Zona D"], item.eureka_bs_areas_sucursales, handlerArea["Zona D"])
                            } else {

                                return ZonaElement(index, item, preciosZonas[item.nombre], item.eureka_bs_areas_sucursales, [])
                            }
                        }) : undefined
                    }
                </div>
                {mapaActivo ? <div className="content-svg-estadio">
                    <svg xmlSpace="preserve"
                        // style="enable-background:new 0 0 1024 1024;" 
                        viewBox="0 0 1024 1024" y="0px" x="0px" xmlnsXlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg" id="Capa_1" version="1.1">
                        <g id="CAMPO">
                            <path d="M187.2,298.8c1.3,0,2.1,0,3.3,0c3.2,0,6.4,0,9.6,0c5.1,0,10.1,0,15.3,0c6.8,0,13.7,0,20.5,0c8.3,0,16.7,0,25,0
		c9.7,0,19.5,0,29.1,0c10.7,0,21.4,0,32.2,0c11.8,0,23.5,0,35.4,0c12.4,0,24.9,0,37.3,0c13.2,0,26,0,39.2,0c13.4,0,26.8,0,40.2,0
		c13.7,0,27.3,0,41,0s27.2,0,40.8,0c13.5,0,26.9,0,40.5,0c13,0,25.9,0,39.1,0c12.6,0,25,0,37.7,0c11.6,0,23.4,0,35,0
		c10.9,0,21.5,0,32.4,0c9.6,0,19.2,0,29,0c8.3,0,16.7,0,24.9,0c6.7,0,13.7,0,20.4,0c5.1,0,10.1,0,15.3,0c3.2,0,6.4,0,9.6,0
		c1.3,0,2.4,0,3.5,0c9.4,0.1,17.6,7.6,18.6,17.2c0.1,1.3,0.1,2.5,0.1,3.6c0,3.1,0,6.4,0,9.5c0,6.1,0,12.1,0,18.2
		c0,8.5,0,16.9,0,25.5c0,10.6,0,21.1,0,31.5c0,12,0,24.1,0,36.1c0,13.3,0,26.5,0,39.6c0,13.8,0,27.6,0,41.3c0,14.1,0,28.3,0,42.2
		c0,13.8,0,27.6,0,41.3c0,13.3,0,26.3,0,39.6c0,12,0,24,0,36c0,10.4,0,20.9,0,31.3c0,8.5,0,16.8,0,25.3c0,6.1,0,11.9,0,18
		c0,3,0,6.1,0,9.1c0,2.1-0.1,4-0.6,6.1c-2.1,8.6-9.9,14.6-18.5,14.6c-1.3,0-2.4,0-3.5,0c-3.3,0-6.6,0-9.7,0c-5.1,0-10.5,0-15.6,0
		c-6.8,0-13.7,0-20.6,0c-8.5,0-16.7,0-25.3,0c-9.7,0-19.2,0-29.1,0c-10.9,0-21.7,0-32.5,0c-11.8,0-23.5,0-35.2,0
		c-12.6,0-25.2,0-37.7,0c-13,0-25.9,0-39.2,0c-13.5,0-26.9,0-40.5,0c-13.7,0-27.3,0-40.8,0c-13.7,0-27.3,0-40.8,0s-26.8,0-40.3,0
		c-13,0-25.9,0-39.2,0c-12.4,0-25,0-37.4,0c-11.6,0-23.3,0-34.9,0c-10.7,0-21.5,0-32.2,0c-9.6,0-19.1,0-28.7,0c-8.3,0-16.6,0-24.9,0
		c-6.7,0-13.5,0-20.1,0c-5.1,0-10,0-15.2,0c-3,0-6.2,0-9.2,0c-1.9,0-3.8,0.1-5.6-0.1c-9.2-1.3-16.4-9.6-16.7-19.2c0-0.4,0-1,0-1.6
		c0-3.3,0-6.6,0-9.9c0-6.1,0-12.3,0-18.5c0-8.6,0-17.2,0-25.9c0-10.6,0-21.2,0-31.7c0-12.1,0-24.2,0-36.4c0-13.3,0-26.5,0-39.8
		c0-13.8,0-27.6,0-41.3c0-14.1,0-28.3,0-42.2c0-13.8,0-27.6,0-41.2c0-13.3,0-26.2,0-39.5c0-11.9,0-23.9,0-35.7c0-10.3,0-20.9,0-31.1
		c0-8.5,0-16.6,0-25c0-5.9,0-11.7,0-17.5c0-2.9,0-5.7,0-8.7c0-2.1,0.1-3.9,0.6-5.7C171,305,178.7,298.8,187.2,298.8z" className="st0"></path>
                            <path d="M201.3,657.9c0,0.4-0.1,1.3,0,1.8c0,0.3,0.3,0,0.3,0.1s0,0.4,0,0.5c0,0.9,0,2,0,2.9c0,3.9,0,7.7,0,11.6
		c0,12.1,0,24.2,0,36.4c0,11.5,0,22.9,0,34.3c0,2.9,0,6.1,0,9c0,0.5-0.3,0.9,0.3,1.3c0.4,0.1,1.3,0,1.6,0c1,0,2,0,3,0
		c3.5,0,6.7,0,10.1,0c5.1,0,10.1,0,15.3,0c6.6,0,13.2,0,19.9,0c8.1,0,15.9,0,24,0c9.1,0,18.2,0,27.4,0c10.1,0,20.5,0,30.6,0
		c11,0,22,0,32.9,0c11.8,0,23.4,0,35.2,0c12.1,0,24.4,0,36.5,0c12.6,0,25.2,0,37.7,0c12.8,0,25.4,0,38.2,0c12.8,0,25.4,0,38.2,0
		c12.6,0,25.2,0,37.7,0c12.1,0,24.5,0,36.7,0c11.8,0,23.4,0,35.2,0c11.1,0,22.1,0,33.3,0c10.4,0,20.5,0,30.6,0c9.2,0,18.3,0,27.6,0
		c8.1,0,16.1,0,24.2,0c6.6,0,13.4,0,20,0c5.2,0,10.5,0,15.7,0c3.5,0,7,0,10.5,0c1,0,2.1,0,3.2,0c0.5,0,1.3,0.1,1.8,0
		s0.3,0.1,0.4-0.4c0.1-0.4,0-1.3,0-1.8c0-1,0-2.1,0-3c0-7.7,0-15.6,0-23.5c0-11.6,0-23.3,0-34.9c0-13.2,0-26.1,0-39.1
		c0-11.7,0-23.5,0-35.2c0-7.8,0-15.9,0-23.7c0-1.7-0.3-3.4,0-5.1c0-0.1,0-0.4,0-0.4c0.1-0.1,0.4,0,0.4-0.1c0.3-0.3,0-1.4,0-1.7
		c0-8.7,0-17.5,0-26.2c0-12.6,0-25.4,0-38.2c0-8.6,0-17.1,0-25.7c0-0.3,0.1-1.3,0-1.4s-0.4,0-0.4-0.1s0-0.4,0-0.4
		c-0.1-1.8,0-3.6,0-5.2c0-8,0-16,0-24.1c0-11.7,0-23.6,0-35.3c0-13.2,0-26.1,0-39.1c0-11.6,0-23.3,0-34.9c0-7.7,0-15.5,0-23.2
		c0-1,0-2.1,0-3c0-0.4,0.1-1.3,0-1.7s0.1-0.3-0.4-0.4s-1.3,0-1.9,0c-1,0-2.1,0-3.2,0c-3.5,0-7.1,0-10.5,0c-5.1,0-10.5,0-15.6,0
		c-6.7,0-13.4,0-20.1,0c-8.1,0-16.1,0-24.2,0c-9.2,0-18.5,0-27.6,0c-10.4,0-20.5,0-30.6,0c-11.1,0-22.1,0-33.3,0
		c-11.6,0-23.4,0-35,0c-12.1,0-24.5,0-36.8,0c-12.6,0-25,0-37.7,0c-12.9,0-25.5,0-38.3,0s-25.3,0-38.1,0c-12.6,0-25.2,0-37.8,0
		c-12.1,0-24.4,0-36.5,0c-11.8,0-23.4,0-35.2,0c-11,0-22,0-32.9,0c-10.1,0-20.4,0-30.6,0c-9.1,0-18.2,0-27.3,0c-8.1,0-15.9,0-24,0
		c-6.6,0-13.2,0-19.9,0c-5.1,0-10.1,0-15.3,0c-3.3,0-6.7,0-10,0c-1,0-2,0-2.9,0c-0.4,0-1.3-0.1-1.6,0c-0.4,0.3-0.3,0.7-0.3,1.3
		c0,3.1,0,6.4,0,9.4c0,11.7,0,23.6,0,35.3c0,11.6,0,23.2,0,34.8c0,3,0,6.1,0,9.1c0,0.3,0,0.5,0,0.8v0.1l0,0h-0.3c0,0.1,0,0.4,0,0.4
		c-0.1,1,0.3,1.3,0.3,2.3c0,9.3,0,18.6,0,27.8c0,11.9,0,23.7,0,35.6c0,1.8,0,3.6,0,5.2c0,0.8,0.1,1.8,0,2.5v0.1c0,0-0.3,0-0.3,0.1
		c-0.1,0.4,0,0.9,0,1.3c0,0.3,0.1,0.4,0.1,0.4c0,0.1,0.1,0,0.1,0v0.1c0.1,0.7,0,1.6,0,2.3c0,9.4,0,18.8,0,28.2c0,12.6,0,25.3,0,38.1
		c0,4.7,0,9.4,0,14.2c0,1.4,0,2.9,0,4.4c0,0.5,0.1,1.4,0,2.1v0.1c0,0-0.3,0.1-0.3,0.3c-0.1,0.4,0,0.8,0,1.3c0.1,0.4,0.1,0.4,0.3,0.7
		c0.3,0.8,0,2.1,0,2.7c0,4.4,0,8.7,0,13.2c0,12.4,0,25.2,0,37.5c0,4.4,0,9,0,13.4c0,1.3,0,2.6,0,3.9
		C201.6,657.1,201.7,658,201.3,657.9z M228.6,494.6c0,4.7,0,9.5,0,14.2c0,10.3,0,20.7,0,31c0,10.2,0,20.5,0,30.8c0,3.3,0,6.6,0,9.9
		c0,0.5,0.4,3-0.1,3.4c-0.1,0.1-1,0-1.3,0c-0.8,0-1.8,0-2.5,0c-2.5,0-4.9,0-7.3,0c-4.4,0-8.9,0-13.3,0c-0.5,0-0.6,0.1-0.8-0.3
		c-0.3-0.4,0-1.4,0-2.1c0-2.9,0-5.9,0-8.9c0-10,0-19.9,0-30.1c0-10.6,0-21.2,0-31.7c0-3.6,0-7.4,0-11.1c0-1.3,0-2.3,0-3.6
		c0-0.4-0.3-1.4,0-1.7c0.3-0.4,0.9-0.1,1.4-0.1c2,0,4,0,5.9,0c5.1,0,10,0,15.2,0C226.6,494.6,227.6,494.6,228.6,494.6z M230.4,585.8
		c0-2.9,0-6.1,0-9c0-7.2,0-14.5,0-21.6c0-8.9,0-17.5,0-26.3c0-7.7,0-15.6,0-23.3c0-2.7,0-5.2,0-8c0-1.4,0.1-2.7,0-4.3
		c0-0.1,0-0.4,0-0.4c-0.4-0.3-2,0-2.4,0c-3.8,0-7.5,0-11.3,0c-3.7,0-7.3,0-11.1,0c-0.4,0-2,0.3-2.1,0c-0.1-0.1,0-0.5,0-0.7
		c0-5.1,0-10,0-15.1c0-8.6,0-17.2,0-25.7c0-7.6,0-15.5,0-23.1c0-2.1,0-4.3,0-6.4c0-0.3-0.1-1,0-1.3c0.1-0.1,0.1,0,0.3-0.1
		c0.4-0.1,0.9,0,1.4,0c0.8,0,1.5,0,2.3,0c2.4,0,4.8,0,7.2,0c7.2,0,14.3,0,21.5,0c8.6,0,16.9,0,25.5,0c7.7,0,15.4,0,23.1,0
		c4.7,0,9.4,0,14,0c0.3,0,1.5-0.1,1.6,0s0,1,0,1.3c0,2.6,0,5.2,0,7.8c0,4.7,0,9.4,0,14.1c0,6.4,0,12.5,0,18.9c0,7.6,0,15.1,0,22.8
		c0,8.5,0,16.8,0,25.3c0,8.9,0,17.6,0,26.5c0,9,0,17.7,0,26.7c0,8.5,0,17.1,0,25.5c0,7.6,0,15.5,0,23.1c0,6.6,0,13.3,0,19.5
		c0,5,0,9.9,0,14.9c0,3,0,6.1,0,9.1c0,0.4,0.3,1.4,0,2c-0.1,0.4-0.1,0.3-0.5,0.3c-4,0-8.1,0-12,0c-7.3,0-14.7,0-22.1,0
		c-8.6,0-17.2,0-25.7,0c-7.5,0-15.2,0-22.6,0c-4.3,0-8.7,0-13,0c-0.6,0-0.9,0.3-1-0.4c-0.1-0.4,0-1.3,0-1.8c0-1,0-2.1,0-3
		c0-7.2,0-14.2,0-21.4c0-8.7,0-17.3,0-26.1c0-6.1,0-11.9,0-18c0-0.4-0.1-1.3,0-1.7s-0.1-0.1,0.4-0.3c1-0.3,2.3,0,3.3,0
		c1.9,0,3.7,0,5.3,0C218.4,585.8,224.3,585.9,230.4,585.8z M826.7,586.7c-8.2,0-16.2,0-24.4,0c-0.4,0-1.3,0.1-1.4-0.1
		c-0.3-0.3,0-1.3,0-1.7c0-2.7,0-5.6,0-8.5c0-9.9,0-19.7,0-29.6c0-10.8,0-21.4,0-32.2c0-3.9,0-7.6,0-11.6c0-1.3,0-2.5,0-3.8
		c0-0.4-0.3-1.6,0-2.1c0.1-0.4,0.4-0.3,0.9-0.3c1.9,0,3.7,0,5.4,0c5.1,0,10.4,0,15.3,0c0.8,0,1.8,0,2.5,0c0.3,0,1.3-0.1,1.3,0
		s0.1,0.4,0.1,0.7c0,2.3,0,4.6,0,6.9c0,9.4,0,18.8,0,28.2c0,10.9,0,21.9,0,32.8c0,6.8,0,13.3,0,19.9
		C826.7,586.2,826.7,586.6,826.7,586.7z M799.3,495.5c0,2.9,0,6.1,0,9c0,7.2,0,14.3,0,21.6c0,8.9,0,17.6,0,26.3c0,7.7,0,15.6,0,23.3
		c0,2.7,0,5.3,0,8.1c0,1.4-0.1,2.9,0,4.4c0,0.1,0,0.4,0,0.4c0.4,0.3,2,0,2.3,0c3.8,0,7.3,0,11.3,0c3.9,0,7.5,0,11.3,0
		c0.4,0,2.1-0.3,2.5,0c0.1,0.1,0.1,0.3,0.1,0.4c0.1,1.8,0,3.6,0,5.2c0,3.1,0,6.4,0,9.5c0,8.5,0,17.1,0,25.5c0,7.7,0,15.5,0,23.2
		c0,2.2,0,4.4,0,6.6c0,0.5,0.3,1.3-0.3,1.4c-0.4,0.1-0.9,0-1.3,0c-0.8,0-1.5,0-2.3,0c-2.4,0-4.7,0-7.1,0c-7.2,0-14.3,0-21.4,0
		c-8.6,0-17.3,0-25.8,0c-7.7,0-15.6,0-23.3,0c-4.8,0-9.5,0-14.3,0c-0.3,0-1.5,0.3-1.8,0c-0.1-0.1,0-1,0-1.3c0-2.6,0-5.2,0-7.8
		c0-4.7,0-9.3,0-14.1c0-6.4,0-12.5,0-18.9c0-7.6,0-15.1,0-22.8c0-8.5,0-16.8,0-25.3c0-8.9,0-17.6,0-26.6s0-17.7,0-26.7
		c0-8.5,0-17.1,0-25.5c0-7.7,0-15.6,0-23.3c0-6.6,0-13.2,0-19.5c0-5,0-9.9,0-14.9c0-2.9,0-6.1,0-9c0-0.4-0.3-1.4,0-2
		c0.1-0.4,0.3-0.3,0.6-0.3c4,0,8.2,0,12,0c7.3,0,14.9,0,22.3,0c8.6,0,17.2,0,25.7,0c7.5,0,15.2,0,22.8,0c4.3,0,8.6,0,12.9,0
		c0.6,0,0.8-0.3,0.9,0.4c0.4,1.6,0,3.4,0,5c0,7.2,0,14.3,0,21.5c0,8.7,0,17.3,0,26.1c0,5.9,0,11.6,0,17.5c0,0.4,0.1,1.3,0,1.6
		c-0.1,0.3-0.1,0.1-0.5,0.3c-2.9,0-6.1,0-9,0C811.3,495.5,805.2,495.5,799.3,495.5z M826.7,421.4c-3.3,0-6.6,0-9.9,0
		c-7.8,0-15.9,0-23.6,0c-9.5,0-19,0-28.6,0c-8.2,0-16.4,0-24.7,0c-2.7,0-5.4,0-8.2,0c-0.8,0-1.8,0-2.5,0c-0.3,0-1-0.1-1.3,0
		c-0.4,0.3-0.1,1.6-0.1,2.1c0,2.6,0,5.2,0,7.7c0,8.7,0,17.3,0,26.1c0,9.5,0,19,0,28.5c0,5.5,0,11.1,0,16.6c0,0.9-0.4,0.9-1.3,1.7
		c-2.7,2.2-5.2,4.8-7.3,7.6c-4.3,5.6-7.3,12.4-8.9,19.4c-3,14.9,0.3,31,9.6,43c1.3,1.4,2.4,2.7,3.7,4.2c0.6,0.7,1.4,1.3,2,2
		c0.4,0.3,0.5,0.5,0.9,0.8c0.3,0.3,1.3,0.7,1.3,0.9c0.1,0.3,0,0.7,0,0.9c0,2.1,0,4.3,0,6.5c0,8.5,0,16.7,0,25.2c0,9.6,0,19.2,0,28.8
		c0,6.1,0,12,0,18.1c0,0.8-0.3,1.3,0.5,1.3c1.6,0,3.2,0,4.8,0c7,0,13.8,0,20.7,0c9.4,0,18.7,0,28.1,0c9,0,17.8,0,26.8,0
		c5.4,0,11,0,16.3,0c0.4,0,1.5-0.3,1.8,0.1c0.1,0.3,0,1.3,0,1.4c0,0.9,0,2,0,2.7c0,7.2,0,14.2,0,21.4c0,9.8,0,19.5,0,29.2
		c0,8.6,0,17.2,0,25.8c0,2.6,0,5.2,0,7.8c0,0.4,0.3,2.5,0,2.7c-0.1,0.1-0.8,0-0.9,0c-0.9,0-1.8,0-2.7,0c-3.3,0-6.6,0-9.9,0
		c-5.1,0-10.4,0-15.4,0c-6.6,0-13.3,0-19.9,0c-7.8,0-15.7,0-23.5,0c-8.7,0-17.4,0-26,0c-9.4,0-18.7,0-27.9,0c-9.6,0-19.1,0-29,0
		s-19.2,0-29.1,0c-9.4,0-18.7,0-28.1,0c-8.7,0-17.6,0-26,0c-7.8,0-15.7,0-23.5,0c-6.6,0-13.4,0-20,0c-5.1,0-10.5,0-15.6,0
		c-3.5,0-6.7,0-10.1,0c-0.9,0-1.9,0-2.7,0c-0.1,0-0.9,0.1-1.3,0c-0.1-0.1,0-1.3,0-1.4c0-1.6,0-3,0-4.6c0-5.7,0-11.5,0-17.2
		c0-8.2,0-16.4,0-24.8c0-9.6,0-19.4,0-29.1c0-9.9,0-19.7,0-29.6c0-8.9,0-17.7,0-26.6c0-6.8,0-13.6,0-20.3c0-3.4,0-6.8,0-10
		c0-0.1-0.1-0.4,0-0.5s0.8,0,0.9,0c0.9,0,1.9-0.1,2.7-0.1c1.6-0.1,3.2-0.4,4.8-0.7c6.4-1.3,12.6-3.9,18-7.6
		c10.6-7.6,18.1-19.4,20-32.6c2-13.6-1.6-27.6-10-38.1c-8.2-10.4-20.6-16.9-33.6-18c-0.4,0-2.7,0.1-2.8-0.1c0,0,0-0.1,0-0.3
		c-0.1-0.3,0-0.8,0-1c0-1.6,0-3,0-4.4c0-5.6,0-11.5,0-17.1c0-8.5,0-16.6,0-25c0-9.6,0-19.4,0-29.1c0-9.9,0-19.5,0-29.5
		c0-8.9,0-17.7,0-26.6c0-6.6,0-13.3,0-19.8c0-2.1,0-4.3,0-6.4c0-1-0.1-2.3,0-3.4c0.1-0.4-0.1-0.3,0.3-0.4c0.4-0.3,1.3,0,1.8,0
		c2.7,0,5.6,0,8.3,0c4.7,0,9.4,0,14,0c6.3,0,12.4,0,18.7,0c7.5,0,15.2,0,22.5,0c8.6,0,16.9,0,25.5,0c9.2,0,18.3,0,27.6,0
		c9.6,0,19,0,28.7,0s19.5,0,29.1,0c9.5,0,18.8,0,28.3,0c9,0,18,0,26.8,0c8.2,0,16.2,0,24.4,0c7.1,0,14,0,21.1,0c5.6,0,11.3,0,16.7,0
		c3.9,0,7.7,0,11.6,0c1.3,0,2.4,0,3.7,0c0.6,0,1.4-0.1,2,0c0.4,0.1,0.4-0.1,0.4,0.4c0.4,1.6,0,3.4,0,5c0,7.4,0,14.7,0,22.3
		c0,9.8,0,19.5,0,29.2c0,8.5,0,16.8,0,25.3C826.7,414.9,826.7,418.2,826.7,421.4z M727.9,581.5c-18.2-14.5-23.5-42.7-12.1-63.5
		c3-5.6,7.3-10.4,12.1-14.3c0,8.9,0,17.5,0,26.3c0,13.9,0,27.9,0,41.8C727.9,575.1,727.9,578.4,727.9,581.5z M515.3,588
		c0-3,0-6.3,0-9.3c0-7.4,0-14.7,0-22.2c0-9.1,0-18.1,0-27c0-7.7,0-15.6,0-23.3c0-2.6,0-5.2,0-7.7c0-0.8,0-1.7,0-2.3
		c0-0.4-0.1-0.9,0-1.3v-0.1c18.7,0,36,12.4,42.5,30.6c6.6,18.4,1,39.5-13.7,52C536.2,584.1,525.7,588,515.3,588z M512.9,588
		c-18.2-0.9-34.4-13.4-40.5-31c-6.2-18-0.9-38.3,13-50.7c7.6-6.8,17.4-10.9,27.4-11.5c0,3,0,6.1,0,9.3c0,7.4,0,14.7,0,22.2
		c0,9.1,0,18.1,0,27c0,7.7,0,15.6,0,23.3C512.9,580.3,512.9,584.1,512.9,588C512.9,587.9,512.9,587.9,512.9,588z M512.9,329.8
		c0,2.2,0,4.4,0,6.8c0,5.9,0,11.7,0,17.6c0,8.5,0,16.7,0,25.2c0,9.6,0,19.4,0,29.1c0,9.8,0,19.5,0,29.2c0,8.7,0,17.5,0,26.2
		c0,6.5,0,12.9,0,19.4c0,2.1,0,4.2,0,6.1c0,0.9,0.3,2.2,0,3c0,0.1,0,0.3,0,0.3c-0.1,0.3-1.5,0.1-1.8,0.1c-3.3,0.3-6.6,0.9-9.7,2
		c-6.2,2-11.9,5.2-16.7,9.5c-9.9,8.7-15.9,21.5-16.6,34.9c-0.5,13.6,4.6,26.8,13.9,36.4c4.6,4.6,9.9,8.2,15.9,10.8
		c3,1.3,6.3,2.1,9.6,2.7c0.8,0.1,1.6,0.3,2.4,0.4c0.8,0.1,2-0.1,2.7,0.3c0.4,0.1,0.3-0.1,0.4,0.1c0.3,0.4,0,2.1,0,2.5
		c0,2,0,3.6,0,5.5c0,6.3,0,12.4,0,18.6c0,8.6,0,17.1,0,25.7c0,9.8,0,19.5,0,29.2c0,9.6,0,19.5,0,29.2c0,8.6,0,17.2,0,25.9
		c0,6.3,0,12.5,0,18.8c0,2,0,3.9,0,5.7c0,0.5,0.4,2.1,0,2.7c-0.3,0.4-2,0.1-2.5,0.1c-2.9,0-5.9,0-9,0c-4.8,0-9.6,0-14.3,0
		c-6.4,0-12.9,0-19.1,0c-7.6,0-15.2,0-22.9,0c-8.6,0-17.2,0-25.7,0c-9.2,0-18.3,0-27.6,0c-9.6,0-19.1,0-28.8,0c-9.7,0-19.1,0-28.8,0
		c-9.4,0-18.7,0-28.1,0c-8.9,0-17.7,0-26.6,0c-8.1,0-15.9,0-23.8,0c-6.8,0-13.7,0-20.5,0c-5.3,0-10.7,0-16.2,0c-3.7,0-7.3,0-10.9,0
		c-1,0-2.1,0-3.2,0c-0.4,0-1.3,0.3-1.6,0c-0.3-0.1-0.1-0.7-0.1-1.3c0-2.1,0-4,0-6.1c0-7.7,0-15.6,0-23.5c0-9.8,0-19.5,0-29.2
		c0-8.2,0-16.4,0-24.6c0-2.5,0-5,0-7.3c0-0.4-0.3-2.1,0-2.2c0.1-0.1,0.8,0,1,0c0.8,0,1.5,0,2.3,0c2.5,0,5.1,0,7.6,0
		c8.1,0,16.1,0,24.2,0c9.6,0,19,0,28.7,0c8.2,0,16.1,0,24.3,0c2.5,0,5.1,0,7.6,0c0.8,0,1.5,0,2.3,0c0.1,0,0.9,0.1,1,0
		c0.4-0.3,0-2.2,0-2.7c0-2.7,0-5.5,0-8.2c0-9,0-17.7,0-26.7c0-9.3,0-18.6,0-27.9c0-3.3,0-6.6,0-10c0-1,0-2.1,0-3.1
		c0-0.4-0.1-1.3,0-1.7s0,0,0.3-0.4c0.5-0.5,1.4-1.3,2-1.7c0.6-0.7,1.5-1.3,2.1-2.1c2.5-2.6,4.8-5.5,6.7-8.7
		c3.9-6.5,6.3-13.8,7.1-21.2c1.5-14.7-2.8-29.6-13-40.3c-1.3-1.3-2.7-2.6-4-3.8c-0.4-0.4-1-0.7-1.3-1c-0.1-0.4,0-1,0-1.4
		c0-2.3,0-4.8,0-7.2c0-8.6,0-17.1,0-25.7c0-9.5,0-19.2,0-28.7c0-5.9,0-11.7,0-17.6c0-0.8,0.3-1-0.5-1c-1.6,0-3.2,0-4.8,0
		c-6.8,0-13.8,0-20.6,0c-9.4,0-18.6,0-27.9,0c-9,0-17.7,0-26.7,0c-5.4,0-11,0-16.4,0c-0.4,0-1.6,0.3-1.8-0.1c-0.1-0.3,0-1.3,0-1.4
		c0-0.9,0-2,0-2.7c0-7.2,0-14.3,0-21.5c0-9.8,0-19.5,0-29.2c0-8.5,0-16.6,0-25c0-2.3,0-4.7,0-7c0-0.4-0.1-1.7,0-1.8
		c0.3-0.1,1.3,0,1.3,0c1,0,2,0,2.9,0c3.5,0,7,0,10.5,0c5.2,0,10.5,0,15.8,0c6.7,0,13.5,0,20.1,0c7.8,0,15.9,0,23.6,0
		c8.9,0,17.6,0,26.3,0c9.4,0,18.7,0,28.1,0c9.6,0,19.1,0,28.8,0c9.6,0,19.1,0,28.8,0c9.2,0,18.5,0,27.7,0c8.6,0,17.3,0,25.8,0
		c7.7,0,15.4,0,23.1,0c6.6,0,12.9,0,19.5,0c4.9,0,9.7,0,14.7,0c3,0,6.3,0,9.4,0C510.9,329.8,511.9,329.8,512.9,329.8z M302.1,501.1
		c8.9,6.9,14.9,16.8,16.9,28.2c2.3,11.6,0.6,24-4.7,34.5c-3,5.9-7.2,11.1-12.3,15c0-8.7,0-17.3,0-26.2c0-13.9,0-27.9,0-41.8
		C302.1,507.5,302.1,504.3,302.1,501.1z" className="st1"></path>

                        </g>
                        <rect height="30.8" width="41.8" className="st4" y="148.8" x="231.7" id="P1"></rect>
                        <rect height="30.8" width="24.4" className="st4" y="148.8" x="275.1" id="P2"></rect>
                        <rect height="30.8" width="25.1" className="st4" y="148.8" x="301" id="P3"></rect>
                        <rect height="30.8" width="25.6" className="st4" y="148.8" x="327.2" id="P4"></rect>
                        <rect height="30.8" width="24.9" className="st4" y="148.8" x="353.6" id="P5"></rect>
                        <rect height="30.8" width="40.7" className="st4" y="148.8" x="379.3" id="P6"></rect>
                        <rect height="30.8" width="82.5" className="st4" y="148.8" x="420.8" id="P7_esp"></rect>
                        <rect height="30.8" width="40.7" className="st4" y="148.8" x="525.1" id="P8"></rect>
                        <rect height="30.8" width="40.7" className="st4" y="148.8" x="566.8" id="P9"></rect>
                        <rect height="30.8" width="40.7" className="st4" y="148.8" x="608.4" id="P10"></rect>
                        <rect height="30.8" width="24.6" className="st4" y="148.8" x="649.9" id="P11"></rect>
                        <rect height="30.8" width="25.5" className="st4" y="148.8" x="675.7" id="P12"></rect>
                        <rect height="30.8" width="25.5" className="st4" y="148.8" x="702.1" id="P13"></rect>
                        <rect height="30.8" width="24.6" className="st4" y="148.8" x="728.4" id="P14"></rect>
                        <rect height="30.8" width="40.7" className="st4" y="148.8" x="754.2" id="P15"></rect>
                        {/* Zona A */}
                        <rect height="62.9" width="88.3" className={`st4 active`} y="827.8" x="224.3" id="tt_8"
                            // onMouseOver={e => { handlerAreasHover("Zona A", "TT8", true); }}
                            // onMouseOut={e => { handlerAreasHover("Zona A", "TT8", false); }}
                            onClick={e => {
                                seleccionarCobertura("Zona A", "TT8")
                                if (!type && eventoId != null) {
                                    seleccionarPrecio(preciosZonas["Zona A"], handlerArea["Zona A"].item.id)
                                }
                            }}
                            style={{
                                fill: handlerAreasFill("Zona A", "TT8")
                            }}
                        ></rect>
                        <rect height="62.9" width="75.7" className={`st4 active`} y="827.8" x="345.1" id="tt_6"
                            // onMouseOver={e => { handlerAreasHover("Zona A", "TT6", true); }}
                            // onMouseOut={e => { handlerAreasHover("Zona A", "TT6", false); }}
                            onClick={e => {
                                seleccionarCobertura("Zona A", "TT6")
                                if (!type && eventoId != null) {
                                    seleccionarPrecio(preciosZonas["Zona A"], handlerArea["Zona A"].item.id)
                                }
                            }}
                            style={{
                                fill: handlerAreasFill("Zona A", "TT6")
                            }}
                        ></rect>
                        <rect height="62.9" width="82.2" className={`st4 active`} y="827.8" x="420.8" id="TT5"
                            // onMouseOver={e => { handlerAreasHover("Zona A", "TT5", true); }}
                            // onMouseOut={e => { handlerAreasHover("Zona A", "TT5", false); }}
                            onClick={e => {
                                seleccionarCobertura("Zona A", "TT5")
                                if (!type && eventoId != null) {
                                    seleccionarPrecio(preciosZonas["Zona A"], handlerArea["Zona A"].item.id)
                                }
                            }}
                            style={{
                                fill: handlerAreasFill("Zona A", "TT5")
                            }}
                        ></rect>
                        <rect height="62.9" width="81.8" className={`st4 active `} y="827.8" x="527.3" id="TT4"
                            // onMouseOver={e => { handlerAreasHover("Zona A", "TT4", true); }}
                            // onMouseOut={e => { handlerAreasHover("Zona A", "TT4", false); }}
                            onClick={e => {
                                seleccionarCobertura("Zona A", "TT4")
                                if (!type && eventoId != null) {
                                    seleccionarPrecio(preciosZonas["Zona A"], handlerArea["Zona A"].item.id)
                                }
                            }}
                            style={{
                                fill: handlerAreasFill("Zona A", "TT4")
                            }}
                        ></rect>
                        <rect height="62.9" width="73.2" className={`st4 active `} y="827.8" x="609.1" id="TT3"
                            // onMouseOver={e => { handlerAreasHover("Zona A", "TT3", true); }}
                            // onMouseOut={e => { handlerAreasHover("Zona A", "TT3", false); }}
                            onClick={e => {
                                seleccionarCobertura("Zona A", "TT3")
                                if (!type && eventoId != null) {
                                    seleccionarPrecio(preciosZonas["Zona A"], handlerArea["Zona A"].item.id)
                                }
                            }}
                            style={{
                                fill: handlerAreasFill("Zona A", "TT3")
                            }}
                        ></rect>
                        <rect height="31.7" width="32.5" className="st4" y="859" x="682.3" id="tt_2"
                            // onMouseOver={e => { handlerAreasHover("Zona A", "TT2", true); }}
                            // onMouseOut={e => { handlerAreasHover("Zona A", "TT2", false); }}
                            onClick={e => {
                                seleccionarCobertura("Zona A", "TT2")
                                if (!type && eventoId != null) {
                                    seleccionarPrecio(preciosZonas["Zona A"], handlerArea["Zona A"].item.id)
                                }
                            }}
                            style={{
                                fill: handlerAreasFill("Zona A", "TT2")
                            }}></rect>
                        <rect height="62.9" width="90.4" className={`st4 active `} y="827.8" x="714.8" id="TT1"
                            // onMouseOver={e => { handlerAreasHover("Zona A", "TT1", true); }}
                            // onMouseOut={e => { handlerAreasHover("Zona A", "TT1", false); }}
                            onClick={e => {
                                seleccionarCobertura("Zona A", "TT1")
                                if (!type && eventoId != null) {
                                    seleccionarPrecio(preciosZonas["Zona A"], handlerArea["Zona A"].item.id)
                                }
                            }}
                            style={{
                                fill: handlerAreasFill("Zona A", "TT1")
                            }}
                        ></rect>
                        <rect height="31.7" width="32.5" className="st4" y="859" x="312.6" id="tt_7"
                            // onMouseOver={e => { handlerAreasHover("Zona A", "TT7", true); }}
                            // onMouseOut={e => { handlerAreasHover("Zona A", "TT7", false); }}
                            onClick={e => {
                                seleccionarCobertura("Zona A", "TT7")
                                if (!type && eventoId != null) {
                                    seleccionarPrecio(preciosZonas["Zona A"], handlerArea["Zona A"].item.id)
                                }
                            }}
                            style={{
                                fill: handlerAreasFill("Zona A", "TT7")
                            }}></rect>
                        {/* Zona B */}
                        <path d="M898.5,729.7c-4.1,32.7-9.1,63.2-14.4,91.2c29,5.5,57.9,11,86.9,16.5c5.8-29.6,11.2-61.9,15.7-96.6
	L898.5,729.7z" className={`st4 active `} id="CS7"
                            // onMouseOver={e => { handlerAreasHover("Zona B", "CS7", true); }}
                            // onMouseOut={e => { handlerAreasHover("Zona B", "CS7", false); }}
                            onClick={e => {
                                seleccionarCobertura("Zona B", "CS7")
                                if (!type && eventoId != null) {
                                    seleccionarPrecio(preciosZonas["Zona B"], handlerArea["Zona B"].item.id)
                                }
                            }}
                            style={{
                                fill: handlerAreasFill("Zona B", "CS7")
                            }}
                        ></path>
                        <path d="M934.3,690.4c-0.9,9-4.3,34.5-5.4,43.1l57.8,7.3c1.2-9.1,4.2-35.2,5.2-44.8L934.3,690.4z" className="st4 active" id="CS6"
                            // onMouseOver={e => { handlerAreasHover("Zona B", "CS6", true); }}
                            // onMouseOut={e => { handlerAreasHover("Zona B", "CS6", false); }}
                            onClick={e => {
                                seleccionarCobertura("Zona B", "CS6")
                                if (!type && eventoId != null) {
                                    seleccionarPrecio(preciosZonas["Zona B"], handlerArea["Zona B"].item.id)
                                }
                            }}
                            style={{
                                fill: handlerAreasFill("Zona B", "CS6")
                            }}
                        ></path>
                        <path d="M910.1,563.5c-0.6,43.8-3.1,85.1-6.9,123.9l88.6,8.7c4.2-41,7-84.9,7.7-131.4L910.1,563.5z" className="st4 active" id="CS5"
                            // onMouseOver={e => { handlerAreasHover("Zona B", "CS5", true); }}
                            // onMouseOut={e => { handlerAreasHover("Zona B", "CS6", false); }}
                            onClick={e => {
                                seleccionarCobertura("Zona B", "CS6")
                                if (!type && eventoId != null) {
                                    seleccionarPrecio(preciosZonas["Zona B"], handlerArea["Zona B"].item.id)
                                }
                            }}
                            style={{
                                fill: handlerAreasFill("Zona B", "CS5")
                            }}
                        ></path>
                        <path d="M941.9,520.3c0.1,4.4,0.1,17.4,0.1,21.8c0,4.4,0,17.4-0.1,21.8l57.7,0.7c0.1-5.4,0.2-19.5,0.2-25
	c0-3.9-0.1-16.3-0.1-20.1L941.9,520.3z" className="st4 active" id="CS4"
                            // onMouseOver={e => { handlerAreasHover("Zona B", "CS4", true); }}
                            // onMouseOut={e => { handlerAreasHover("Zona B", "CS4", false); }}
                            onClick={e => {
                                seleccionarCobertura("Zona B", "CS4")
                                if (!type && eventoId != null) {
                                    seleccionarPrecio(preciosZonas["Zona B"], handlerArea["Zona B"].item.id)
                                }
                            }}
                            style={{
                                fill: handlerAreasFill("Zona B", "CS4")
                            }}
                        ></path>
                        <path d="M903.2,396.9c3.8,38.7,6.3,80.1,6.9,123.8l89.5-1.1c-0.6-46.5-3.2-90.4-7.2-131.5L903.2,396.9z" className="st4 active" id="CS3"
                            // onMouseOver={e => { handlerAreasHover("Zona B", "CS3", true); }}
                            // onMouseOut={e => { handlerAreasHover("Zona B", "CS3", false); }}
                            onClick={e => {
                                seleccionarCobertura("Zona B", "CS3")
                                if (!type && eventoId != null) {
                                    seleccionarPrecio(preciosZonas["Zona B"], handlerArea["Zona B"].item.id)
                                }
                            }}
                            style={{
                                fill: handlerAreasFill("Zona B", "CS3")
                            }}
                        ></path>
                        <path d="M930.3,350.5c1.1,8.5,4,34.3,4.8,43.2l57.3-5.6c-0.9-9.5-3.9-35.8-5.1-44.8L930.3,350.5z" className="st4 active" id="CS2"
                            // onMouseOver={e => { handlerAreasHover("Zona B", "CS2", true); }}
                            // onMouseOut={e => { handlerAreasHover("Zona B", "CS2", false); }}
                            onClick={e => {
                                seleccionarCobertura("Zona B", "CS2")
                                if (!type && eventoId != null) {
                                    seleccionarPrecio(preciosZonas["Zona B"], handlerArea["Zona B"].item.id)
                                }
                            }}
                            style={{
                                fill: handlerAreasFill("Zona B", "CS2")
                            }}
                        ></path>
                        <path d="M987.4,343.3c-4.4-35-9.8-67.6-15.5-97.6l-88,16.7c5.4,28.3,10.4,59.1,14.5,92.1L987.4,343.3z" className="st4 active" id="CS1"
                            // onMouseOver={e => { handlerAreasHover("Zona B", "CSa", true); }}
                            // onMouseOut={e => { handlerAreasHover("Zona B", "CS1", false); }}
                            onClick={e => {
                                seleccionarCobertura("Zona B", "CS1")

                                if (!type && eventoId != null) {
                                    seleccionarPrecio(preciosZonas["Zona B"], handlerArea["Zona B"].item.id)
                                }
                            }}
                            style={{
                                fill: handlerAreasFill("Zona B", "CS1")
                            }}
                        ></path>
                        {/* Zona C */}
                        <path d="M37.5,742c4.5,34.2,9.8,66.1,15.6,95.5c29-5.5,57.9-11,86.9-16.5c-5.3-27.7-10.1-57.9-14.2-90.1
	L37.5,742z" className="st4 active" id="cp_1"
                            // onMouseOver={e => { handlerAreasHover("Zona C", "CP1", true); }}
                            // onMouseOut={e => { handlerAreasHover("Zona C", "CP1", false); }}
                            onClick={e => {
                                seleccionarCobertura("Zona C", "CP1")
                                if (!type && eventoId != null) {
                                    seleccionarPrecio(preciosZonas["Zona C"], handlerArea["Zona C"].item.id)
                                }
                            }}
                            style={{
                                fill: handlerAreasFill("Zona C", "CP1")
                            }}
                        ></path>
                        <path d="M32.1,696.1c1.1,10.3,4,36,5.3,45.8L100,734c-1.2-9.2-4-34.3-4.9-44L32.1,696.1z" className="st4 active" id="cp_2"
                            // onMouseOver={e => { handlerAreasHover("Zona C", "CP2", true); }}
                            // onMouseOut={e => { handlerAreasHover("Zona C", "CP2", false); }}
                            onClick={e => {
                                seleccionarCobertura("Zona C", "CP2")
                                if (!type && eventoId != null) {
                                    seleccionarPrecio(preciosZonas["Zona C"], handlerArea["Zona C"].item.id)
                                }
                            }}
                            style={{
                                fill: handlerAreasFill("Zona C", "CP2")
                            }}
                        ></path>
                        <path d="M24.4,565.2c0.8,46.3,3.6,90.1,7.7,130.9l88.7-8.6c-3.8-38.6-6.3-79.8-6.9-123.5L24.4,565.2z" className="st4 active" id="cp_3"
                            // onMouseOver={e => { handlerAreasHover("Zona C", "CP3", true); }}
                            // onMouseOut={e => { handlerAreasHover("Zona C", "CP3", false); }}
                            onClick={e => {
                                seleccionarCobertura("Zona C", "CP3")
                                if (!type && eventoId != null) {
                                    seleccionarPrecio(preciosZonas["Zona C"], handlerArea["Zona C"].item.id)
                                }
                            }}
                            style={{
                                fill: handlerAreasFill("Zona C", "CP3")
                            }}
                        ></path>
                        <path d="M87.7,519.9l-63.4-0.8c0,4.1-0.1,16.2-0.1,20.4c0,6,0.2,19.9,0.2,25.8l63.3-0.8
	c-0.1-4.7-0.1-17.5-0.1-22.3C87.6,537.3,87.7,524.6,87.7,519.9z" className="st4 active" id="cp_4"
                            // onMouseOver={e => { handlerAreasHover("Zona C", "CP4", true); }}
                            // onMouseOut={e => { handlerAreasHover("Zona C", "CP4", false); }}
                            onClick={e => {
                                seleccionarCobertura("Zona C", "CP4")
                                if (!type && eventoId != null) {
                                    seleccionarPrecio(preciosZonas["Zona C"], handlerArea["Zona C"].item.id)
                                }
                            }}
                            style={{
                                fill: handlerAreasFill("Zona C", "CP4")
                            }}
                        ></path>
                        <path d="M31.6,388.1c-4,40.9-6.6,84.7-7.2,131l89.6,1.1c0.6-43.6,3.2-84.7,6.9-123.3L31.6,388.1z" className="st4 active" id="cp_5"
                            // onMouseOver={e => { handlerAreasHover("Zona C", "CP5", true); }}
                            // onMouseOut={e => { handlerAreasHover("Zona C", "CP5", false); }}
                            onClick={e => {
                                seleccionarCobertura("Zona C", "CP5")
                                if (!type && eventoId != null) {
                                    seleccionarPrecio(preciosZonas["Zona C"], handlerArea["Zona C"].item.id)
                                }
                            }}
                            style={{
                                fill: handlerAreasFill("Zona C", "CP5")
                            }}
                        ></path>
                        <path d="M125.7,353.5c4.1-32.6,9.1-63,14.4-91l-88-16.7c-5.7,29.6-11,61.9-15.4,96.5L125.7,353.5z" className="st4 active" id="cp_7"
                            // onMouseOver={e => { handlerAreasHover("Zona C", "CP7", true); }}
                            // onMouseOut={e => { handlerAreasHover("Zona C", "CP7", false); }}
                            onClick={e => {
                                seleccionarCobertura("Zona C", "CP7")
                                if (!type && eventoId != null) {
                                    seleccionarPrecio(preciosZonas["Zona C"], handlerArea["Zona C"].item.id)
                                }
                            }}
                            style={{
                                fill: handlerAreasFill("Zona C", "CP7")
                            }}
                        ></path>
                        <path d="M36.8,342.2c-1.2,9.8-4.2,35.5-5.2,45.9l63.3,6.3c1-9.7,4.3-34.8,5.4-44.1L36.8,342.2z" className="st4 active" id="cp_6"
                            // onMouseOver={e => { handlerAreasHover("Zona C", "CP6", true); }}
                            // onMouseOut={e => { handlerAreasHover("Zona C", "CP6", false); }}
                            onClick={e => {
                                seleccionarCobertura("Zona C", "CP6")
                                if (!type && eventoId != null) {
                                    seleccionarPrecio(preciosZonas["Zona C"], handlerArea["Zona C"].item.id)
                                }
                            }}
                            style={{
                                fill: handlerAreasFill("Zona C", "CP6")
                            }}
                        ></path>
                        {/* Zona D */}
                        <rect height="74.5" width="88.7" className="st4 active" y="181.7" x="226.4" id="TPyP1"
                            // onMouseOver={e => { handlerAreasHover("Zona D", "TP1", true); }}
                            // onMouseOut={e => { handlerAreasHover("Zona D", "TP1", false); }}
                            onClick={e => {
                                seleccionarCobertura("Zona D", "TP1")
                                if (!type && eventoId != null) {
                                    seleccionarPrecio(preciosZonas["Zona D"], handlerArea["Zona D"].item.id)
                                }
                            }}
                            style={{
                                fill: handlerAreasFill("Zona D", "TP1")
                            }}
                        ></rect>
                        <rect height="43.3" width="22.7" className="st4 active" y="181.7" x="315" id="TPyP2"
                            // onMouseOver={e => { handlerAreasHover("Zona D", "TP2", true); }}
                            // onMouseOut={e => { handlerAreasHover("Zona D", "TP2", false); }}
                            onClick={e => {
                                seleccionarCobertura("Zona D", "TP2")

                                if (!type && eventoId != null) {
                                    seleccionarPrecio(preciosZonas["Zona D"], handlerArea["Zona D"].item.id)
                                }
                            }}
                            style={{
                                fill: handlerAreasFill("Zona D", "TP2")
                            }}
                        ></rect>
                        <polygon points="503.3,181.7 337.8,181.7 337.8,256.3 503.3,256.3" className="st4 active" id="TPyP3"
                            // onMouseOver={e => { handlerAreasHover("Zona D", "TP3", true); }}
                            // onMouseOut={e => { handlerAreasHover("Zona D", "TP3", false); }}
                            onClick={e => {
                                seleccionarCobertura("Zona D", "TP3")
                                if (!type && eventoId != null) {
                                    seleccionarPrecio(preciosZonas["Zona D"], handlerArea["Zona D"].item.id)
                                }
                            }}
                            style={{
                                fill: handlerAreasFill("Zona D", "TP3")
                            }}
                        ></polygon>
                        <polygon points="525.1,181.7 503.3,181.7 503.3,225.1 525.1,225.1" className="st4 active" id="TPyP4"
                            // onMouseOver={e => { handlerAreasHover("Zona D", "TP4", true); }}
                            // onMouseOut={e => { handlerAreasHover("Zona D", "TP4", false); }}
                            onClick={e => {
                                seleccionarCobertura("Zona D", "TP4")
                                if (!type && eventoId != null) {
                                    seleccionarPrecio(preciosZonas["Zona D"], handlerArea["Zona D"].item.id)
                                }
                            }}
                            style={{
                                fill: handlerAreasFill("Zona D", "TP4")
                            }}
                        ></polygon>
                        <rect height="74.5" width="152.9" className="st4 active" y="181.7" x="525.1" id="TPyP5"
                            // onMouseOver={e => { handlerAreasHover("Zona D", "TP5", true); }}
                            // onMouseOut={e => { handlerAreasHover("Zona D", "TP5", false); }}
                            onClick={e => {
                                seleccionarCobertura("Zona D", "TP5")
                                if (!type && eventoId != null) {
                                    seleccionarPrecio(preciosZonas["Zona D"], handlerArea["Zona D"].item.id)
                                }
                            }}
                            style={{
                                fill: handlerAreasFill("Zona D", "TP5")
                            }}
                        ></rect>
                        <rect height="74.5" width="106.4" className="st4 active" y="181.7" x="700.7" id="TPyP6"
                            // onMouseOver={e => { handlerAreasHover("Zona D", "TP6", true); }}
                            // onMouseOut={e => { handlerAreasHover("Zona D", "TP6", false); }}
                            onClick={e => {
                                seleccionarCobertura("Zona D", "TP6")
                                if (!type && eventoId != null) {
                                    seleccionarPrecio(preciosZonas["Zona D"], handlerArea["Zona D"].item.id)
                                }
                            }}
                            style={{
                                fill: handlerAreasFill("Zona D", "TP6")
                            }}
                        ></rect>
                        <rect height="43.3" width="22.7" className="st4 active" y="181.7" x="678" id="TPyP7"
                            // onMouseOver={e => { handlerAreasHover("Zona D", "TP7", true); }}
                            // onMouseOut={e => { handlerAreasHover("Zona D", "TP7", false); }}
                            onClick={e => {
                                seleccionarCobertura("Zona D", "TP7")
                                if (!type && eventoId != null) {
                                    seleccionarPrecio(preciosZonas["Zona D"], handlerArea["Zona D"].item.id)
                                }
                            }}
                            style={{
                                fill: handlerAreasFill("Zona D", "TP7")
                            }}
                        ></rect>
                    </svg>
                </div> : undefined}
            </div>
        </>
    );
}