import { Card, ModalForm, Input, ButtonText, ButtonIcon } from "eureka-design"
import { useGetZonasQuery, useCrearZonaMutation, useCrearAsientosMutation, useBorrarAsientosMutation, useUpdateAsientosMutation } from "../../js/service/lugares"
import "../../css/estilos.css"
import { EkQuery } from "../../js/query"
import { useEffect, useState } from 'react';

export default function FormZonas({ lugar, ShowAlert }) {
    const [zona, setZona] = useState();
    const [seccion, setSeccion] = useState({ value: null, zonaId: null });
    const [asientos, setAsientos] = useState({ fila: null, asientos: 0 });
    const [modalSeccion, setModalSeccion] = useState(false);
    const [modalAsientos, setModalAsientos] = useState({ active: false, index: null, idZona: null });
    const [seccionSelect, setSeccionSelect] = useState({});
    const [ZonasData, setZonasData] = useState([]);

    const { data: Data, isLoading: IsLoadingQueryZonas } = useGetZonasQuery({ lugar });
    const [crearZonas, { isLoading: zonaIsLoading }] = useCrearZonaMutation();
    const [crearAsientos, { isLoading: asientoIsLoading }] = useCrearAsientosMutation();
    const [borrarAsientos, { isLoading: borrarIsLoading }] = useBorrarAsientosMutation();
    const [updateAsientos, { isLoading: UpdateIsLoading, isSuccess: UpdateAsientoSuccess }] = useUpdateAsientosMutation();

    useEffect(() => {
        if (Data) {
            let seleccion = {};
            Data.map((item, index) => {
                seleccion[item.id] = {
                    seccion: seccionSelect[item.id]?.seccion ?? null,
                    secciones: item.eureka_bs_areas_sucursales,
                }
            })
            setZonasData(Data)
            setSeccionSelect(seleccion)
        }
    }, [Data])

    useEffect(() => {
        if (UpdateAsientoSuccess) {
            ShowAlert({
                titulo: "Asiento actualizado",
                mensaje: "El asiento a sido actualizado correctamente",
                icono: "success",
                time: 3
            });
        }
    }, [UpdateAsientoSuccess])


    const groupBy = (list, keyGetter) => {
        const map = new Map();
        list.forEach((item) => {
            const key = keyGetter(item);
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            } else {
                collection.push(item);
            }
        });
        return map;
    }

    const buildAsientos = (secciones, seccion) => {
        let seccion_seleccionada = secciones.filter(item => item.id === seccion)[0];
        let asientosObj = groupBy(seccion_seleccionada.eureka_bs_asientos_areas, item => item.fila);
        let asientos = [];
        if (seccion_seleccionada.eureka_bs_asientos_areas.length > 0) {
            asientosObj.forEach((value, key, ma) => {
                asientos.push({ fila: key, total: value })
            });
        }
        return asientos;
    }


    const handlerAsientos = () => {
        const queryAsientos = [];
        for (let index = 0; index < asientos.asientos; index++) {
            queryAsientos.push(new EkQuery(["EurekaBsAsientosArea", "Array"])
                .create({
                    area: modalAsientos.idZona,
                    fila: asientos.fila,
                    asiento: index + 1,
                    indice: index,
                }))
        }
        crearAsientos({ data: queryAsientos })
        setAsientos({ fila: null, asientos: 0 });
        setModalAsientos({ active: false, index: null, idZona: null })
    }

    return (
        <>
            <ModalForm titulo="Agrega una seccion"
                show={modalSeccion}
                botones={[
                    {
                        texto: "Agregar seccion",
                        onClick: () => {
                            crearZonas({ id: null, body: { sucursal: lugar, nivel: "seccion", area_padre: seccion.zonaId, nombre: seccion.value } })
                            setSeccion({ value: null, zonaId: null })
                            setModalSeccion(false)
                        }
                    }
                ]}
                onClose={() => { setModalSeccion(false) }}
            >
                <Input
                    value={seccion.value}
                    iconLeft="fa-square"
                    changeFunction={(value) => { setSeccion({ value: value, zonaId: seccion.zonaId }) }}
                    placeholder="Ej. Seccion TTA"
                />
            </ModalForm>
            <ModalForm titulo="Agrega una fila de asientos"
                show={modalAsientos.active}
                botones={[
                    {
                        texto: "Agregar fila",
                        onClick: () => {
                            handlerAsientos();

                        }
                    }
                ]}
                onClose={() => { setModalAsientos({ active: false, index: null, idZona: null }) }}
            >
                <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                    <Input
                        iconLeft=" fa-chart-simple"
                        changeFunction={(value) => { setAsientos(old => { return { ...old, fila: value } }) }}
                        value={asientos.fila}
                        placeholder="Fila de asientos"
                    />
                    <Input
                        iconLeft="fa-chair"
                        changeFunction={(value) => { setAsientos(old => { return { ...old, asientos: value } }) }}
                        value={asientos.asientos}
                        placeholder="Cantidad de asientos"
                    />
                </div>
            </ModalForm>
            <div className="zonas-box">
                <Card>
                    <div className="zonas-content">
                        <h3>Agregar una zona nueva</h3>
                        <br />
                        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                            <Input
                                value={zona}
                                iconLeft="fa-square"
                                changeFunction={(value) => { setZona(value) }}
                                placeholder="Ej. Zona A"
                            />
                            <ButtonText
                                onClick={() => {
                                    crearZonas({ id: null, body: { sucursal: lugar, nivel: "zona", nombre: zona } })
                                    setZona("")
                                }}
                                size="large"
                                texto="Añadir Zona"
                                select={true}></ButtonText>
                        </div>

                    </div>
                </Card>
                <div className="zonas-section">
                    {ZonasData ? ZonasData.map((item, index) => {
                        return <Card key={index}>
                            <div className="zonas-section__zona">
                                {item.nombre}
                            </div>
                            <div className="zonas-section__zona" style={{ paddingTop: 0 }}>
                                <h4>Secciones de la zona</h4>
                            </div>
                            <div className="zonas-section__secciones">
                                {
                                    item.eureka_bs_areas_sucursales.map((seccion, i_Sec) => {
                                        return <div key={i_Sec}
                                            onClick={() => {
                                                let seleccion = { ...seccionSelect }
                                                seleccion[item.id] = {
                                                    seccion: seccion.id,
                                                    secciones: item.eureka_bs_areas_sucursales
                                                }
                                                setSeccionSelect(seleccion)
                                            }}
                                            className={`zonas-section__seccion 
                                        ${seccion.id === seccionSelect[item.id]?.seccion ?
                                                    "zonas-section__seccion--seleccionada" : ""}`}>
                                            {seccion.nombre}
                                        </div>
                                    })
                                }

                                <ButtonIcon
                                    onClick={() => {
                                        setSeccion({ value: null, zonaId: item.id })
                                        setModalSeccion(true)
                                    }}
                                    tooltip="Agregar Sección"
                                    size="large"
                                    icono="fa-solid fa-plus"
                                    select={true}></ButtonIcon>
                            </div>
                            {seccionSelect[item.id]?.seccion ? <div className="zonas-section__asientos-box">

                                {
                                    buildAsientos(seccionSelect[item.id].secciones, seccionSelect[item.id].seccion).map((asiento, i_asiento) => {
                                        return <div className="zonas-section__asientos" key={i_asiento}>
                                            <span className="zonas-section__asientos-fila">{asiento.fila}</span>
                                            <div className="zonas-section__asientos-row">
                                                {
                                                    asiento.total.map((silla, i) => {
                                                        return <div className={`zonas-section__asientos-asiento ${silla.estatus !== "disponible" ? "zonas-section__asientos-asiento--ocupado " : ""}`} key={i}
                                                            onClick={e => {
                                                                ShowAlert({
                                                                    titulo: `Estatus de asiento ${asiento.fila}-${silla.asiento}`,
                                                                    mensaje: `${silla.estatus !== "disponible" ? "¿Deseas reactivar este asiento?" :
                                                                        "¿Deseas deshabilitar este asiento?"}`,
                                                                    icono: "warning",
                                                                    botones: [

                                                                        {
                                                                            texto: `${silla.estatus !== "disponible" ? "Reactivar" : "Deshabilitar"}`,
                                                                            fn: () => {
                                                                                updateAsientos({ id: silla.id, body: { estatus: silla.estatus !== "disponible" ? "disponible" : "mantenimiento" } })
                                                                            }
                                                                        }, {
                                                                            texto: "Cancelar",
                                                                            fn: () => { }
                                                                        },
                                                                    ]
                                                                });

                                                            }}>
                                                            {silla.asiento}
                                                        </div>
                                                    })
                                                }

                                            </div>
                                            <ButtonIcon
                                                onClick={() => {
                                                    let ids = []
                                                    asiento.total.forEach((item) => {
                                                        ids.push(item.id)
                                                    })
                                                    borrarAsientos(ids)
                                                }}
                                                tooltip="Borrar"
                                                icono="fa-solid fa-trash"
                                                select={true}></ButtonIcon>
                                        </div>
                                    })
                                }
                                <ButtonText
                                    size="small"
                                    onClick={() => {
                                        setModalAsientos({ active: true, index: null, idZona: seccionSelect[item.id].seccion })
                                    }}
                                    texto="Añadir fila de asientos"
                                    select={true}></ButtonText>
                            </div> : undefined}
                        </Card>
                    }) : undefined}
                </div>

            </div>
        </>


    )
}