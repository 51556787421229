import { Theme, Navegador, LoaderCircle } from "eureka-design";
import { capitalize } from "../js/util";
import { useSelector, useDispatch } from "react-redux";
import { config } from "../config/config";
import { useState, useEffect } from "react";

import { useGetRolesUsuarioQuery } from "../js/service/rol";

import { useNavigate } from "react-router-dom"
import { handler_rol } from "../js/handler_rol.ts";
import Logo from '../img/logosv2/4.png'
import Eventos from "./Eventos/Eventos";
import GruposEventos from "./Eventos/GruposEventos.jsx";
import perfilEvento from "./Eventos/PerfilEvento";
import PerfilEventosGrupos from "./Eventos/PerfilEventosGrupos.jsx";
import Asistencias from "./Eventos/Asistencias";
import FormEventos from "./Eventos/FormEventos";
import FormEventosGrupos from "./Eventos/FormGrupos";
import AgrupacionesEventos from "./Eventos/AgrupacionesEventos.jsx";
import Usuarios from "./Usuarios/Usuarios";
import Usuario from "./Usuarios/Usuario";
import GruposUsuarios from "./Usuarios/GruposUsuarios";
import ListaGrupoUsuarios from "./Usuarios/ListaGrupoUsuarios";
import FormUsuarios from "./Usuarios/FormUsuarios";
import Lugares from "./Lugares/Lugares";
import AccesoUsuarios from "./Lugares/AccesoUsuarios";
import Mapas from "./Lugares/Mapas";
import Lugar from "./Lugares/Lugar";
import FormLugares from "./Lugares/FormLugares";
import FormZonas from "./Lugares/FormZonas";
import Productos from "./Franquicias/Productos";
import FormProductos from "./Franquicias/FormProductos";
import Franquicias from "./Franquicias/Franquicias";
import Franquicia from "./Franquicias/Franquicia";
import FormFranquicias from "./Franquicias/FormFranquicias";
import AccesoUsuariosFranquicias from "./Franquicias/AccesoUsuarios";
import Sucursales from "./Franquicias/Sucursales";
import FormSucursales from "./Franquicias/FormSucursales";
import Perfil from "./Perfil/Perfil";
import PasarelaPago from "./Perfil/PasarelaPago";
import FormCuentaFiscal from "./Perfil/FormCuentaFiscal";
import CuentasFiscales from "./Perfil/CuentasFiscales";
import Puertas from "./Lugares/Puertas";
import FormPuertas from "./Lugares/FormPuertas";
import { usuario as infoUsuario } from 'account-react-eurekasigma'
import {
    token
} from 'account-react-eurekasigma'

export default function Index() {
    /* SELECTORES */
    const sesionSlice = useSelector(state => state.sesionSlice)
    // const usuario = useSelector(state => state.sesionSlice.user)
    const usuario = infoUsuario()
    const navegacion = useNavigate()

    /* STATES */
    const [fotoPerfil, setFotoPerfil] = useState(usuario.foto ? `${config.crufdekFileURL}/ft_usuario/${usuario.foto}/imagen` : "/img/usuario.png");
    const [ListaComponentes, setListaComponentes] = useState({
        "Cargando": {
            pantallas: {
                cargando: {
                    titulo: "Cargando roles...",
                    componente: () => (<div style={{ width: "max-content", height: "max-content", margin: "auto" }}>
                        <LoaderCircle size="large"></LoaderCircle>
                    </div>)
                },
            },
        }
    })
    const [roles, setRoles] = useState() //["administrador general", "administrador eventos", "administrador lugares", "administrador restaurantes"

    /* QUERYS */
    const { data: rolesUsuario, isLoading: cargandoRoles, isError: rolesIsError } = useGetRolesUsuarioQuery({ usuarioID: usuario.id, token: token() })
    const [getPermiso, isAdmin] = handler_rol(rolesUsuario)


    useEffect(() => {
        if (roles) {
            BuildVistas();
        }
    }, [roles])

    useEffect(() => {
        if (rolesUsuario) {
            const getRoles = rolesUsuario.map(rol => rol.eureka_cr_rol.nombre);
            const RolGeneral = getRoles.includes("administrador general"),
                RolEventos = getRoles.includes("administrador eventos"),
                RolLugares = getRoles.includes("administrador lugares"),
                RolFranquicias = getRoles.includes("administrador franquicias");
            setRoles({ RolGeneral, RolEventos, RolLugares, RolFranquicias })
            // componentes();

        }
        console.log(usuario, token())
        // if (rolesIsError) {
        //     navegacion('/cerrar_sesion')
        // }

    }, [rolesUsuario, rolesIsError])

    function BuildVistas() {
        const lista = {};
        lista.Eventos = {
            opcion: {
                texto: "Eventos",
                icono: "fas fa-calendar-alt",
            },
            pantallas: {},
        }

        if (getPermiso("r_eventos")) {
            lista.Eventos.pantallas.eventos = {
                props: { usuario, roles, getPermiso },
                tab: "Eventos",
                titulo: "Lista de eventos",
                componente: Eventos
            }
            lista.Eventos.pantallas.gruposEventos = {
                props: { usuario, roles, getPermiso },
                tab: "Grupos eventos",
                titulo: "Grupos de eventos",
                componente: GruposEventos
            }
            lista.Eventos.pantallas.asistencias = {
                titulo: "Asistencias",
                componente: Asistencias
            }
            lista.Eventos.pantallas.perfilEvento = {
                props: { usuario },
                titulo: "Perfil del evento",
                componente: perfilEvento
            }
            lista.Eventos.pantallas.perfilEventosGrupos = {
                props: { usuario },
                titulo: "Perfil del grupo",
                componente: PerfilEventosGrupos
            }
            lista.Eventos.pantallas.agrupacionesEventos = {
                props: { usuario },
                titulo: "Agrupaciones de eventos",
                componente: AgrupacionesEventos
            }
        }
        if (getPermiso("cu_eventos")) {
            lista.Eventos.pantallas.formEventos = {
                props: { usuario, roles },
                titulo: "Agregar Evento",
                componente: FormEventos
            }
            lista.Eventos.pantallas.formGrupos = {
                props: { usuario, roles },
                titulo: "Agregar Grupo",
                componente: FormEventosGrupos
            }

        }
        lista.Usuarios = {
            opcion: {
                texto: "Usuarios",
                icono: "fas fa-users",
            },
            pantallas: {},
        }
        if (getPermiso("r_usuarios")) {
            lista.Usuarios.pantallas.usuarios = {
                tab: "Usuarios",
                props: { getPermiso },
                titulo: "Lista de usuarios",
                componente: Usuarios
            }

            lista.Usuarios.pantallas.usuario = {
                props: { usuario },
                titulo: "Usuario",
                componente: Usuario
            }
        }

        if (getPermiso("cu_usuarios")) {
            lista.Usuarios.pantallas.formUsuarios = {
                props: { usuario },
                titulo: "Agregar usuario",
                componente: FormUsuarios
            }
        }

        if (getPermiso("r_grupos")) {
            lista.Usuarios.pantallas.grupos = {
                tab: "Grupos",
                props: { usuario },
                titulo: "Grupos de usuarios",
                componente: GruposUsuarios
            }
            lista.Usuarios.pantallas.grupo = {
                props: { usuario },
                titulo: "Grupo",
                componente: ListaGrupoUsuarios
            }
        }
        if (getPermiso("cu_grupos")) {
            lista.Usuarios.pantallas.formGrupos = {
                props: { usuario },
                titulo: "Agregar grupo",
                componente: FormUsuarios
            }
        }

        lista.Lugares = {
            opcion: {
                texto: "Lugares",
                icono: "fas fa-map-marker-alt",
            },
            pantallas: {},
        }
        if (getPermiso("r_lugares")) {
            lista.Lugares.pantallas.lugares = {
                props: { usuario, roles, getPermiso },
                titulo: "Lista de lugares",
                componente: Lugares
            }
            lista.Lugares.pantallas.puertas = {
                props: { usuario, getPermiso },
                titulo: "Puertas",
                componente: Puertas
            }
            lista.Lugares.pantallas.lugar = {
                props: { usuario, getPermiso },
                titulo: "Información del lugar",
                componente: Lugar
            }
            lista.Lugares.pantallas.mapas = {
                props: { usuario, getPermiso },
                titulo: "Mapa del lugar",
                componente: Mapas
            }
            lista.Lugares.pantallas.accesoUsuarios = {
                props: { usuario, getPermiso },
                titulo: "Usuarios organizadores de eventos",
                componente: AccesoUsuarios
            }
        }
        if (getPermiso("cu_lugares")) {
            lista.Lugares.pantallas.formZonas = {
                props: { usuario, getPermiso },
                titulo: "Agregar zonas",
                componente: FormZonas
            }
            lista.Lugares.pantallas.formLugares = {
                props: { usuario, getPermiso },
                titulo: "Agregar lugar",
                componente: FormLugares
            }
            lista.Lugares.pantallas.formPuertas = {
                props: { usuario, getPermiso },
                titulo: "Agregar puerta",
                componente: FormPuertas
            }
        }
        lista.Franquicias = {
            opcion: {
                texto: "Franquicias",
                icono: "fas fa-store-alt",
            },
            pantallas: {},
        }
        if (getPermiso("r_franquicias")) {
            lista.Franquicias.pantallas.franquicias = {
                props: { usuario, roles, getPermiso },
                titulo: "Lista de franquicias",
                componente: Franquicias
            }
            lista.Franquicias.pantallas.franquicia = {
                props: { usuario, getPermiso },
                titulo: "Franquicia",
                componente: Franquicia
            }

        }

        if (getPermiso("cu_franquicias")) {
            lista.Franquicias.pantallas.formFranquicias = {
                props: { usuario, getPermiso },
                titulo: "Agregar franquicia",
                componente: FormFranquicias
            }
            lista.Franquicias.pantallas.accesos = {
                props: { usuario, getPermiso },
                titulo: "Accesos de usuarios a franquicias",
                componente: AccesoUsuariosFranquicias
            }
        }
        if (getPermiso("r_productos")) {
            lista.Franquicias.pantallas.productos = {
                props: { usuario, getPermiso },
                titulo: "Productos",
                componente: Productos
            }
        }
        if (getPermiso("cu_productos")) {
            lista.Franquicias.pantallas.formProductos = {
                props: { usuario, getPermiso },
                titulo: "Agregar producto",
                componente: FormProductos
            }
        }
        if (getPermiso("r_sucursales")) {
            lista.Franquicias.pantallas.sucursales = {
                props: { usuario, franquicia: null, roles, getPermiso },
                titulo: "Sucursales",
                componente: Sucursales
            }
        }
        if (getPermiso("cu_sucursales")) {
            lista.Franquicias.pantallas.formSucursales = {
                props: { usuario, franquicia: null, roles, getPermiso },
                titulo: "Agregar sucursal",
                componente: FormSucursales
            }
        }
        lista.Perfil = {
            pantallas: {
                perfil: {
                    props: { usuario, getPermiso },
                    titulo: "Mi perfil",
                    componente: Perfil
                },
            },
        };
        if (isAdmin()) {
            lista.Perfil.pantallas.cuentasFiscales = {
                props: { usuario, getPermiso },
                titulo: "Cuentas Fiscales",
                componente: CuentasFiscales
            }
            lista.Perfil.pantallas.formCuentaFiscal = {
                props: { usuario, getPermiso },
                titulo: "Cuenta Fiscal",
                componente: FormCuentaFiscal
            }
            lista.Perfil.pantallas.pasarelaPago = {
                props: { usuario, getPermiso },
                titulo: "Pasarela de pago",
                componente: PasarelaPago
            }
        }
        setListaComponentes(lista)
    }

    return (
        <Theme
            lightColor="#3f00fd"
            darkColor="#2600c6"
        // compareDark="#1e4cad"
        // compareLight="#3aaa2f"
        >
            <Navegador
                // themeDefault="dark"
                empresa={{
                    nombre: "Face Ticket",
                    imagen: Logo,
                    version: "V 0.0.2407111 demo",
                }}
                usuario={{
                    nombre: capitalize(usuario.nombre ?? 'Desconocido'),
                    rol: isAdmin() ? "Administrador" : "Usuario",
                    imagen: fotoPerfil,
                    pantalla: "Perfil"
                }}
                componentes={ListaComponentes}
            >
            </Navegador>
        </Theme>
    )
}