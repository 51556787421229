import { Tabla, buildData, ChipEstatus, ChipContact } from "eureka-design"
import { useGetAsistenciasEventoQuery, useUpdateAsistenciaMutation } from "../../js/service/eventos"
import { useState } from "react"

export default function Asistencias({ UpProps, Push, ShowAlert, busqueda = null, evento }) {
    const [search, setSearch] = useState(busqueda)
    const { data, isLoading, isFetching } = useGetAsistenciasEventoQuery({ search: search, evento: evento })
    const [UpAsistencia, { isLoading: AsisLoading }] = useUpdateAsistenciaMutation();
    const [BotonesRow, setBotonesRow] = useState([
        [
            {
                icono: "fa-ban",
                tooltip: "Vetar",
                mutacion: (item) => item.estatus === "pendiente",
                onClick: (item) => {
                    console.log(item)
                    if (!AsisLoading) {
                        UpAsistencia({ asistencia: item.id, data: { estatus: "vetado" } })
                    }
                },
            },
            {
                icono: " fa-check",
                tooltip: "Habilitar",
                mutacion: (item) => item.estatus === "vetado",
                onClick: (item) => {
                    if (!AsisLoading) {
                        UpAsistencia({ asistencia: item.id, data: { estatus: "pendiente" } })
                    }
                },
            },
        ]
    ])

    return <Tabla
        rowSize="small"
        busqueda={{
            placeholder: "Buscar asistencia...",
            value: search,
            fn: (value) => {
                setSearch(value === "" ? null : value)
                UpProps({ busqueda: value === "" ? null : value })
            }
        }}
        checkbox={false}
        filtros={[
            {
                text: "Todos",
                icono: "fa-solid fa-filter",
                filtro: () => true
            },
            {
                text: "Ingresados",
                key: "estatus",
                filtro: (item) => {
                    return item.value === "ingresado";
                }
            },
            {
                text: "Egresados",
                key: "estatus",
                filtro: item => {
                    return item.value === "egresado";
                }
            },
            {
                text: "Vetados",
                key: "estatus",
                filtro: item => {
                    return item.value === "vetado";
                }
            },
            {
                text: "Sin ingresar",
                key: "estatus",
                filtro: item => {
                    return item.value === "pendiente";
                }
            }
        ]}
        onClickRow={(item) => {
            Push("usuario", { perfilUser: item.item.id })
        }}
        botonesHead={[
            {
                icono: "fa-solid fa-plus",
                tooltip: "Agregar usuario",
                onClick: (item) => {
                    Push("formUsuarios")
                }
            }
        ]}
        botonesRow={BotonesRow}
        headers={[
            { text: "Propietario", key: "propietario", type: "text", size: "medium" },
            { text: "Invitado", key: "invitado", type: "text", size: "medium", },
            { text: "Boleto", key: "boleto", type: "text" },
            { text: "Estatus", key: "estatus", type: "text" },
        ]}
        data={buildData(data ?? [], [
            {
                key: "propietario", type: "text",
                size: "medium",
                value: (item) => item?.eureka_ac_usuario?.nombre ?? "",
                text: (item) => {
                    if (item?.eureka_ac_usuario) {
                        return <ChipContact texto={item?.eureka_ac_usuario?.nombre}
                            onClick={() => {
                                // ChangeScene("Usuarios", "usuario", { perfilUser: item?.eureka_ac_usuario?.id })
                            }}
                            error={"/img/nouser.jpg"}
                            imagen={`https://crud.eksuite.com/api/GetFile/ft_usuario/${item.eureka_ac_usuario?.foto}/imagen`} />
                    }
                }
            },
            {
                key: "invitado", type: "text",
                size: "medium",
                value: (item) => item?.eureka_ac_invitado?.nombre ?? "",
                text: (item) => {
                    if (item?.eureka_ac_invitado) {
                        return <ChipContact texto={item?.eureka_ac_invitado?.nombre}
                            onClick={() => {
                                // ChangeScene("Usuarios", "usuario", { perfilUser: item?.eureka_ac_usuario?.id })
                            }}
                            error={"/img/nouser.jpg"}
                            imagen={`https://crud.eksuite.com/api/GetFile/ft_usuario/${item.eureka_ac_invitado?.foto}/imagen`} />
                    }
                }
            },
            { key: "boleto", type: "text" },
            {
                key: "estatus", type: "text",

                text: (item) => {
                    let chip;
                    if (item.estatus === "ingresado") {
                        chip = <ChipEstatus texto={"Ingresado"} tipo="Aceptado" />
                    }

                    if (item.estatus === "egresado") {
                        chip = <ChipEstatus texto={"Egresado"} tipo="Aceptado" />
                    }

                    if (item.estatus === "vetado") {
                        chip = <ChipEstatus texto={"Vetado"} tipo="Error" />
                    }

                    if (item.estatus === "pendiente") {
                        chip = <ChipEstatus texto={"No ingresado"} tipo="Pendiente" />
                    }

                    return chip;
                }
            },
        ])}
    />
}