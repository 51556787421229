import { PerfilCard, PerfilDetalleSection, PerfilDetalleInfo, LoaderCircle, Chip } from "eureka-design"
import { useGetFranquiciaQuery } from "../../js/service/franquicias";
import { useEffect, useState } from 'react';

export default function Franquicia({ perfilFranquicia, Push }) {
    const { data: perfil = {}, isLoading, isFetching } = useGetFranquiciaQuery({ franquicia: perfilFranquicia })
    const [Portada, setPortada] = useState()

    useEffect(() => {
        if (perfil) {
            if (perfil?.portada) {
                setPortada(`https://crud.eksuite.com/api/GetFile/ft_franquicias/${perfil?.portada}`)
            }
        }
    }, [perfil]);

    return <PerfilCard
        titulo={perfil?.nombre}
        errorImage="/img/noimage.jpg"
        portada={Portada}
        aspectRadio={"21/9"}>
        <PerfilDetalleInfo titulo="Domicilio de la Franquicia"
            boton={
                {
                    texto: "Editar",
                    icono: "fa-solid fa-file-edit",
                    fn: () => {
                        Push('formFranquicias', {
                            lugar: perfil.id, portada: null, nombre: perfil.nombre,
                            domicilio: perfil.eureka_in_domicilio?.id ?? null,
                            calle: perfil.eureka_in_domicilio?.calle ?? null,
                            interior: perfil.eureka_in_domicilio?.interior ?? null,
                            entrecalles: perfil.eureka_in_domicilio?.entrecalles ?? null,
                            referencias: perfil.eureka_in_domicilio?.referencias ?? null,
                            usuario: { id: perfil.eureka_ac_usuario?.id ?? null },
                            prevFile: undefined,
                            prevUrl: `https://crud.eksuite.com/api/GetFile/ft_franquicias/${perfil.portada}`
                        }, "Lugares")
                    }
                }
            }>
            <PerfilDetalleSection titulo={"Calle"}>
                <span>{perfil?.eureka_in_domicilio?.calle ?? "Sin Calle"}</span>
            </PerfilDetalleSection>
            <PerfilDetalleSection titulo={"Numero exterior"}>
                <span>{perfil?.eureka_in_domicilio?.interior ?? "Sin numero int."}</span>
            </PerfilDetalleSection>
            <PerfilDetalleSection titulo={"Referencias"}>
                <span>{perfil?.eureka_in_domicilio?.referencias ?? "Sin referencias"}</span>
            </PerfilDetalleSection>
            <PerfilDetalleSection titulo={"Entre calles"}>
                <span>{perfil?.eureka_in_domicilio?.entrecalles ?? "Sin entre calles"}</span>
            </PerfilDetalleSection>


        </PerfilDetalleInfo>
        {/* <PerfilDetalleInfo titulo="Información de la Franquicia"
            boton={
                {
                    texto: "Editar",
                    icono: "fa-solid fa-file-edit",
                    fn: () => {
    
                    }
                }
            }>
        </PerfilDetalleInfo> */}
    </PerfilCard>
    // return !isLoading ? <Perfil
    //     titulo={""}
    //     foto={`${perfil.portada !== null ? `https://crud.eksuite.com/api/GetFile/ft_franquicias/${perfil.portada}` : "/img/noimage.jpg"}`}>
    //     <PerfilInfo titulo="Domicilio del la Franquicia">
    //         <PerfilSection titulo="Calle" text={perfil?.eureka_in_domicilio?.calle ?? ""}></PerfilSection>
    //         <PerfilSection titulo="Num int." text={`#${perfil?.eureka_in_domicilio?.interior ?? ""}`}></PerfilSection>
    //         <PerfilSection titulo="Referencias" text={perfil?.eureka_in_domicilio?.referencias ?? ""}></PerfilSection>
    //         <PerfilSection titulo="Entre calles" text={perfil?.eureka_in_domicilio?.entrecalles ?? ""}></PerfilSection>
    //     </PerfilInfo>
    // </Perfil> : <div style={{
    //     width: "100%",
    //     height: "50vh",
    //     display: "grid",
    //     placeItems: "center"
    // }}>
    //     <LoaderCircle size="large"></LoaderCircle>
    // </div>
}