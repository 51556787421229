import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { EkQuery } from '../query';
import { config } from '../../config/config';

export const eventosApi = createApi({
    reducerPath: 'eventosApi',
    baseQuery: fetchBaseQuery({ baseUrl: config.crufdekURLV1 }),
    tagTypes: ['Eventos'],
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        getEventos: builder.query({
            query: ({ search, usuario, roles }) => {
                let withs = ["eureka_cr_multimedia_por", "eureka_bs_sucursale", "eureka_ac_usuario", "eureka_in_contadores"]
                let body;

                if (roles.RolEventos) {
                    body = new EkQuery("EurekaBsEvento").with(withs)
                        .where("usuario", usuario?.id)
                        .search(search, ["nombre"])
                        .get()
                }

                if (roles.RolLugares && usuario?.id) {
                    body = new EkQuery("EurekaBsEvento").with(withs)
                        .whereHas("eureka_bs_sucursale", (query) => {
                            return query.where("usuario", usuario?.id)
                        })
                        .orWhere("usuario", usuario?.id)
                        .search(search, ["nombre"])
                        .get()
                }

                if (roles?.RolGeneral) {
                    body = new EkQuery("EurekaBsEvento")
                        .with(withs)
                        .search(search, ["nombre"])
                        .get()
                }
                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    body: body
                }
            },
            providesTags: ["eventos"],
            keepUnusedDataFor: 3600,
        }),
        getEvento: builder.query({
            query: ({ evento }) => {
                let withs = ["eureka_cr_multimedia_por", "eureka_bs_sucursale", "eureka_ac_usuario"]
                let body = new EkQuery("EurekaBsEvento")
                    .with(withs)
                    .where("id", evento ?? null)
                    .first();

                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    body: body
                }
            },
            providesTags: ["Eveento"],
        }),

        getAsistenciasEvento: builder.query({
            query: ({ search, evento }) => {
                // let withs = ["eureka_bs_invitados"]
                // let body = new EkQuery("EurekaAcUsuario")
                //     .with(withs)
                //     .whereHas("eureka_bs_invitados", (query) => {
                //         return query.where("evento", evento)
                //     })
                //     .search(search, ["nombre"])
                //     .get()
                let withs = ["eureka_ac_usuario", "eureka_ac_invitado"]
                let body = new EkQuery("EurekaBsInvitado")
                    .with(withs)
                    .where("evento", evento)
                    .search(search, ["nombre"])
                    .get()

                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    body: body
                }
            },
            providesTags: ["asistencias"],
        }),
        getEventosGrupos: builder.query({
            query: ({ search, usuario, roles }) => {
                let withs = ["eureka_cr_multimedia", "eureka_bs_sucursale", "eureka_ac_usuario", "eureka_bs_agrupaciones"]
                let body;

                if (roles.RolEventos) {
                    body = new EkQuery("EurekaBsEventosGrupos").with(withs)
                        .where("usuario", usuario?.id)
                        .search(search, ["nombre"])
                        .get()
                }

                if (roles.RolLugares && usuario?.id) {
                    body = new EkQuery("EurekaBsEventosGrupos").with(withs)
                        .whereHas("eureka_bs_sucursale", (query) => {
                            return query.where("usuario", usuario?.id)
                        })
                        .orWhere("usuario", usuario?.id)
                        .search(search, ["nombre"])
                        .get()
                }

                if (roles?.RolGeneral) {
                    body = new EkQuery("EurekaBsEventosGrupos")
                        .with(withs)
                        .search(search, ["nombre"])
                        .get()
                }

                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    body: body
                }
            },
            providesTags: ["EventosGrupos"],
        }),
        getEventoGrupo: builder.query({
            query: ({ grupo }) => {
                let withs = ["eureka_cr_multimedia", "eureka_bs_sucursale", "eureka_ac_usuario", "eureka_bs_agrupaciones.eureka_bs_evento.eureka_cr_multimedia_por"]
                let body = new EkQuery("EurekaBsEventosGrupos")
                    .with(withs)
                    .where("id", grupo ?? null)
                    .first();

                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    body: body
                }
            },
            providesTags: ["EventoGrupo"],
        }),
        getAgrupacionesEvento: builder.query({
            query: ({ search, grupo }) => {
                let withs = ["eureka_bs_evento.eureka_cr_multimedia_por", "eureka_bs_evento.eureka_ac_usuario", "eureka_bs_evento.eureka_bs_sucursale"]
                let body;
                body = new EkQuery("EurekaBsEventosAgrupacion")
                    .with(withs)
                    .where("grupo", grupo)
                    .get()
                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    body: body
                }
            },
            providesTags: ["EventosGrupos"],
        }),
        updateAsistencia: builder.mutation({
            query: ({ asistencia, data }) => {
                let body = new EkQuery("EurekaBsInvitado")
                    .where("id", asistencia)
                    .update(data);

                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    body: body
                }
            },
            invalidatesTags: ["asistencias"],
        }),
        crearAsistenciaEvento: builder.mutation({
            query: ({ registro, boletos }) => {
                let data = [];
                for (let index = 0; index < boletos; index++) {
                    data.push({
                        usuario: registro.usuario.id,
                        evento: registro.evento,
                    })
                }
                let body = new EkQuery("EurekaBsInvitado")
                    .create(data);
                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    body: body
                }
            },
            invalidatesTags: ["asistencias"],
        }),
        crearEvento: builder.mutation({
            query: (data) => {
                var body = new EkQuery("EurekaBsEvento")
                    .create(data.body);

                if (data.id != null) {
                    body = new EkQuery("EurekaBsEvento")
                        .where("id", data.id)
                        .update(data.body);
                }

                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    body: body
                }
            },
        }),
        crearGrupoEventos: builder.mutation({
            query: (data) => {
                var body = new EkQuery("EurekaBsEventosGrupos")
                    .create(data.body);

                if (data.id != null) {
                    body = new EkQuery("EurekaBsEventosGrupos")
                        .where("id", data.id)
                        .update(data.body);
                }

                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    body: body
                }
            },
        }),
        addEventoGrupo: builder.mutation({
            query: (data) => {
                let body = new EkQuery("EurekaBsEventosAgrupacion")
                    .create(data.body);

                if (data.id != null) {
                    body = new EkQuery("EurekaBsEventosAgrupacion")
                        .where("id", data.id)
                        .update(data.body);
                }

                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    body: body
                }
            },
            invalidatesTags: ["EventosGrupos"]
        }),
        removerEventoGrupo: builder.mutation({
            query: ({ grupo, evento }) => {
                let body = new EkQuery("EurekaBsEventosAgrupacion")
                    .where("evento", evento)
                    .where("grupo", grupo)
                    .delete();
                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    body: body
                }
            },
            invalidatesTags: ["EventosGrupos"]
        }),
        searchEventos: builder.query({
            query: ({ search, relaciones }) => {
                var body = new EkQuery("EurekaBsEvento")
                    .where("estatus", "activo")
                    .search(search, ["nombre"])
                    .paginate(100, 1);
                if (relaciones) {
                    body = new EkQuery("EurekaBsEvento")
                        .where("estatus", "activo")
                        .with(relaciones)
                        .search(search, ["nombre"])
                        .paginate(100, 1);
                }
                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    body: body
                }
            },
            transformResponse: resp => (resp.data),
        }),
        uploadImageEventos: builder.mutation({
            query: (data) => {
                var body = new EkQuery("EurekaCrMultimedia")
                    .uploadFile(data.file, data.carpeta, data.nombre);

                if (data.id != null) {
                    body = new EkQuery("EurekaCrMultimedia")
                        .where("id", data.id)
                        .uploadFile(data.file, data.carpeta, data.nombre);
                }

                var formulario = new FormData();
                Object.entries(body).forEach(([key, value]) => {
                    formulario.append(key, value);
                });
                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    enctype: "multipart/form-data",
                    body: formulario
                }
            },
        }),
        updateEvento: builder.mutation({
            query: ({ id, bodyData }) => {
                var body = new EkQuery("EurekaBsEvento")
                    .where("id", id)
                    .update(bodyData);
                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    body: body
                }
            },
            invalidatesTags: ["eventos"]
        }),
        crearAreaEvento: builder.mutation({
            query: (data) => {
                var body = new EkQuery("EurekaBsEventosAreas")
                    .create(data.body);

                if (data.id != null) {
                    body = new EkQuery("EurekaBsEventosAreas")
                        .where("id", data.id)
                        .update(data.body);
                }

                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    body: body
                }
            },
            invalidatesTags: ["areas_evento"]
        }),
        getAreasEvento: builder.query({
            query: ({ eventoId }) => {
                let body = new EkQuery("EurekaBsEventosAreas")
                    .with(["eureka_bs_areas_sucursales"])
                    .where("eventos", eventoId)
                    .get();
                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    body: body
                }
            },
            providesTags: ["areas_evento"],
        }),
    }),
})


export const { useGetEventosQuery, useGetAsistenciasEventoQuery, useGetEventoQuery, useGetAreasEventoQuery, useCrearEventoMutation, useUploadImageEventosMutation, useUpdateEventoMutation, useCrearAreaEventoMutation, useCrearAsistenciaEventoMutation, useUpdateAsistenciaMutation, useGetEventosGruposQuery, useCrearGrupoEventosMutation, useGetEventoGrupoQuery, useGetAgrupacionesEventoQuery, useAddEventoGrupoMutation, useSearchEventosQuery, useRemoverEventoGrupoMutation } = eventosApi