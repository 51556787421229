import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { EkQuery } from '../query';
import { config } from '../../config/config';
import { json } from 'react-router-dom';

export const lugaresApi = createApi({
    reducerPath: 'lugaresApi',
    baseQuery: fetchBaseQuery({ baseUrl: config.crufdekURLV1 }),
    tagTypes: ['Estadios'],
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        getEstadios: builder.query({
            query: ({ search, roles, usuario }) => {
                let body;
                let withs = ["eureka_in_domicilio", "eureka_ac_usuario", "eureka_bs_eventos", "eureka_bs_areas_sucursales", "eureka_bs_local", "eureka_bs_franquicia"]

                if (roles.RolEventos) {
                    body = new EkQuery("EurekaBsSucursale")
                        .where("tipo", "Estadio")
                        .where("estatus", "activo")
                        // .whereIn("id", sucursales)
                        .with(withs)
                        .search(search, ["nombre"])
                        .get()
                }

                if (roles.RolLugares && usuario?.id) {
                    body = new EkQuery("EurekaBsSucursale")
                        .where("tipo", "Estadio")
                        .where("usuario", usuario?.id)
                        .with(withs)
                        .search(search, ["nombre"])
                        .get()
                }

                if (roles.RolGeneral) {
                    body = new EkQuery("EurekaBsSucursale")
                        .where("tipo", "Estadio")
                        .with(withs)
                        .search(search, ["nombre"])
                        .get()
                }

                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    body: body
                }
            },
            providesTags: ["Estadios"],
            keepUnusedDataFor: 3600,
        }),
        getEstadio: builder.query({
            query: ({ estadio }) => {
                let withs = ["eureka_in_domicilio", "eureka_ac_usuario", "eureka_bs_eventos", "eureka_bs_areas_sucursales", "eureka_bs_local", "eureka_bs_franquicia"]
                let body = new EkQuery("EurekaBsSucursale")
                    .where("id", estadio)
                    .with(withs)
                    .first();

                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    body: body
                }
            },
            providesTags: ["Estadio"],
        }),
        getAccesoUsuarios: builder.query({
            query: ({ lugar, tipo = "sucursal" }) => {
                let withs = ["eureka_ac_usuario"]
                let body = new EkQuery("EurekaCrRolUsuario")
                    .where(tipo, lugar)
                    .whereNull("rol")
                    .with(withs)
                    .get();

                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    body: body
                }
            },
            providesTags: ["AccesoUsuarios"],
        }),
        getRestaurantes: builder.query({
            query: ({ page, search, limit }) => {
                var body = new EkQuery("EurekaBsSucursale")
                    .where("tipo", "Restaurante")
                    .search(search, ["nombre"])
                    .paginate(limit, page)
                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    body: body
                }
            },
            transformResponse: resp => ({
                restaurantes: resp.data,
                current_page: resp.current_page,
                last_page: resp.last_page
            }),
            serializeQueryArgs: ({ endpointName }) => {
                return endpointName
            },
            merge: (currentCache, newItems, { arg }) => {
                if (arg.page === 1) {
                    currentCache.restaurantes = [...newItems.restaurantes];
                } else {
                    currentCache.restaurantes.push(...newItems.restaurantes)
                }
            },
            forceRefetch({ currentArg, previousArg }) {
                if (currentArg && previousArg) {
                    return currentArg.page !== previousArg.page || currentArg.search !== previousArg.search
                } else {
                    return false;
                }
            },
            keepUnusedDataFor: 3600,
        }),
        getZonas: builder.query({
            query: ({ lugar, nivel = "zona" }) => {
                let body = [];
                if (lugar) {
                    body = new EkQuery("EurekaBsAreasSucursale")
                        .where("sucursal", lugar)
                        .where("nivel", nivel)
                        .with(["eureka_bs_areas_sucursales.eureka_bs_asientos_areas", "eureka_bs_areas_sucursales.eureka_bs_sucursal_coberturas", "eureka_bs_eventos_areas"])
                        .get();
                }
                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    body: body
                }
            },
            providesTags: ["Zonas"]
        }),
        getDispositivos: builder.query({
            query: ({ lugar, search }) => {
                let withs = ["eureka_bs_areas_sucursales", "eureka_ac_usuario"]
                let body = new EkQuery("EurekaBsDispositivo")
                    .where("sucursal", lugar)
                    .search(search, ["nombre"])
                    .with(withs)
                    .get();

                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    body: body
                }
            },
            providesTags: ["Dispositivos"],
        }),
        crearDispositivo: builder.mutation({
            query: (data) => {
                let body = new EkQuery("EurekaBsDispositivo")
                    .create(data.body);

                if (data.id != null) {
                    body = new EkQuery("EurekaBsDispositivo")
                        .where("id", data.id)
                        .update(data.body);
                }

                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    body: body
                }
            },
            invalidatesTags: ["Dispositivos"]
        }),
        crearLugar: builder.mutation({
            query: (data) => {
                var body = new EkQuery("EurekaBsSucursale")
                    .create(data.body);

                if (data.id != null) {
                    body = new EkQuery("EurekaBsSucursale")
                        .where("id", data.id)
                        .update(data.body);
                }

                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    body: body
                }
            },
            invalidatesTags: ["Estadios"]
        }),
        crearDomicilio: builder.mutation({
            query: (data) => {
                var body = new EkQuery("EurekaInDomicilio")
                    .create(data.body);

                if (data.id != null) {
                    body = new EkQuery("EurekaInDomicilio")
                        .where("id", data.id)
                        .update(data.body);
                }

                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    body: body
                }
            },
            invalidatesTags: ["Estadios"]
        }),
        crearZona: builder.mutation({
            query: (data) => {
                let body = new EkQuery("EurekaBsAreasSucursale")
                    .create(data.body);

                if (data.id != null) {
                    body = new EkQuery("EurekaBsAreasSucursale")
                        .where("id", data.id)
                        .update(data.body);
                }

                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    body: body
                }
            },
            invalidatesTags: ["Zonas"]
        }),
        crearAsientos: builder.mutation({
            query: ({ data }) => {
                let body = new EkQuery("EurekaBsAsientosArea")
                    .create(data);
                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    body: body
                }
            },
            invalidatesTags: ["Zonas"]
        }),
        updateAsientos: builder.mutation({
            query: (data) => {
                let body = new EkQuery("EurekaBsAsientosArea")
                    .where("id", data.id)
                    .update(data.body);
                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    body: body
                }
            },
            invalidatesTags: ["Zonas"]
        }),
        borrarAsientos: builder.mutation({
            query: (ids) => {
                let body = new EkQuery("EurekaBsAsientosArea")
                    .destroy(ids);

                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    body: body
                }
            },
            invalidatesTags: ["Zonas"]
        }),
        addUsuarioAccesos: builder.mutation({
            query: (data) => {
                let body = new EkQuery("EurekaCrRolUsuario")
                    .create(data.body);

                if (data.id != null) {
                    body = new EkQuery("EurekaCrRolUsuario")
                        .where("id", data.id)
                        .update(data.body);
                }

                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    body: body
                }
            },
            invalidatesTags: ["AccesoUsuarios"]
        }),
        removerAccesoUsuario: builder.mutation({
            query: ({ lugar, usuario }) => {
                let body = new EkQuery("EurekaCrRolUsuario")
                    .where("sucursal", lugar)
                    .where("usuario", usuario)
                    .whereNull("rol")
                    .delete();
                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    body: body
                }
            },
            invalidatesTags: ["AccesoUsuarios"]
        }),
        uploadImageLugares: builder.mutation({
            query: (data) => {
                var body = new EkQuery("EurekaBsSucursale")
                    .uploadFile(data.file, data.carpeta, data.nombre);

                if (data.id != null) {
                    body = new EkQuery("EurekaBsSucursale")
                        .where("id", data.id)
                        .uploadFile(data.file, data.carpeta, data.nombre);
                }
                var formulario = new FormData();
                Object.entries(body).forEach(([key, value]) => {
                    formulario.append(key, value);
                });
                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    enctype: "multipart/form-data",
                    body: formulario
                }
            },
        }),
        uploadArchivos: builder.mutation({
            query: (data) => {
                let body = new EkQuery("EurekaCrMultimedia")
                    .uploadFile(data.file, data.carpeta, data.nombre);

                if (data.id != null) {
                    body = new EkQuery("EurekaCrMultimedia")
                        .where("id", data.id)
                        .uploadFile(data.file, data.carpeta, data.nombre);
                }
                console.log(body);
                let formulario = new FormData();
                Object.entries(body).forEach(([key, value]) => {
                    formulario.append(key, value);
                });
                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    enctype: "multipart/form-data",
                    body: formulario
                }
            },
        }),
        writeFiles: builder.mutation({
            query: (data) => {
                let body = new EkQuery("EurekaBsSucMultimedia")
                    .create(data.body);

                if (data.id != null) {
                    body = new EkQuery("EurekaBsSucMultimedia")
                        .where("id", data.id)
                        .update(data.body);
                }

                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    enctype: "multipart/form-data",
                    body: body
                }
            },
        }),
        searchLugares: builder.query({
            query: ({ search, relaciones }) => {
                var body = new EkQuery("EurekaBsSucursale")
                    .where("estatus", "activo")
                    .where("tipo", "Estadio")
                    .search(search, ["nombre"])
                    .paginate(100, 1);
                if (relaciones) {
                    body = new EkQuery("EurekaBsSucursale")
                        .where("estatus", "activo")
                        .where("tipo", "Estadio")
                        .with(relaciones)
                        .search(search, ["nombre"])
                        .paginate(100, 1);
                }
                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    body: body
                }
            },
            transformResponse: resp => (resp.data),
        }),
        updateLugar: builder.mutation({
            query: ({ id, bodyData }) => {
                var body = new EkQuery("EurekaBsSucursale")
                    .where("id", id)
                    .update(bodyData);
                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    body: body
                }
            },
            invalidatesTags: ["Estadios"]
        }),
        updateCobertura: builder.mutation({
            query: ({ id, bodyData, type }) => {
                let body = new EkQuery("EurekaBsSucursalCobertura");
                if (type && type === "borrar") {
                    body = body.where("id", id).delete();
                } else {
                    body = body.create(bodyData);
                }
                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    body: body
                }
            },
            invalidatesTags: ["Zonas"]
        }),

    }),
})


export const { useGetEstadiosQuery, useGetEstadioQuery, useGetRestaurantesQuery, useGetZonasQuery, useGetAccesoUsuariosQuery, useCrearLugarMutation, useSearchLugaresQuery, useUploadImageLugaresMutation, useUploadArchivosMutation, useWriteFilesMutation, useCrearDomicilioMutation, useUpdateLugarMutation, useAddUsuarioAccesosMutation, useRemoverAccesoUsuarioMutation, useCrearZonaMutation, useCrearAsientosMutation, useBorrarAsientosMutation, useUpdateAsientosMutation, useUpdateCoberturaMutation, useCrearDispositivoMutation, useGetDispositivosQuery } = lugaresApi