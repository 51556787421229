import { Tabla, buildData, ModalForm, Input } from "eureka-design";
import { useGetGruposQuery, useCrearGruposMutation, } from "../../js/service/usuarios";
import { handlerMutation } from "../../js/util.js"
import { useState, useEffect } from "react"

export default function GruposUsuarios({ UpProps, Push, getPermiso, ShowAlert, busqueda = null, usuario, grupo }) {
    const [search, setSearch] = useState(busqueda)
    const [ModalGrupo, setModalGrupo] = useState(false)
    const [grupoSeleccionado, setGrupoSeleccionado] = useState({})
    const { data, isLoading, isFetching } = useGetGruposQuery({ search: search, usuario: usuario?.id ?? null })
    const [crearGrupo, { isLoading: createLoading, isSuccess: createSuccess, isError: createError }] = useCrearGruposMutation();

    const handlerInput = (value, nombre,) => {
        handlerMutation(crearGrupo, { id: grupoSeleccionado?.id ?? undefined, body: { [nombre]: value, usuario: usuario?.id } }, (r) => {
            if (r?.mensaje?.id) {
                setGrupoSeleccionado({ ...grupoSeleccionado, id: r.mensaje.id })
            }
        })
    }

    return <>
        {ModalGrupo ? <ModalForm
            isLoading={[createLoading]}
            isError={[createError]}
            isSuccess={[createSuccess]}
            titulo={`${grupoSeleccionado?.id ? `Actualiza la información del grupo` : "Agregar un grupo nuevo"}`}
            show={ModalGrupo}
            onClose={() => {
                setModalGrupo(false)
                setGrupoSeleccionado({})
            }}
        >
            <Input
                min={3}
                max={255}
                changeFunction={handlerInput}
                isLoading={false}
                placeholder={"Nombre"}
                nombre={"nombre"}
                type={"text"}
                value={grupoSeleccionado?.nombre}
            />
            <Input
                min={3}
                max={255}
                changeFunction={handlerInput}
                isLoading={false}
                placeholder={"Tipo"}
                nombre={"tipo"}
                type={"text"}
                value={grupoSeleccionado?.tipo}
            />

        </ModalForm> : undefined}
        <Tabla
            busqueda={{
                placeholder: "Buscar grupo...",
                value: search,
                fn: (value) => {
                    setSearch(value === "" ? null : value)
                    UpProps({ busqueda: value === "" ? null : value })
                }
            }}
            checkbox={false}
            rowSize="small"
            keys={[
                {
                    text: "Todos",
                    detalle: data && data.length > 0 ? data.length : 0,
                    filtro: () => true
                }
            ]}
            onClickRow={(item) => {
                Push("grupo", { grupo: item.item.id })
            }}
            botonesHead={[
                {
                    icono: "fa-solid fa-plus",
                    tooltip: "Agregar grupo",
                    onClick: (item) => {
                        setModalGrupo(true)
                    }
                }
            ]}
            // botonesRow={[
            //     {
            //         icono: "fa-trash",
            //         tooltip: "Borrar del grupo",
            //         onClick: (item) => {
            //             item = item.item;
            //             if (removerLoading) removerUsuario({ categoria: grupo, usuario: item.id })
            //         },
            //     }
            // ]}
            headers={[
                { text: "Nombre", key: "nombre", type: "text" },
                { text: "Tipo", key: "tipo", type: "text" },
                { text: "Usuarios", key: "usuarios", type: "text" },
            ]}
            data={buildData(data ?? [], [
                { key: "nombre", type: "text" },
                { key: "tipo", type: "text" },
                { key: "usuarios", type: "text", text: (item) => item.eureka_ac_usuarios_categorias.length },
            ])}
        >

        </Tabla>
    </>

}