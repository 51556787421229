import { Formulario, Input, ImageInput, DropFile } from "eureka-design"
import { useCrearLugarMutation, useUploadImageLugaresMutation, useCrearDomicilioMutation, useUploadArchivosMutation, useWriteFilesMutation } from "../../js/service/lugares.js";
import { useEffect, useState } from 'react';
import { handlerMutation } from "../../js/util.js"


export default function FormLugares({ UpProps, Push, ShowAlert, lugar, domicilio, prevUrl, prevFile, nombre, calle, interior, entrecalles, referencias, archivos, usuario }) {
    const [idLugar, setIdLugar] = useState(lugar);
    const [idDomicilio, setIdDomicilio] = useState(domicilio);
    const [idArchivo, setIdArchivo] = useState(archivos);

    const [CrearLugar, { isLoading: inputIsloading, isError: inputIsError, isSuccess: inputIsSuccess }] = useCrearLugarMutation();
    const [CrearDomicilio, { isLoading: domLoading, isError: domIsError, isSuccess: domIsSuccess }] = useCrearDomicilioMutation();
    const [uploadFoto, { isLoading: imageisLoading, isError: imageIsError, isSuccess: imageIsSuccess }] = useUploadImageLugaresMutation();
    const [uploadArchivos, { isLoading: fileisLoading, isError: fileIsError, isSuccess: fileIsSuccess }] = useUploadArchivosMutation();
    const [writeFiles, { isLoading: writeIsLoading, isError: writeIsError, isSuccess: writeIsSuccess }] = useWriteFilesMutation();

    const handlerInput = (value, nombre) => {
        handlerMutation(CrearLugar, { id: idLugar, body: { [nombre]: value, tipo: "Estadio", usuario: usuario.id } }, (r) => {
            if (r?.mensaje?.id) {
                setIdLugar(r.mensaje.id)
                UpProps({ lugar: r.mensaje.id })
            }
        })
        UpProps({ [nombre]: value })
    }

    const handlerDom = (value, nombre) => {
        handlerMutation(CrearDomicilio, { id: idDomicilio, body: { [nombre]: value, usuario: usuario.id } }, (r) => {
            if (r?.mensaje?.id) {
                setIdDomicilio(r.mensaje.id)
                UpProps({ domicilio: r.mensaje.id })
                handlerInput(r?.mensaje?.id, "domicilio")
            }
        })
        UpProps({ [nombre]: value })
    }

    const handlerPhoto = (e) => {
        handlerMutation(uploadFoto, { id: idLugar, file: e.target.files[0], carpeta: "ft_estadios", nombre: "portada" }, (r) => {
            if (r?.mensaje?.id) {
                setIdLugar(r.mensaje.id)
                UpProps({ portada: r.mensaje.id })
            }
        })
        UpProps({ prevFile: e.target.files[0] })
    }

    const handlerFile = (value, nombre) => {
        handlerMutation(uploadArchivos, {
            id: null, file: value, carpeta: "ft_estadios", nombre: "archivo"
        }, (r) => {
            if (r?.mensaje?.id) {
                handlerMutation(writeFiles, {
                    id: idArchivo, body: { [nombre]: r.mensaje.id, sucursal: idLugar }
                }, (r) => {
                    if (r?.mensaje?.id) {
                        setIdArchivo(r.mensaje.id)
                        UpProps({ archivos: r.mensaje.id })
                    }
                })
            }
        })
    }

    return (
        <Formulario titulo="Agrega un lugar"
            botones={[
                {
                    texto: "Agregar zonas",
                    iconLeft: "fa-solid fa-plus",
                    onClick: () => {
                        Push("formZonas", { lugar: idLugar })
                    }
                },
                {
                    texto: "Puertas de acceso",
                    iconLeft: " fa-solid fa-door-open",
                    onClick: () => {
                        Push("puertas", { lugar: idLugar })
                    }
                }
            ]}
            isLoading={[inputIsloading, domLoading, imageisLoading, fileisLoading, writeIsLoading]}
            isError={[inputIsError, imageIsError, domIsError, fileIsError, writeIsError]}
            isSuccess={[inputIsSuccess, imageIsSuccess, domIsSuccess, fileIsSuccess, writeIsSuccess]}>
            <ImageInput
                width={"100%"}
                height={"100%"}
                radio={[21, 9]}
                recomendado={[1000, 350]}
                changeFunction={handlerPhoto}
                preview={prevUrl}
                file={prevFile}
            >
            </ImageInput>
            <DropFile
                nombre='archivo'
                allowedExtensions={[".pdf", ".xls", ".xlsx", ".doc", ".docx", ".txt"]}
                onChange={(value, nombre) => {
                    if (!idLugar) {
                        ShowAlert({
                            titulo: "Espera...",
                            mensaje: "Primero agrega un nombre antes de subir algún archivo.",
                            icono: "warning",
                            time: 3.5
                        });
                    } else handlerFile(value, nombre)

                }}
            ></DropFile>
            <Input
                min={5}
                max={255}
                changeFunction={handlerInput}
                isLoading={inputIsloading}
                placeholder={"Nombre"}
                nombre={"nombre"}
                type={"text"}
                value={nombre}
                iconLeft={"fa-file-contract"}
            />
            <div style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
                width: "100%"
            }}>
                <Input
                    min={5}
                    max={150}
                    changeFunction={handlerDom}
                    isLoading={inputIsloading}
                    placeholder={"Calle"}
                    nombre={"calle"}
                    type={"text"}
                    value={calle}
                    iconLeft={" fa-road"}
                />
                <Input
                    min={1}
                    max={5}
                    changeFunction={handlerDom}
                    isLoading={inputIsloading}
                    placeholder={"Numero exterior"}
                    nombre={"interior"}
                    type={"text"}
                    value={interior}
                    iconLeft={"fa-building"}
                />
            </div>

            <Input
                min={0}
                max={255}
                changeFunction={handlerDom}
                isLoading={inputIsloading}
                placeholder={"Entre calles"}
                nombre={"entrecalles"}
                type={"text"}
                value={entrecalles}
                iconLeft={"fa-road-circle-exclamation"}
            />
            <Input
                min={0}
                max={255}
                changeFunction={handlerDom}
                isLoading={inputIsloading}
                placeholder={"Referencias"}
                nombre={"referencias"}
                type={"textarea"}
                value={referencias}
                iconLeft={" fa-map-marker-alt"}
            />
        </Formulario>
    )
}
