import { Tabla, buildData, ChipEstatus } from "eureka-design"
import { useGetDispositivosQuery } from "../../js/service/lugares"
import { config } from "../../config/config";
import { useState, useEffect } from "react"

export default function Puertas({ Push, UpProps, lugar, busqueda }) {
    const [search, setSearch] = useState(busqueda)
    const { data, isLoading, isFetching } = useGetDispositivosQuery({ search: busqueda, lugar });


    useEffect(() => {
        if (data) {
            let nuevas_puertas = [];
            data.forEach(item => {
                nuevas_puertas.push({
                    ...item,
                    zona: item?.eureka_bs_areas_sucursales?.nombre,
                })
            });
        }
    }, [data]);

    const BotonesRow = () => {
        const botones = [];
        botones.push([
            {
                icono: "fa-solid fa-file-edit",
                tooltip: "Editar",
                onClick: (item) => {
                    item = item.item;
                    Push("formPuertas", { lugar: lugar, puerta: item.id, nombre: item.nombre, areas: item.areas })
                },
            }
        ])
        return botones;
    }

    const BotonesHead = () => {
        const botones = [];
        botones.push(
            {
                icono: "fa-plus",
                tooltip: "Agregar puerta",
                onClick: (item) => {
                    Push("formPuertas", { lugar: lugar, areas: [] })
                },
            }
        )

        return botones;
    }

    return <Tabla
        rowSize="small"
        busqueda={{
            placeholder: "Buscar dispositivo...",
            value: search,
            fn: (value) => {
                setSearch(value === "" ? null : value)
                UpProps({ busqueda: value === "" ? null : value })
            }
        }}
        checkbox={false}
        botonesHead={BotonesHead()}
        botonesRow={BotonesRow()}
        headers={[
            // { text: "", key: "portada", type: "text", size: "small" },
            { text: "Nombre", key: "nombre", type: "text" },
            { text: "Tipo", key: "tipo", type: "text" },
            { text: "", key: "estatus", type: "text" }
        ]}

        data={buildData(data ?? [], [
            {
                key: "nombre", type: "text", text: (item) => {
                    if (!item.nombre || item.nombre.length === 0) {
                        return "---"
                    } else {
                        return item.nombre
                    }
                },
                value: (item) => {
                    return item.nombre;
                },
            },
            {
                key: "tipo", type: "text"
            },
            {
                key: "estatus", type: "text",
                value: (item) => {
                    let estatus = item.estatus;
                    return estatus;
                },
                text: (item) => {
                    let chip;
                    if (item.estatus === "funcionando") {
                        chip = <ChipEstatus texto={"Funcionando"} tipo="Aceptado" />
                    }

                    if (item.estatus === "mantenimiento" || item.estatus === "reparacion") {
                        chip = <ChipEstatus texto={"Mantenimiento"} tipo="Pendiente" />
                    }

                    if (item.estatus === "descompuesto") {
                        chip = <ChipEstatus texto={"Descompuesto"} tipo="Error" />
                    }

                    return chip;
                }
            }
        ])}
    >

    </Tabla >
}