import { Formulario, Input, Select } from "eureka-design";
import { useGenerarCuentaFiscalMutation, useCrearCuentaFiscalMutation } from "../../js/service/fiscal.js";
import { useState, useEffect } from "react";
import { handlerMutation } from "../../js/util.js"

export default function CuentaFiscal({ cuenta, usuario, ShowAlert }) {

    const [idCuentaFiscal, setIdCuentaFiscal] = useState(cuenta?.id ?? undefined);
    const [r_nombre, setR_nombre] = useState(cuenta?.nombre ?? "");
    const [r_apelliod, setR_apelliod] = useState(cuenta?.apellidos ?? "");
    const [r_rfc, setR_rfc] = useState(cuenta?.razonsocial ?? "");
    const [r_email, setR_email] = useState(cuenta?.correo);
    const [r_telefono, setR_telefono] = useState(cuenta?.telefono ?? "");
    const [r_direccion, setR_direccion] = useState(cuenta?.direccionlinea1 ?? "");
    const [r_cp, setR_cp] = useState(cuenta?.codigopostal ?? "");
    const [r_ciudad, setR_ciudad] = useState(cuenta?.ciudad ?? "");
    const [r_estado, setR_estado] = useState(cuenta?.region ?? "");
    const [r_pais, setR_pais] = useState(cuenta?.pais ?? "");
    const [r_nacimiento, setR_nacimiento] = useState(cuenta?.nacimiento ?? null);
    const [r_fotoIdentificacionFrontal, setR_fotoIdentificacionFrontal] = useState("");
    const [r_fotoIdentificacionTrasera, setR_fotoIdentificacionTrasera] = useState("");
    const [r_numeroCuenta, setR_numeroCuenta] = useState(cuenta?.numero_cuenta ?? "");
    const [r_moneda, setR_moneda] = useState(cuenta?.moneda ?? "");
    const [r_paisCuenta, setR_paisCuenta] = useState(cuenta?.pais_cuenta ?? "");

    const [e_nombreEmpresa, setE_nombreEmpresa] = useState(cuenta?.empresa ?? "");
    const [e_categoriaEmpresa, setE_categoriaEmpresa] = useState(cuenta?.categoria_empresa ?? "");
    const [e_rfcEmpresa, setE_rfcEmpresa] = useState(cuenta?.rfc_empresa ?? "");
    const [e_emailEmpresa, setE_emailEmpresa] = useState(cuenta?.correo_empresa ?? "");
    const [e_telefonoEmpresa, setE_telefonoEmpresa] = useState(cuenta?.telefono_empresa ?? "");
    const [e_direccionEmpresa, setE_direccionEmpresa] = useState(cuenta?.direccion1_empresa ?? "");
    const [e_codigoPostalEmpresa, setE_codigoPostalEmpresa] = useState(cuenta?.cp_empresa ?? "");
    const [e_ciudadEmpresa, setE_ciudadEmpresa] = useState(cuenta?.ciudad_empresa ?? "");
    const [e_paisEmpresa, setE_paisEmpresa] = useState(cuenta?.pais_empresa ?? "");
    const [e_estadoEmpresa, setE_estadoEmpresa] = useState(cuenta?.estado_empresa ?? "");

    const [generarCuenta] = useGenerarCuentaFiscalMutation();
    const [crearCuenta, { isLoading: inputIsloading, isError: inputIsError, isSuccess: inputIsSuccess }] = useCrearCuentaFiscalMutation();

    const handlerInput = (value, nombre) => {
        handlerMutation(crearCuenta, {
            id: idCuentaFiscal, body: {
                [nombre]: value,
                usuario: usuario.id
            }
        }, (r) => {
            if (r?.mensaje?.id) {
                setIdCuentaFiscal(r.mensaje.id)
            }
        })
    }

    const makeNacimiento = (fecha) => {
        let fecha1 = new Date(fecha)
        return {
            dia: fecha1.getDate(),
            mes: fecha1.getMonth() + 1,
            anio: fecha1.getFullYear()
        }
    }

    const verificarInputRequerido = () => {
        return r_nombre === "" || r_apelliod === "" || r_rfc === "" || r_email === "" || r_telefono === "" || r_direccion === "" || r_cp === "" || r_ciudad === "" || r_estado === "" || r_pais === "" || r_nacimiento === null || r_fotoIdentificacionFrontal === "" || r_fotoIdentificacionTrasera === "" || r_numeroCuenta === "" || r_moneda === "" || r_paisCuenta === ""
    }

    return (
        <Formulario titulo="Información de cuenta fiscal"
            isLoading={[inputIsloading]}
            isError={[inputIsError]}
            isSuccess={[inputIsSuccess]}
            botones={[
                {
                    texto: "Generar cuenta",
                    onClick: () => {

                        if (!verificarInputRequerido()) {
                            let nacimiento = makeNacimiento(r_nacimiento)

                            generarCuenta({
                                nombreRepresentante: r_nombre,
                                apellidoRepresentante: r_apelliod,
                                rfcRepresentante: r_rfc,
                                email: r_email,
                                telefonoRepresentante: r_telefono,
                                direccion1Representante: r_direccion,
                                codigoPostalRepresentante: String(r_cp),
                                direccion2Representante: r_direccion,
                                ciudadRepresentante: r_ciudad,
                                pais: r_pais,
                                estadoRepresentante: r_estado,
                                diaNacimientoRepresentante: nacimiento.dia,
                                mesNacimientoRepresentante: nacimiento.mes,
                                anioNacimientoRepresentante: nacimiento.anio,
                                fotoIdentificacionFrontal: r_fotoIdentificacionFrontal,
                                fotoIdentificacionTrasera: r_fotoIdentificacionTrasera,
                                numeroCuentaBancaria: r_numeroCuenta,
                                monedaCuentaBancaria: r_moneda,
                                paisCuentaBancaria: r_paisCuenta,
                                seRegistraEmpresa: 0,
                            })
                        } else {
                            ShowAlert({
                                titulo: "Espera...",
                                mensaje: "Debes agregar todos los campos del representante y de la cuenta bancaria para generar la cuenta fiscal",
                                icono: "warning",
                                time: 5
                            })
                        }

                    }
                }
            ]}
        >
            <h4>Datos de la empresa</h4>
            <Input
                min={5}
                value={e_nombreEmpresa}
                isLoading={inputIsloading}
                placeholder={"Nombre"}
                nombre={"empresa"}
                type={"text"}
                changeFunction={(value, nombre) => {
                    setE_nombreEmpresa(value)
                    handlerInput(value, nombre)
                }}
                iconLeft={"fa-file-contract"}
            />
            <Input
                value={e_categoriaEmpresa}
                isLoading={inputIsloading}
                placeholder={"Categoria de la empresa"}
                nombre={"categoria_empresa"}
                type={"text"}
                changeFunction={(value, nombre) => {
                    setE_categoriaEmpresa(value)
                    handlerInput(value, nombre)
                }}
                iconLeft={" fa-building"}
            />
            <Input
                isLoading={inputIsloading}
                value={e_rfcEmpresa}
                placeholder={"RFC"}
                nombre={"rfc_empresa"}
                changeFunction={(value, nombre) => {
                    setE_rfcEmpresa(value)
                    handlerInput(value, nombre)
                }}

                type={"text"}
                iconLeft={" fa-id-card-alt"}
            />
            <Input
                value={e_emailEmpresa}
                isLoading={inputIsloading}
                placeholder={"Correo electrónico"}
                nombre={"correo_empresa"}
                type={"email"}

                changeFunction={(value, nombre) => {
                    setE_emailEmpresa(value)
                    handlerInput(value, nombre)
                }}
                iconLeft={"fa-envelope"}
            />
            <Input
                value={e_telefonoEmpresa}
                isLoading={inputIsloading}
                placeholder={"Teléfono"}
                nombre={"telefono_empresa"}
                type={"telefono"}
                changeFunction={(value, nombre) => {
                    setE_telefonoEmpresa(value)
                    handlerInput(value, nombre)
                }}
                iconLeft={" fa-phone-alt"}
            />
            <div style={{
                display: "flex",
                gap: "10px",
                justifyContent: "space-between"
            }}>
                <Input
                    isLoading={inputIsloading}
                    value={e_direccionEmpresa}
                    placeholder={"Dirección"}
                    nombre={"direccion1_empresa"}
                    type={"text"}
                    changeFunction={(value, nombre) => {
                        setE_direccionEmpresa(value)
                        handlerInput(value, nombre)
                    }}
                    iconLeft={" fa-map-pin"}
                />

                <Input
                    isLoading={inputIsloading}
                    value={e_codigoPostalEmpresa}
                    placeholder={"Código postal"}
                    nombre={"cp_empresa"}
                    changeFunction={(value, nombre) => {
                        setE_codigoPostalEmpresa(value)
                        handlerInput(value, nombre)
                    }}
                    type={"text"}
                    iconLeft={" fa-mail-bulk"}
                />
            </div>

            <Input
                isLoading={inputIsloading}
                value={e_ciudadEmpresa}
                placeholder={"Ciudad"}
                nombre={"ciudad_empresa"}
                type={"text"}
                changeFunction={(value, nombre) => {
                    setE_ciudadEmpresa(value)
                    handlerInput(value, nombre)
                }}
                iconLeft={" fa-city"}
            />
            <Select
                isLoading={inputIsloading}
                value={e_paisEmpresa}
                nombre="pais_empresa"
                placeholder="País"
                iconLeft="fa-globe"
                changeFunction={(value, nombre) => {
                    setE_paisEmpresa(value.value)
                    handlerInput(value.value, nombre)
                }}
                options={[
                    { value: 'MX', text: 'México' },
                    { value: 'US', text: 'Estados Unidos' },
                ]}
            />
            <h4>Datos del representante</h4>
            <div style={{
                display: "flex",
                gap: "10px",
                justifyContent: "space-between"
            }}>
                <Input
                    placeholder={"Nombre"}
                    nombre={"nombre"}
                    type={"text"}
                    changeFunction={(value, nombre) => {
                        setR_nombre(value)
                        handlerInput(value, nombre)
                    }}
                    value={r_nombre}
                    iconLeft={"fa-user"}
                    isLoading={inputIsloading}
                />
                <Input
                    placeholder={"Apellido"}
                    nombre={"apellidos"}
                    changeFunction={(value, nombre) => {
                        setR_apelliod(value)
                        handlerInput(value, nombre)
                    }}
                    value={r_apelliod}
                    type={"text"}
                    iconLeft={" fa-user"}
                    isLoading={inputIsloading}
                />
            </div>
            <Input
                placeholder={"RFC del representante"}

                changeFunction={(value, nombre) => {
                    setR_rfc(value)
                    handlerInput(value, nombre)
                }}
                value={r_rfc}
                type={"text"}
                nombre={"rfc"}
                iconLeft={" fa-id-card-alt"}
                isLoading={inputIsloading}
            />
            <Input
                placeholder={"Correo electrónico"}
                changeFunction={(value, nombre) => {
                    setR_email(value)
                    handlerInput(value, nombre)
                }}
                value={r_email}
                nombre={"correo"}
                type={"email"}
                iconLeft={"fa-envelope"}
                isLoading={inputIsloading}

            />
            <div style={{
                display: "flex",
                gap: "10px",
                justifyContent: "space-between"
            }}>
                <Input
                    placeholder={"Teléfono"}
                    value={r_telefono}
                    changeFunction={(value, nombre) => {
                        setR_telefono(value)
                        handlerInput(value, nombre)
                    }}
                    nombre={"telefono"}
                    type={"telefono"}
                    iconLeft={"fa-phone-alt"}
                    isLoading={inputIsloading}
                />
                <Input
                    placeholder={"Fecha de nacimiento"}
                    nombre={"nacimiento"}
                    type={"date"}
                    value={r_nacimiento}
                    changeFunction={(value) => {
                        let fecha = `${value.getFullYear()}-${value.getMonth() + 1}-${value.getDate()}`
                        setR_nacimiento(fecha)
                        handlerInput(fecha, "nacimiento")
                    }}
                    dateConfig={{
                        maxYear: 2005,
                        minYear: 1900,
                        formato: "{DD} de {MM} del {YYYY}",
                    }}
                    iconLeft={" fa-calendar-alt"}

                />
            </div>

            <div style={{
                display: "flex",
                gap: "10px",
                justifyContent: "space-between"
            }}><Input
                    placeholder={"Dirección"}
                    nombre={"direccionlinea1"}
                    changeFunction={(value, nombre) => {
                        setR_direccion(value)
                        handlerInput(value, nombre)
                    }}
                    value={r_direccion}
                    type={"text"}
                    iconLeft={"fa-map-pin"}
                    isLoading={inputIsloading}
                />
                <Input
                    placeholder={"Código postal"}
                    value={r_cp}
                    changeFunction={(value, nombre) => {
                        setR_cp(value)
                        handlerInput(value, nombre)
                    }}
                    nombre={"codigopostal"}
                    type={"text"}
                    iconLeft={"fa-mail-bulk"}
                    isLoading={inputIsloading}
                />
            </div>

            <Input
                placeholder={"Ciudad"}
                value={r_ciudad}
                changeFunction={(value, nombre) => {
                    setR_ciudad(value)
                    handlerInput(value, nombre)
                }}
                nombre={"ciudad"}
                type={"text"}
                iconLeft={"fa-city"}
                isLoading={inputIsloading}
            />
            <Input
                placeholder={"Estado"}
                value={r_estado}
                changeFunction={(value, nombre) => {
                    setR_estado(value)
                    handlerInput(value, nombre)
                }}
                nombre={"region"}
                type={"text"}
                iconLeft={"fa-city"}
                isLoading={inputIsloading}
            />

            <Select
                nombre="pais"
                value={r_pais}
                placeholder="País"
                changeFunction={(value, nombre) => {
                    setR_pais(value.value)
                    handlerInput(value.value, nombre)
                }}
                iconLeft="fa-globe"
                options={[
                    { value: 'MX', text: 'México' },
                    { value: 'US', text: 'Estados Unidos' },
                ]}
                isLoading={inputIsloading}
            />
            <div style={{
                display: "flex",
                gap: "10px",
                justifyContent: "space-between"
            }}>
                <Input
                    placeholder={"INE Frontal"}
                    changeFunction={(value, nombre) => {
                        setR_fotoIdentificacionFrontal(value)
                    }}
                    nombre={"fotoIdentificacionFrontal"}
                    type={"file"}
                    accept={"image/*"}
                    iconLeft={" fa-id-card-alt"}

                />
                <Input
                    placeholder={"INE Trasera"}
                    changeFunction={(value, nombre) => {
                        setR_fotoIdentificacionTrasera(value)
                    }}
                    nombre={"fotoIdentificacionTrasera"}
                    type={"file"}
                    accept={"image/*"}
                    iconLeft={" fa-id-card-alt"}
                />
            </div>
            <h4>Datos de la cuenta bancaria</h4>
            <Input
                max={18}
                placeholder={"Numero de cuenta"}
                value={r_numeroCuenta}
                changeFunction={(value, nombre) => {
                    setR_numeroCuenta(value)
                    handlerInput(value, nombre)
                }}
                nombre={"numero_cuenta"}
                type={"text"}
                iconLeft={"fa-credit-card"}
                isLoading={inputIsloading}
            />
            <Select
                nombre="moneda"
                placeholder="Moneda"
                changeFunction={(value, nombre) => {
                    setR_moneda(value.value)
                    handlerInput(value.value, nombre)
                }}
                value={r_moneda}
                iconLeft="fa-money-bill-wave"
                options={[
                    { value: 'MXN', text: 'Peso Mexicano' },
                    { value: 'USD', text: 'Dólar Americano' },
                ]}
                isLoading={inputIsloading}
            />
            <Select
                nombre="pais_cuenta"
                placeholder="País de la cuenta"
                iconLeft="fa-globe"
                changeFunction={(value, nombre) => {
                    setR_paisCuenta(value.value)
                    handlerInput(value.value, nombre)
                }}
                value={r_paisCuenta}
                options={[
                    { value: 'MX', text: 'México' },
                    { value: 'US', text: 'Estados Unidos' },
                ]}
                isLoading={inputIsloading}
            />
        </Formulario>
    )
}