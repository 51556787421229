import { MiPerfil, ModalForm } from "eureka-design"
import { useSelector } from "react-redux"
import { obtenerFoto, obtenerRol } from "../../js/util"
import { useNavigate } from "react-router-dom"
import { config } from "../../config/config"
import QRCode from 'qrcode.react';
import CryptoJS from 'crypto-js';
import { useState, useEffect } from "react"

export default function Perfil({ Push, usuario, ShowAlert }) {
    const navegacion = useNavigate()
    const [show, setShow] = useState(false) // Variable para mostrar el modal
    const [codigoQR, setCodigoQR] = useState({
        active: false,
        expirationDate: new Date().toISOString(),
        qr: ""
    });
    const [minutos, setMinutos] = useState(0);
    const [segundos, setSegundos] = useState(0);
    const [corriendo, setCorriendo] = useState(false);

    const generateQRCode = (userId, expirationDate) => {
        const userData = `${userId}+${expirationDate}`;

        // Clave de encriptación
        const encryptionKey = 'eureka_sigma2024'; // Esta clave debe ser compartida con la aplicación de Flutter
        const encryptedData = CryptoJS.AES.encrypt(userData, encryptionKey, {
            mode: CryptoJS.mode.ECB, // Modo de operación CBC
            padding: CryptoJS.pad.Pkcs7 // Padding PKCS7
        });
        const decryptedData = CryptoJS.AES.decrypt(encryptedData, encryptionKey, {
            mode: CryptoJS.mode.ECB, // Modo de operación CBC
            padding: CryptoJS.pad.Pkcs7 // Padding PKCS7
        }).toString(CryptoJS.enc.Utf8);

        // console.log(CryptoJS.enc.Base64.stringify(encryptedData.ciphertext), userData);
        // Generar el QR code con los datos encriptados
        return <QRCode value={encryptedData.toString()} renderAs="svg" size={175} bgColor="transparent" fgColor="var(--color-text-primario)" />;
    };

    const QRGenerator = ({ userId, expirationDate }) => {
        return (
            <div>
                {generateQRCode(userId, expirationDate)}
            </div>
        );
    };

    useEffect(() => {
        let intervalo;
        if (corriendo) {
            intervalo = setInterval(() => {
                if (segundos === 0 && minutos === 0) {
                    setCorriendo(false);
                    setCodigoQR({
                        active: false,
                        expirationDate: new Date().toISOString(),
                        qr: ""
                    });
                    clearInterval(intervalo);
                } else if (segundos === 0) {
                    setMinutos(minutos => minutos - 1);
                    setSegundos(59);
                } else {
                    setSegundos(segundos => segundos - 1);
                }
            }, 1000);
        } else {
            clearInterval(intervalo);
        }
        return () => clearInterval(intervalo);
    }, [corriendo, segundos, minutos]);

    const iniciarCronometro = () => {
        setCorriendo(true);
    };

    return (
        <>
            <ModalForm
                titulo="Código QR para inicio de sesión en la app móvil"
                show={show}
                onClose={() => setShow(false)}
                botones={[
                    {
                        texto: 'Generar QR',
                        onClick: () => {
                            if (!corriendo) {
                                let fechaActual = new Date();
                                fechaActual.setMinutes(fechaActual.getMinutes() + 3);
                                setCodigoQR({
                                    active: true,
                                    expirationDate: fechaActual.toLocaleString(),
                                    qr: <QRGenerator userId={usuario.id} expirationDate={
                                        `${fechaActual.getFullYear()}-${String(fechaActual.getMonth() + 1).padStart(2, "0")}-${String(fechaActual.getDate()).padStart(2, "0")} ${String(fechaActual.getHours()).padStart(2, "0")}:${String(fechaActual.getMinutes()).padStart(2, "0")}:${String(fechaActual.getSeconds()).padStart(2, "0")}`
                                    } />
                                });
                                setMinutos(3);
                                setSegundos(0);
                                iniciarCronometro();

                            } else {
                                ShowAlert({
                                    titulo: "Espera...",
                                    icono: "info",
                                    mensaje: "Espera a que el código QR expire para generar uno nuevo.",
                                    time: 5
                                })
                            }

                        }
                    }
                ]}
            >
                <div className="qr__box">
                    <div className="qr__content">
                        <div className="qr__preview">
                            {codigoQR.active ? codigoQR.qr : undefined}
                        </div>
                        <h4>Expiración del código QR</h4>
                        <span style={{ color: "var(--color-text)" }}>
                            <span>{minutos < 1 ? '0' + minutos : String(minutos).padStart(2, "0")}</span> : <span>{segundos < 1 ? '0' + segundos : String(segundos).padStart(2, "0")}</span></span>
                    </div>
                </div>
            </ModalForm>

            <MiPerfil
                avatar={obtenerFoto(usuario)}
                nombre={usuario.nombre}
                correo={usuario.correo}
                telefono={usuario.telefono}
                extrasInfo={[
                    // { titulo: 'Rol', contenido: obtenerRol(usuario) },

                ]}

                botones={[
                    {
                        texto: 'Editar perfil',
                        iconLeft: 'fas fa-user-edit',
                        onClick: () => { Push('editarperfil') }
                    },
                    {
                        texto: 'Mi cuenta fiscal',
                        iconLeft: 'fas fa-file-invoice-dollar',
                        onClick: () => {

                            Push('cuentasFiscales')
                        }
                    },
                    {
                        texto: 'Pasarela de pago',
                        iconLeft: 'fas fa-file-invoice-dollar',
                        onClick: () => {

                            Push('pasarelaPago')
                        }
                    },
                    {
                        texto: 'Código QR',
                        iconLeft: 'fas fa-qrcode',
                        onClick: () => { setShow(true) }
                    },
                    {
                        texto: 'Cerrar sesión',
                        iconLeft: 'fas fa-arrow-right-from-bracket',
                        onClick: () => { navegacion('/cerrar_sesion') }
                    }
                ]}
            >

            </MiPerfil>
        </>
    )
}