import { useEffect, useState } from 'react'
import Index from './Index'
import { DOMINIO, config } from '../config/config'
import { CargandoPagina } from 'eureka-design'
import {
    crearCookie,
    configurarVerificacionSesion,
    iniciarProcesoRefrescarToken,
    token,
    // usuario as infoUsuario,
} from 'account-react-eurekasigma'
import { useSelector } from 'react-redux'

const Inicio = () => {
    const sesionSlice = useSelector(state => state.sesionSlice)

    const [verificandoSesion, cambiarEstadoVerificarSesion] = useState(true)
    const [mensajePantallaCarga, fijaMensajePantallaCarga] = useState('Procesando credenciales, por favor espere...')

    useEffect(() => {
        /**
         * 
         * @param {object} usuario 
         * @param {string} tokenSesion 
         */
        const verificarDatosFiscales = async (usuario = {}, tokenSesion = '') => {
            guardarDatosSesion(usuario, tokenSesion)
            iniciarProcesoRefrescarToken(
                usuario,
                tokenSesion,
                config.tiempoRefrescar,
                guardarDatosSesion,
                false,
                config.inactividad,
                `${DOMINIO}/cerrar_sesion`
            )
        }

        /**
         * 
         * @param {object} usuario 
         * @param {string} tokenSesion 
         */
        const guardarDatosSesion = (usuario = {}, tokenSesion = '') => {
            crearCookie(config.nomCookieUsuario, JSON.stringify(usuario), true, config.expCookie)
            crearCookie(config.nomCookieToken, tokenSesion, true, config.expCookie)
        }

        if (verificandoSesion) {
            cambiarEstadoVerificarSesion(false)
            console.log(token())

            configurarVerificacionSesion(
                sesionSlice,
                config.nomCookieUsuario,
                config.nomCookieToken,
                `${DOMINIO}/cerrar_sesion`,
                verificarDatosFiscales
            )
        }
        else {
            fijaMensajePantallaCarga('Redirigiendo, por favor espere...')
        }
    }, [verificandoSesion])

    if (verificandoSesion)
        return <CargandoPagina
            mensaje={mensajePantallaCarga}
            colorPrincipal={config.colorPrincipal}
        />
    else
        return <Index />
}

export default Inicio