import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { EkQuery } from '../query';
import { config } from '../../config/config';

export const usuariosApi = createApi({
    reducerPath: 'usuariosApi',
    baseQuery: fetchBaseQuery({ baseUrl: config.crufdekURLV1 }),
    tagTypes: ['Usuarios'],
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        getUsuarios: builder.query({
            query: ({ search, IsSucursal }) => {
                let body = new EkQuery("EurekaAcUsuario")
                    .with(["eureka_ac_usuarios_categorias.eureka_ac_categoria", "eureka_cr_rol_usuarios.eureka_cr_rol"])
                    .where("fecha_registro", "!=", "null")
                    .where("estatus", "!=", "inactivo")
                    .search(search, ["nombre", "correo"])
                    .get();


                if (IsSucursal) {
                    body = new EkQuery("EurekaAcUsuario")
                        .with(["eureka_ac_usuarios_categorias.eureka_ac_categoria", "eureka_cr_rol_usuarios.eureka_cr_rol"])
                        .where("fecha_registro", "!=", null)
                        .where("estatus", "!=", "inactivo")
                        .whereHas("eureka_cr_rol_usuarios.eureka_cr_rol", (query) => {
                            return query.where("nombre", "administrador eventos")
                        })
                        .search(search, ["nombre", "correo"])
                        .paginate();
                }
                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    body: body
                }
            },
            // transformResponse: resp => ({
            //     usuarios: resp.data,
            //     current_page: resp.current_page,
            //     last_page: resp.last_page
            // }),
            // serializeQueryArgs: ({ endpointName }) => {
            //     return endpointName
            // },
            // merge: (currentCache, newItems, { arg }) => {
            //     if (arg.page === 1) {
            //         currentCache.usuarios = [...newItems.usuarios];
            //     } else {
            //         currentCache.usuarios.push(...newItems.usuarios)
            //     }
            // },
            // forceRefetch({ currentArg, previousArg }) {
            //     if (currentArg && previousArg) {
            //         return currentArg.page !== previousArg.page || currentArg.search !== previousArg.search
            //     } else {
            //         return false;
            //     }
            // },
            providesTags: ["usuarios"],
            keepUnusedDataFor: 3600,
        }),
        getUsuario: builder.query({
            query: ({ usuario }) => {
                let body = new EkQuery("EurekaAcUsuario")
                    .where("id", usuario)
                    .with(["eureka_ac_usuarios_categorias.eureka_ac_categoria"])
                    .with("eureka_cr_rol_usuarios", (query) => {
                        return query.where("rol", "!=", "NULL")
                    })
                    .first();

                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    body: body
                }
            },
            providesTags: ["usuario"],
        }),
        getGrupos: builder.query({
            query: ({ usuario, search }) => {
                let body = new EkQuery("EurekaAcCategoria")
                    .where("usuario", usuario)
                    .search(search, ["nombre", "correo"])
                    .with(["eureka_ac_usuario", "eureka_ac_usuarios_categorias.eureka_ac_usuario"])
                    .get();

                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    body: body
                }
            },
            providesTags: ["Grupos"],
        }),
        getUsuariosGrupos: builder.query({
            query: ({ categoria, search }) => {
                let body = new EkQuery("EurekaAcUsuariosCategoria")
                    .where("categoria", categoria)
                    .with(["eureka_ac_usuario"])
                    .get();

                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    body: body
                }
            },
            providesTags: ["GruposUsuarios"],
        }),
        crearGrupos: builder.mutation({
            query: (data) => {
                let body = new EkQuery("EurekaAcCategoria")
                    .create(data.body);

                if (data.id != null) {
                    body = new EkQuery("EurekaAcCategoria")
                        .where("id", data.id)
                        .update(data.body);
                }

                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    body: body
                }
            },
            invalidatesTags: ["Grupos"]
        }),
        addUsuarioGrupo: builder.mutation({
            query: (data) => {
                let body = new EkQuery("EurekaAcUsuariosCategoria")
                    .create(data.body);

                if (data.id != null) {
                    body = new EkQuery("EurekaAcUsuariosCategoria")
                        .where("id", data.id)
                        .update(data.body);
                }

                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    body: body
                }
            },
            invalidatesTags: ["GruposUsuarios"]
        }),
        crearUsuario: builder.mutation({
            query: (data) => {
                let body = new EkQuery("EurekaAcUsuario")
                    .create(data.body);

                if (data.id != null) {
                    body = new EkQuery("EurekaAcUsuario")
                        .where("id", data.id)
                        .update(data.body);
                }

                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    body: body
                }
            },
            invalidatesTags: ["usuarios", "roles"]
        }),
        uploadImageUsuarios: builder.mutation({
            query: (data) => {
                let body = new EkQuery("EurekaAcUsuario")
                    .uploadFile(data.file, data.carpeta, data.nombre);

                if (data.id != null) {
                    body = new EkQuery("EurekaAcUsuario")
                        .where("id", data.id)
                        .uploadFile(data.file, data.carpeta, data.nombre);
                }
                var formulario = new FormData();
                Object.entries(body).forEach(([key, value]) => {
                    formulario.append(key, value);
                });
                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    enctype: "multipart/form-data",
                    body: formulario
                }
            },
            invalidatesTags: ["usuarios", "roles"]
        }),

        getRoles: builder.query({
            query: ({ usuario = 0 }) => {
                let body = new EkQuery("EurekaCrRol")
                    .with("eureka_cr_rol_usuarios", (query) => {
                        return query.where("usuario", usuario === null ? 0 : usuario)
                            .where("rol", "!=", "NULL")
                    })
                    .get();

                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    body: body
                }

            },
            providesTags: ["roles"],
            // transformResponse: resp => (resp.data),
        }),
        agregarRol: builder.mutation({
            query: (data) => {
                let body = new EkQuery("EurekaCrRolUsuario")
                    .create(data);
                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    body: body
                }
            },
            invalidatesTags: ["roles"]
        }),
        removerRol: builder.mutation({
            query: ({ rol, usuario }) => {
                let body = new EkQuery("EurekaCrRolUsuario")
                    .where("rol", rol)
                    .where("usuario", usuario)
                    .delete();
                console.log("removido el rol", rol, "al usuario", usuario)
                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    body: body
                }
            },
            invalidatesTags: ["roles"]
        }),
        removerUsuarioGrupo: builder.mutation({
            query: ({ categoria, usuario }) => {
                let body = new EkQuery("EurekaAcUsuariosCategoria")
                    .where("categoria", categoria)
                    .where("usuario", usuario)
                    .whereNull("rol")
                    .delete();
                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    body: body
                }
            },
            invalidatesTags: ["GruposUsuarios"]
        }),
        searchUsuarios: builder.query({
            query: ({ search }) => {

                let body = new EkQuery("EurekaAcUsuario")

                if (search) {
                    body = body.search(search, ["nombre"])
                        .paginate(100, 1);
                } else {
                    body = body.where("id", 0).first();
                }
                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    body: body
                }
            },
            transformResponse: resp => (resp?.data ?? []),
        }),
        usuarioLogin: builder.query({
            query: (id = null) => {
                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    body: new EkQuery("EurekaAcUsuario")
                        .where("id", id)
                        .first()
                }
            },
        }),
        updateUsuario: builder.mutation({
            query: ({ id, bodyData }) => {
                var body = new EkQuery("EurekaAcUsuario")
                    .where("id", id)
                    .update(bodyData);
                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    body: body
                }
            },
            invalidatesTags: ["usuarios"]
        }),
    }),
})


export const { useSearchUsuariosQuery, useUsuarioLoginQuery, useGetGruposQuery, useGetUsuariosGruposQuery, useGetRolesQuery, useGetUsuariosQuery, useGetUsuarioQuery, useCrearUsuarioMutation, useCrearGruposMutation, useRemoverUsuarioGrupoMutation, useAddUsuarioGrupoMutation, useUploadImageUsuariosMutation, useUpdateUsuarioMutation, useAgregarRolMutation, useRemoverRolMutation } = usuariosApi