import { eliminarSesion } from 'account-react-eurekasigma'
import { config } from '../config/config'
import { CargandoPagina } from 'eureka-design'

const TerminarSesion = () => {
    eliminarSesion(
        config.nomCookieUsuario,
        config.nomCookieToken,
        `${window.location.origin}/iniciar_sesion?inactivo=1`
    )

    return (
        <CargandoPagina
            mensaje="Cerrarando Sesión, por favor espere..."
            colorPrincipal={config.colorPrincipal}
        />
    )
}

export default TerminarSesion