import { Tabla, buildData, ModalForm, InputSearch, Miniatura } from "eureka-design";
import { useSearchUsuariosQuery } from "../../js/service/usuarios.js";
import { useGetAccesoUsuariosQuery, useAddUsuarioAccesosMutation, useRemoverAccesoUsuarioMutation } from "../../js/service/lugares.js";
import { handlerMutation } from "../../js/util.js"
import { useState, useEffect } from "react"

export default function AccesoUsuarios({ UpProps, Push, lugar, busqueda = null, usuario }) {
    const [search, setSearch] = useState(busqueda)
    const [Usuarios, setUsuarios] = useState([]);
    const [ModalGrupo, setModalGrupo] = useState(false)
    const [searchUsuarios, setSearchUsuarios] = useState()
    const { data } = useGetAccesoUsuariosQuery({ search: search, lugar: lugar ?? null })
    const { data: dataUsuarios, isLoading: isLoadingUsuarios, isFetching: isFetchingUsuarios } = useSearchUsuariosQuery({ search: searchUsuarios })
    const [addUsuario, { isLoading: createLoading, isSuccess: createSuccess, isError: createError }] = useAddUsuarioAccesosMutation();
    const [removerUsuario, { isLoading: removerLoading, isSuccess: removerSuccess, isError: removerError }] = useRemoverAccesoUsuarioMutation();
    const handlerInput = (value, nombre) => {
        handlerMutation(addUsuario, { id: undefined, body: { [nombre]: value, sucursal: lugar } })
    }

    useEffect(() => {
        if (createSuccess && !createError) {
            setModalGrupo(false)
        }
    }, [createSuccess]);

    useEffect(() => {
        if (data) {
            let usuarios = []
            data.map((item) => {
                usuarios.push(item.eureka_ac_usuario ?? {})
            })

            setUsuarios(usuarios)
        }
    }, [data]);

    const handlerUsuarios = () => {
        let estadios = dataUsuarios?.map((item, index) => {
            return {
                value: item,
                text: item.nombre,
                icono: () => {
                    return <Miniatura
                        key={index}
                        avatar={true}
                        error={"/img/noimage.jpg"}
                        src={`https://crud.eksuite.com/api/GetFile/ft_usuario/${item.foto}`}
                    />
                }
            }
        });
        return estadios;
    }


    return <>
        {ModalGrupo ? <ModalForm
            isLoading={[createLoading]}
            isError={[createError]}
            isSuccess={[createSuccess]}
            titulo={"Agregar un usuario al grupo"}
            show={ModalGrupo}
            onClose={() => {
                setModalGrupo(false)

            }}
        >
            <InputSearch
                placeholder="Buscar usuario..."
                iconLeft="fa-search"
                nombre="Busca un usuario por su nombre o correo"
                isLoading={isLoadingUsuarios}
                opciones={dataUsuarios ? handlerUsuarios() : []}
                changeFunction={(value) => {
                    setSearchUsuarios(value)
                }}
                OpcionSelect={(item) => {
                    // setValueEstadio(item.nombre)
                    // setEstadio(item.id)
                    handlerInput(item.id, "usuario")
                }}
            />

        </ModalForm> : undefined}
        <Tabla
            busqueda={{
                placeholder: "Buscar usuario...",
                value: search,
                fn: (value) => {
                    setSearch(value === "" ? null : value)
                    UpProps({ busqueda: value === "" ? null : value })
                }
            }}
            checkbox={false}
            rowSize="small"
            keys={[
                {
                    text: "Todos",
                    detalle: data && data.length > 0 ? data.length : 0,
                    filtro: () => true
                }
            ]}
            onClickRow={(item) => {
                Push("usuario", { perfilUser: item.item.id }, "Usuarios")
            }}
            botonesHead={[
                {
                    icono: "fa-solid fa-plus",
                    tooltip: "Agregar usuario",
                    onClick: (item) => {
                        setModalGrupo(true)
                    }
                }
            ]}
            botonesRow={[
                {
                    icono: "fa-trash",
                    tooltip: "Borrar usuario",
                    onClick: (item) => {
                        if (!removerLoading) removerUsuario({ lugar: lugar, usuario: item.item.id })
                    },
                }
            ]}
            headers={[
                { text: "Nombre", key: "nombre", type: "text" },
                { text: "Correo", key: "correo", type: "text" },
                { text: "Teléfono", key: "telefono", type: "text" },
            ]}
            data={buildData(Usuarios ?? [], [
                { key: "nombre", type: "text" },
                { key: "correo", type: "text" },
                { key: "telefono", type: "text" }
            ])}
        >

        </Tabla>
    </>

}