import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { EkQuery } from '../query';
import { config } from '../../config/config';

export const franquiciasApi = createApi({
    reducerPath: 'franquiciasApi',
    baseQuery: fetchBaseQuery({ baseUrl: config.crufdekURLV1 }),
    tagTypes: ['Franquicias', 'Sucursales'],
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        getFranquicias: builder.query({
            query: ({ search, usuario, roles }) => {
                let body;


                if (roles.RolFranquicias) {
                    body = new EkQuery("EurekaBsSucursale")
                        .where("tipo", "Franquicia")
                        .where("usuario", usuario?.id)
                        .with(["eureka_ac_usuario", "eureka_bs_sucursales", "eureka_in_domicilio"])
                        .search(search, ["nombre"])
                        .get();
                }

                if (roles.RolLugares) {
                    body = new EkQuery("EurekaBsSucursale")
                        .where("tipo", "Franquicia")
                        .with(["eureka_ac_usuario", "eureka_bs_sucursales", "eureka_in_domicilio"])
                        .search(search, ["nombre"])
                        .get();
                }

                if (roles.RolGeneral) {
                    body = new EkQuery("EurekaBsSucursale")
                        .where("tipo", "Franquicia")
                        .with(["eureka_ac_usuario", "eureka_bs_sucursales", "eureka_in_domicilio"])
                        .search(search, ["nombre"])
                        .get();
                }

                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    body: body
                }
            },
            providesTags: ["Franquicias"],
            keepUnusedDataFor: 3600,
        }),
        getFranquicia: builder.query({
            query: ({ franquicia }) => {
                let body = new EkQuery("EurekaBsSucursale")
                    .where("id", franquicia)
                    .where("tipo", "Franquicia")
                    .with(["eureka_ac_usuario", "eureka_bs_sucursales", "eureka_in_domicilio"])
                    .first();

                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    body: body
                }
            },
            providesTags: ["Franquicia"],
        }),
        crearFranquicia: builder.mutation({
            query: (data) => {
                var body = new EkQuery("EurekaBsSucursale")
                    .create(data.body);

                if (data.id != null) {
                    body = new EkQuery("EurekaBsSucursale")
                        .where("id", data.id)
                        .update(data.body);
                }

                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    body: body
                }
            },
        }),
        searchFranquicias: builder.query({
            query: ({ search }) => {
                let body = new EkQuery("EurekaBsSucursale")
                    .where("estatus", "activo")
                    .where("tipo", "Franquicia")
                    .search(search, ["nombre"])
                    .get();

                if (!search) {
                    body = [];
                }
                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    body: body
                }
            },
            providesTags: ["SearchFranquicias"],
            keepUnusedDataFor: 3600,
        }),
        searchEstadios: builder.query({
            query: ({ search, is_acceso }) => {
                let body = new EkQuery("EurekaBsSucursale")
                    .where("tipo", "Estadio")
                if (!is_acceso) {
                    if (search && search.length > 0) {
                        body = body.search(search, ["nombre"])
                            .get();
                    } else {
                        body = body.where("id", 0).get();
                    }
                } else {
                    if (search && search.length > 0) {
                        body = body.search(search, ["nombre"])
                            .where("estatus", "activo")
                            .whereHas("eureka_cr_rol_usuarios", (query) => {
                                return query.where("usuario", is_acceso)
                            }).get();
                    } else {
                        body = body.where("estatus", "activo").where("id", 0).get();
                    }
                }

                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    body: body
                }
            },
            providesTags: ["SearchEstadios"],
            keepUnusedDataFor: 3600,
        }),
        getSucursales: builder.query({
            query: ({ search, franquicia, usuario, roles }) => {
                let withs = [
                    "eureka_bs_local",
                    "eureka_bs_franquicia",
                    "eureka_ac_usuario",
                    "eureka_st_productos_almacenes",
                    "eureka_st_transacciones_sucursals.eureka_st_transaccione",
                    "eureka_bs_sucursal_coberturas.eureka_bs_areas_sucursale.eureka_bs_sucursale"];
                let body;
                if (!franquicia && usuario && roles.RolLugares) {
                    body = new EkQuery("EurekaBsSucursale")
                        .where("tipo", "Sucursal")
                        .with(withs)
                        .whereHas("eureka_bs_local", (q) => {
                            return q.where("usuario", usuario.id)
                        })
                        .orWhere("usuario", usuario?.id)
                        .where("tipo", "Sucursal")
                        .search(search, ["nombre"])
                        .get();
                }

                if (roles.RolGeneral && franquicia || roles.RolFranquicias && franquicia) {
                    body = new EkQuery("EurekaBsSucursale")
                        .where("tipo", "Sucursal")
                        .where("franquicia", franquicia)
                        .with(withs)
                        .search(search, ["nombre"])
                        .get();
                }

                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    body: body
                }
            },
            providesTags: ["Sucursales"],
            keepUnusedDataFor: 3600,
        }),
        updateSucFran: builder.mutation({
            query: ({ id, bodyData, tipo = "Sucursal" }) => {
                var body = new EkQuery("EurekaBsSucursale")
                    .where("id", id)
                    .update(bodyData);
                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    body: body
                }
            },
            invalidatesTags: (result, error, arg) => [arg.tipo],
        }),
        getAsignaciones: builder.query({
            query: ({ page, search, limit, sucursal }) => {
                let withs = ["eureka_ac_usuario"]
                let body = new EkQuery("EurekaCrRolUsuario")
                    .where("sucursal", sucursal)
                    .where("rol", null)
                    .with(withs)
                    .search(search, ["nombre"], "eureka_ac_usuario")
                    .paginate(limit, page)
                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    body: body
                }
            },
            transformResponse: resp => ({
                asignaciones: resp.data,
                current_page: resp.current_page,
                last_page: resp.last_page
            }),
            serializeQueryArgs: ({ endpointName }) => {
                return endpointName
            },
            merge: (currentCache, newItems, { arg }) => {
                if (arg.page === 1) {
                    currentCache.asignaciones = [...newItems.asignaciones];
                } else {
                    currentCache.asignaciones.push(...newItems.asignaciones)
                }
            },
            forceRefetch({ currentArg, previousArg }) {
                if (currentArg && previousArg) {
                    return currentArg.page !== previousArg.page || currentArg.search !== previousArg.search
                } else {
                    return false;
                }
            },
            providesTags: ["Asignaciones"],
            keepUnusedDataFor: 3600,
        }),
        crearAsignacion: builder.mutation({
            query: (data) => {
                var body = new EkQuery("EurekaCrRolUsuario")
                    .create(data.body);

                if (data.id != null) {
                    body = new EkQuery("EurekaCrRolUsuario")
                        .where("id", data.id)
                        .update(data.body);
                }

                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    body: body
                }
            },
            invalidatesTags: ["Asignaciones"],
        }),
    }),
})


export const { useGetFranquiciasQuery, useGetFranquiciaQuery, useSearchFranquiciasQuery, useSearchEstadiosQuery, useCrearFranquiciaMutation, useGetSucursalesQuery, useUpdateSucFranMutation, useGetAsignacionesQuery, useCrearAsignacionMutation } = franquiciasApi