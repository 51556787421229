import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { EkQuery } from '../query';
import { config } from '../../config/config';

export const productosApi = createApi({
    reducerPath: 'productosApi',
    baseQuery: fetchBaseQuery({ baseUrl: config.crufdekURLV1 }),
    tagTypes: ["Categorias", "Productos"],
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        getCategorias: builder.query({
            query: ({ user, franquicia, categoria }) => {

                let body = new EkQuery("EurekaStNivele")
                    .has("eureka_st_niveles_rutas")
                    .doesntHave("eureka_st_niveles_rutas_hijos")
                    .where("sucursal", franquicia)
                    .orWhere("sucursal", franquicia)
                    .doesntHave("eureka_st_niveles_rutas_hijos")
                    .get();
                if (categoria) {
                    body = new EkQuery("EurekaStNivele")
                        .whereHas("eureka_st_niveles_rutas_hijos", (query) => {
                            return query.where("padre", categoria)
                        })
                        .where("sucursal", franquicia)
                        .get();
                }

                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    body: body
                }
            },
            providesTags: ["Categorias"],
            keepUnusedDataFor: 3600,
        }),
        getCategoria: builder.query({
            query: ({ categoria }) => {

                let body = new EkQuery("EurekaStNivele")
                    .where("id", categoria ?? null)
                    .first();

                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    body: body
                }
            },
            providesTags: ["Categoria"],
            keepUnusedDataFor: 3600,
        }),
        getProductos: builder.query({
            query: ({ search, franquicia, categoria }) => {

                // var body = new EkQuery("EurekaStProducto")
                //     .where("sucursal", franquicia)
                //     .with(["eureka_cr_multimedia", "eureka_st_nivele"])
                let body = new EkQuery("EurekaStProducto")
                    .where("sucursal", franquicia)
                    .with(["eureka_cr_multimedia", "eureka_st_nivele"])
                    .search(search, ["nombre", "clave"])
                    .orderBy("nombre", "ASC")
                if (categoria && categoria != null) {
                    body = body.whereHas("eureka_st_nivele", (query) => {
                        return query.whereIn("id", [categoria])
                    })
                }
                // body = body.orderBy("nombre", "ASC").paginate(limit, page)

                body = body.get()
                // if (!isAdmin && search != "") {
                //     body = new EkQuery("EurekaStProducto")
                //         .with(["eureka_cr_multimedia", "eureka_st_nivele"])
                //         .where("usuario", user)
                //         .where("sucursal", franquicia)
                //         .search(search, ["nombre", "clave"])
                //         .orderBy("nombre", "ASC")
                //         .paginate(limit, page)

                // } else if (isAdmin && search != "") {
                //     body = new EkQuery("EurekaStProducto")
                //         .where("sucursal", franquicia)
                //         .with(["eureka_cr_multimedia", "eureka_st_nivele"])
                //         .search(search, ["nombre", "clave"])
                //         .orderBy("nombre", "ASC")
                //         .paginate(limit, page)
                // }

                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    body: body
                }
            },
            providesTags: ["Productos"],
            keepUnusedDataFor: 3600,
        }),
        crearCategoria: builder.mutation({
            query: (data) => {
                var body = new EkQuery("EurekaStNivele")
                    .create(data.body);

                if (data.id != null) {
                    body = new EkQuery("EurekaStNivele")
                        .where("id", data.id)
                        .update(data.body);
                }
                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    body: body
                }
            },
            invalidatesTags: ["Categorias", "Categoria"],
        }),
        crearCategoriaRuta: builder.mutation({
            query: (data) => {
                var body = new EkQuery("EurekaStNivelesRuta")
                    .create(data.body);

                if (data.id != null) {
                    body = new EkQuery("EurekaStNivelesRuta")
                        .where("id", data.id)
                        .update(data.body);
                }
                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    body: body
                }
            },
            invalidatesTags: ["Categorias"]
        }),
        crearProductos: builder.mutation({
            query: (data) => {
                var body = new EkQuery("EurekaStProducto")
                    .create(data.body);

                if (data.id != null) {
                    body = new EkQuery("EurekaStProducto")
                        .where("id", data.id)
                        .update(data.body);
                }
                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    body: body
                }
            },
            invalidatesTags: ["Productos", "Categorias"],
        }),
        uploadImageMultimedia: builder.mutation({
            query: (data) => {
                var body = new EkQuery("EurekaCrMultimedia")
                    .uploadFile(data.file, data.carpeta, data.nombre);

                if (data.id != null) {
                    body = new EkQuery("EurekaCrMultimedia")
                        .where("id", data.id)
                        .uploadFile(data.file, data.carpeta, data.nombre);
                }

                var formulario = new FormData();
                Object.entries(body).forEach(([key, value]) => {
                    formulario.append(key, value);
                });
                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    enctype: "multipart/form-data",
                    body: formulario
                }
            },
            invalidatesTags: ["Categorias", "Productos"]
        }),
        borrarCategoria: builder.mutation({
            query: (data) => {
                var body;

                if (data.id != null) {
                    body = new EkQuery("EurekaStNivele")
                        .where("id", data.id)
                        .delete();
                }
                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    body: body
                }
            },
            invalidatesTags: ["Categorias"]
        }),
    }),
})


export const { useGetCategoriasQuery, useGetCategoriaQuery, useGetProductosQuery, useCrearCategoriaMutation, useCrearCategoriaRutaMutation, useUploadImageMultimediaMutation, useBorrarCategoriaMutation, useCrearProductosMutation } = productosApi