import { Formulario, Input, ImageInput, InputSearch, Select, Miniatura } from "eureka-design"
import { useUploadImageLugaresMutation, useCrearDomicilioMutation } from "../../js/service/lugares.js";
import { useCrearFranquiciaMutation, useSearchEstadiosQuery, useSearchFranquiciasQuery } from "../../js/service/franquicias.js";
import { useEffect, useState } from 'react';
import { handlerMutation } from "../../js/util.js"


export default function FormSucursales({ UpProps, franquicia, sucursal, prevUrl, prevFile, nombre, giro, numerolocal, franquiciaName, estadioName, usuario, roles }) {
    const [idSucursal, setIdSucursal] = useState(sucursal);
    const [idFranquicia, setIdFranquicia] = useState(franquicia);
    const [searchEstadio, setSearchEstadio] = useState(null)
    const [searchFranquicia, setSearchFranquicia] = useState(null)
    const [valueEstadio, setValueEstadio] = useState(estadioName);
    const [valueFranquicia, setValueFranquicia] = useState(franquiciaName);

    const [CrearSucursal, { isLoading: inputIsloading, isError: inputIsError, isSuccess: inputIsSuccess }] = useCrearFranquiciaMutation();
    const [uploadFoto, { isLoading: imageisLoading, isError: imageIsError, isSuccess: imageIsSuccess }] = useUploadImageLugaresMutation();
    const { data: Estadios, isLoadingEstadios } = useSearchEstadiosQuery({ search: searchEstadio })
    const { data: Franquicias, isLoadingFranquicias } = useSearchFranquiciasQuery({ search: searchFranquicia })

    const handlerInput = (value, nombre) => {
        handlerMutation(CrearSucursal, {
            id: idSucursal, body: {
                tipo: "Sucursal",
                franquicia: idFranquicia,
                [nombre]: value,
                usuario: usuario.id
            }
        }, (r) => {
            if (r?.mensaje?.id) {
                setIdSucursal(r.mensaje.id)
                UpProps({ sucursal: r.mensaje.id })
            }
        })
        if (nombre !== "franquicia")
            UpProps({ [nombre]: value })
    }

    const handlerPhoto = (e) => {
        handlerMutation(uploadFoto, { id: idSucursal, file: e.target.files[0], carpeta: "ft_sucursales", nombre: "portada" }, (r) => {
            if (r?.mensaje?.id) {
                setIdSucursal(r.mensaje.id)
                UpProps({ portada: r.mensaje.id })
            }
        })
        UpProps({ prevFile: e.target.files[0] })
    }

    const handlerEstadios = () => {
        let estadios = Estadios?.map((item, index) => {
            return {
                value: item,
                text: item.nombre,
                icono: () => {
                    return <Miniatura
                        key={index}
                        avatar={true}
                        error={"/img/noimage.jpg"}
                        src={`https://crud.eksuite.com/api/GetFile/ft_estadios/${item.portada}`}
                    />
                }
            }
        });
        return estadios;
    }


    const handlerFranquicias = () => {
        let franquicias = Franquicias?.map((item, index) => {
            return {
                value: item,
                text: item.nombre,
                icono: () => {
                    return <Miniatura
                        key={index}
                        avatar={true}
                        error={"/img/noimage.jpg"}
                        src={`https://crud.eksuite.com/api/GetFile/ft_franquicias/${item.portada}`}
                    />
                }
            }
        });
        return franquicias;
    }


    return (
        <Formulario
            titulo="Agrega una sucursal"
            isLoading={[inputIsloading, imageisLoading]}
            isError={[inputIsError, imageIsError]}
            isSuccess={[inputIsSuccess, imageIsSuccess]}>
            <ImageInput
                width={250}
                height={250}
                changeFunction={handlerPhoto}
                preview={prevUrl}
                file={prevFile}
            >
            </ImageInput>
            <Input
                min={5}
                max={255}
                changeFunction={handlerInput}
                isLoading={inputIsloading}
                placeholder={"Nombre"}
                nombre={"nombre"}
                type={"text"}
                value={nombre}
                iconLeft={"fa-file-contract"}
            />
            <Input
                min={1}
                max={12}
                changeFunction={handlerInput}
                isLoading={inputIsloading}
                placeholder={"Numero de local"}
                nombre={"numerolocal"}
                type={"text"}
                value={numerolocal}
                iconLeft={" fa-store"}
            />
            <Select
                value={giro}
                placeholder={"Giro de la sucursal"}
                changeFunction={(value, nombre) => handlerInput(value.value, nombre)}
                iconLeft={"fa-tag"}
                options={[
                    { value: "Comida", text: "Comida" },
                    { value: "Ropa", text: "Ropa" },
                    { value: "Entretenimiento", text: "Entretenimiento" },
                    { value: "Tecnología", text: "Tecnología" },
                    { value: "Otros", text: "Otros" },
                ]}
                nombre={"giro"}
            ></Select>
            {!roles.RolGeneral && !roles.RolFranquicias && roles.RolLugares ?
                <InputSearch
                    value={valueFranquicia}
                    iconLeft={" fa-store-alt"}
                    placeholder={"Franquicia"}
                    isLoading={isLoadingFranquicias}
                    changeFunction={(value) => {
                        setSearchFranquicia(value)
                    }}
                    OpcionSelect={(item) => {
                        setValueFranquicia(item.nombre)
                        setIdFranquicia(item.id)
                        handlerInput(item.id, "franquicia")
                    }}
                    opciones={Franquicias ? handlerFranquicias() : []}
                >
                </InputSearch> : undefined}
            <InputSearch
                value={valueEstadio}
                changeFunction={(value) => {
                    setSearchEstadio(value)
                }}
                OpcionSelect={(item) => {
                    setValueEstadio(item.nombre)
                    handlerInput(item.id, "local")
                }}
                isLoading={isLoadingEstadios}
                opciones={Estadios ? handlerEstadios() : []}
                iconLeft={"fa-solid fa-map-marker"}
                placeholder={"Estadio o lugar"}
            />

        </Formulario>
    )
}
