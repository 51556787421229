import { Tabla, buildData, ChipEstatus, Miniatura, ChipContact } from "eureka-design";
import { useGetUsuariosQuery, useCrearUsuarioMutation } from "../../js/service/usuarios";
import { config } from "../../config/config";
import { useState, useEffect } from "react"

export default function Usuarios({ UpProps, Push, ShowAlert, busqueda = null, getPermiso }) {
    const [search, setSearch] = useState(busqueda)
    const { data, isLoading, isFetching } = useGetUsuariosQuery({ search: search, user: 1, isAdmin: true, isSucursal: true })
    const [actualizarUsuario] = useCrearUsuarioMutation();

    const [keysInfo, setKeysInfo] = useState({ activos: 0, bloqueados: 0, inactivos: 0 })

    useEffect(() => {
        if (data) {
            const activos = data.filter((item) => item.estatus === "activo")
            if (activos.length > 0) {
                setKeysInfo({ ...keysInfo, activos: activos.length })
            }
            const bloqueados = data.filter((item) => item.estatus === "bloqueado")
            if (bloqueados.length > 0) {
                setKeysInfo({ ...keysInfo, bloqueados: bloqueados.length })
            }
            const inactivos = data.filter((item) => item.estatus === "inactivo")
            if (inactivos.length > 0) {
                setKeysInfo({ ...keysInfo, inactivos: inactivos.length })
            }
        }
    }, [data]);

    return <>
        <Tabla
            busqueda={{
                placeholder: "Buscar usuario...",
                value: search,
                fn: (value) => {
                    setSearch(value === "" ? null : value)
                    UpProps({ busqueda: value === "" ? null : value })
                }
            }}
            checkbox={false}
            rowSize="small"
            keys={[
                {
                    text: "Todos",
                    detalle: data && data.length > 0 ? data.length : 0,
                    filtro: () => true
                },
                {
                    text: "Activos",
                    detalle: keysInfo.activos,
                    filtro: (item) => {
                        return item.value === "activo";
                    }
                },
                {
                    text: "Bloqueados",
                    detalle: keysInfo.bloqueados + keysInfo.inactivos,
                    filtro: item => {
                        return item.value === "inactivo" || item.value === "bloqueado";
                    }
                }
            ]}
            onClickRow={(item) => {
                Push("usuario", { perfilUser: item.item.id })
            }}
            botonesHead={[
                {
                    icono: "fa-solid fa-plus",
                    tooltip: "Agregar usuario",
                    onClick: (item) => {
                        Push("formUsuarios")
                    }
                }
            ]}
            botonesRow={[
                {
                    icono: "fa-solid fa-file-edit",
                    tooltip: "Editar",
                    onClick: (item) => {
                        item = item.item;
                        Push('formUsuarios', {
                            userid: item.id, foto: null, prevUrl: null, prevFile: null, nombre: item.nombre, correo: item.correo, telefono: item.telefono,
                            prevUrl: `https://crud.eksuite.com/api/GetFile/ft_usuario/${item.foto}`
                        })
                    },
                },
                {
                    icono: "fa-solid fa-lock",
                    tooltip: "Bloquear usuario",
                    onClick: (item) => {
                        item = item.item;
                        ShowAlert({
                            icono: "info", // success, error, warning, info
                            titulo: "¿Estas seguro de bloquear este usuario?",
                            mensaje: "Le impedirá el acceso a la aplicación",
                            botones: [
                                {
                                    texto: "Cancelar",
                                    fn: () => { }
                                },
                                {
                                    texto: "si bloquear",
                                    fn: () => {
                                        actualizarUsuario({ id: item.id, body: { estatus: "bloqueado" } })
                                    }
                                }
                            ]
                        })
                    }
                },
                {
                    icono: " fa-solid fa-archive",
                    tooltip: "Archivar usuario",
                    onClick: (item) => {
                        item = item.item;
                        ShowAlert({
                            icono: "info", // success, error, warning, info
                            titulo: "¿Estas seguro de archivar este usuario?",
                            mensaje: "Le impedirá el acceso a la aplicación y no veras su información en la lista de usuarios",
                            botones: [
                                {
                                    texto: "Cancelar",
                                    fn: () => { }
                                },
                                {
                                    texto: "si archivar",
                                    fn: () => {
                                        actualizarUsuario({ id: item.id, body: { estatus: "inactivo" } })
                                    }
                                }
                            ]
                        })
                    }
                }
            ]}
            headers={[
                { text: "", key: "foto", type: "text", size: "small" },
                { text: "Nombre", key: "nombre", type: "text" },
                { text: "Correo", key: "correo", type: "text" },
                { text: "Teléfono", key: "telefono", type: "text" },
                { text: "", key: "estatus", type: "text" },
            ]}
            data={buildData(data ?? [], [
                {
                    key: "foto", size: "small", type: "text", text: (item) => {
                        return <Miniatura
                            avatar={true}
                            error={"/img/nouser.jpg"}
                            src={item?.foto ? `${config.crufdekFileURL}/ft_usuario/${item?.foto}/imagen` : "/img/nouser.jpg"}
                        />
                    },
                },
                { key: "nombre", type: "text" },
                { key: "correo", type: "text" },
                { key: "telefono", type: "telefono" },
                {
                    key: "estatus", type: "text",
                    text: (item) => {
                        let chip;
                        if (item.estatus === "activo") {
                            chip = <ChipEstatus texto={"Aceptado"} tipo="Aceptado" />
                        }

                        if (item.estatus === "inactivo") {
                            chip = <ChipEstatus texto={"Archivado"} tipo="Error" />
                        }

                        if (item.estatus === "bloqueado") {
                            chip = <ChipEstatus texto={"Bloqueado"} tipo="Error" />
                        }

                        return chip;
                    }
                },
            ])}
        >

        </Tabla>
    </>

}