import { Tabla, buildData, ModalForm, InputSearch, Miniatura, ChipEstatus, ChipContact } from "eureka-design";
import { useGetAgrupacionesEventoQuery, useAddEventoGrupoMutation, useSearchEventosQuery, useRemoverEventoGrupoMutation } from "../../js/service/eventos.js";

import { handlerMutation } from "../../js/util.js"
import { useState, useEffect } from "react"

export default function AgrupacionesEventos({ UpProps, Push, grupo, busqueda = null, usuario }) {
    const [search, setSearch] = useState(busqueda)
    const [Eventos, setEventos] = useState([]);
    const [ModalGrupo, setModalGrupo] = useState(false)
    const [searchEventos, setSearchEventos] = useState()
    const { data } = useGetAgrupacionesEventoQuery({ search: search, grupo: grupo ?? null })
    const { data: dataEventos, isLoading: isLoadingEventos, isFetching: isFetchingEventos } = useSearchEventosQuery({ search: searchEventos })
    const [addEvento, { isLoading: createLoading, isSuccess: createSuccess, isError: createError }] = useAddEventoGrupoMutation();
    const [removerEvento, { isLoading: removerLoading, isSuccess: removerSuccess, isError: removerError }] = useRemoverEventoGrupoMutation();

    const handlerInput = (value, nombre,) => {
        handlerMutation(addEvento, { id: undefined, body: { [nombre]: value, grupo: grupo } })
    }

    useEffect(() => {
        if (createSuccess && !createError) {
            setModalGrupo(false)
        }
    }, [createSuccess]);

    useEffect(() => {
        if (data) {
            let eventos = []
            data.map((item) => {
                eventos.push(item.eureka_bs_evento ?? {})
            })
            setEventos(eventos)
        }
    }, [data]);

    const handlerUsuarios = () => {
        let estadios = dataEventos?.map((item, index) => {
            return {
                value: item,
                text: item.nombre,
                icono: () => {
                    return <Miniatura
                        key={index}
                        avatar={true}
                        error={"/img/noimage.jpg"}
                        src={`https://crud.eksuite.com/api/GetFile/ft_usuario/${item.foto}`}
                    />
                }
            }
        });
        return estadios;
    }


    return <>
        {ModalGrupo ? <ModalForm
            isLoading={[createLoading]}
            isError={[createError]}
            isSuccess={[createSuccess]}
            titulo={"Agregar un evento al grupo"}
            show={ModalGrupo}
            onClose={() => {
                setModalGrupo(false)

            }}
        >
            <InputSearch
                placeholder="Buscar evento..."
                iconLeft="fa-search"
                nombre="Busca un evento por su nombre"
                isLoading={isLoadingEventos}
                opciones={dataEventos ? handlerUsuarios() : []}
                changeFunction={(value) => {
                    setSearchEventos(value)
                }}
                OpcionSelect={(item) => {
                    // setValueEstadio(item.nombre)
                    // setEstadio(item.id)
                    handlerInput(item.id, "evento")
                }}
            />

        </ModalForm> : undefined}
        <Tabla
            busqueda={{
                placeholder: "Buscar evento...",
                value: search,
                fn: (value) => {
                    setSearch(value === "" ? null : value)
                    UpProps({ busqueda: value === "" ? null : value })
                }
            }}
            checkbox={false}
            rowSize="small"
            keys={[
                {
                    text: "Todos",
                    detalle: data && data.length > 0 ? data.length : 0,
                    filtro: () => true
                }
            ]}
            onClickRow={(item) => {
                // Push("usuario", { perfilUser: item.item.id }, "Usuarios")
            }}
            botonesHead={[
                {
                    icono: "fa-solid fa-plus",
                    tooltip: "Agregar evento",
                    onClick: (item) => {
                        setModalGrupo(true)
                    }
                }
            ]}
            botonesRow={[
                {
                    icono: "fa-trash",
                    tooltip: "Borrar del grupo",
                    onClick: (item) => {
                        item = item.item;
                        if (!removerLoading) removerEvento({ grupo: grupo, evento: item.id })
                    },
                }
            ]}
            headers={[
                { text: "Nombre", key: "nombre", type: "text" },
                { text: "Organizador", key: "usuarios", type: "text" },
                { text: "Estadio", key: "sucursales", type: "text" },
                { text: "", key: "estatus", type: "text" },
            ]}
            data={buildData(Eventos ?? [], [
                { key: "nombre", type: "text" },

                {
                    key: "usuarios", type: "text", value: (item) => item?.eureka_ac_usuario?.nombre ?? "",
                    text: (item) => {
                        if (item?.eureka_ac_usuario) {
                            return <ChipContact texto={item?.eureka_ac_usuario?.nombre}
                                onClick={() => {
                                    Push("usuario", { perfilUser: item?.eureka_ac_usuario?.id }, "Usuarios")

                                }}
                                error={"/img/nouser.jpg"}
                                imagen={`https://crud.eksuite.com/api/GetFile/ft_usuario/${item.eureka_ac_usuario?.foto}/imagen`} />
                        }
                    }
                },
                {
                    key: "sucursales", type: "text", text: (item) => {
                        if (item?.eureka_bs_sucursale) {
                            return <ChipContact texto={item?.eureka_bs_sucursale?.nombre}
                                onClick={() => {
                                    Push("lugar", { perfilEstadio: item?.eureka_bs_sucursale?.id }, "Lugares")
                                }}
                                error={"/img/noimage.jpg"}
                                imagen={`https://crud.eksuite.com/api/GetFile/ft_estadios/${item.eureka_bs_sucursale?.portada}/imagen`} />
                        }
                    },
                    value: (item) => item?.eureka_bs_sucursale?.nombre ?? ""
                },
                {
                    key: "estatus", type: "text",
                    value: (item) => {
                        let estatus = item.estatus;

                        if (!item.eureka_bs_sucursale) {
                            estatus = "Sin estadio"
                        }
                        return estatus;
                    },
                    text: (item) => {
                        let chip;
                        if (item.estatus === "activo") {
                            chip = <ChipEstatus texto={"Aceptado"} tipo="Aceptado" />
                        }

                        if (item.estatus === "inactivo") {
                            chip = <ChipEstatus texto={"Cancelado"} tipo="Error" />
                        }

                        if (item.estatus === "pendiente") {
                            chip = <ChipEstatus texto={"Pendiente"} tipo="Pendiente" />
                        }

                        if (!item.eureka_bs_sucursale) {
                            chip = <ChipEstatus texto={"Sin estadio"} tipo="Pendiente" />
                        }
                        return chip;
                    }
                }

            ])}
        >

        </Tabla>
    </>
}