import { PerfilCard, PerfilDetalleSection, PerfilDetalleInfo, ChipEstatus, ChipContact } from "eureka-design"
import { useGetEventoGrupoQuery } from "../../js/service/eventos"
import { useState, useEffect } from "react"


export default function PerfilEventosGrupos({ Push, grupo }) {
    const { data, isLoading, isFetching } = useGetEventoGrupoQuery({ grupo })
    const [Portada, setPortada] = useState()

    useEffect(() => {
        if (data) {
            if (data?.eureka_cr_multimedia?.archivo) {
                setPortada(`https://crud.eksuite.com/api/GetFile/ft_bonos/${data?.eureka_cr_multimedia?.archivo}`)
            }
        }
    }, [data]);

    const EstatusEvento = () => {

        let chip;
        if (data?.estatus === "activo") {
            chip = <ChipEstatus texto={"Activo"} tipo="Aceptado" />
        }

        if (data?.estatus === "cancelado") {
            chip = <ChipEstatus texto={"Cancelado"} tipo="Error" />
        }

        if (data?.estatus === "lleno") {
            chip = <ChipEstatus texto={"Saturado"} tipo="Pendiente" />
        }

        if (!data?.eureka_bs_sucursale) {
            chip = <ChipEstatus texto={"Sin estadio"} tipo="Pendiente" />
        }
        return chip;

    }

    return (<PerfilCard
        titulo={data?.nombre}
        errorImage="/img/noimage.jpg"
        portada={Portada}
        aspectRadio={"21/9"}
    >
        <PerfilDetalleInfo titulo="Información del grupo"
            boton={
                {
                    texto: "Editar",
                    icono: "fa-solid fa-file-edit",
                    fn: () => {
                        Push('formGrupos', {
                            sucursal: data?.eureka_bs_sucursale?.id ?? null,
                            grupo: data?.id, portada: null, nombre: data?.nombre, descripcion: data?.descripcion,
                            precio: data?.precio,
                            prevFile: undefined,
                            estadioName: data?.eureka_bs_sucursale?.nombre ?? null,
                            usuario: { id: data?.eureka_ac_usuario?.id ?? null },
                            prevUrl: `https://crud.eksuite.com/api/GetFile/${data?.eureka_cr_multimedia?.carpeta}/${data?.eureka_cr_multimedia?.archivo}`
                        })
                    }
                }
            }>

            <PerfilDetalleSection titulo={"Descripción"}>
                <span>{data?.descripcion ?? "Sin descripción"}</span>
            </PerfilDetalleSection>
            <PerfilDetalleSection titulo={"Precio"}>
                <span>{`$${data?.precio}` ?? "Sin precio"}</span>
            </PerfilDetalleSection>
            <PerfilDetalleSection titulo={"Estatus actual del evento"}>
                {
                    EstatusEvento()
                }
            </PerfilDetalleSection>
            <PerfilDetalleSection titulo={"Creador del grupo"}>
                <ChipContact texto={data?.eureka_ac_usuario?.nombre}
                    onClick={() => {
                        Push("usuario", { perfilUser: data?.eureka_ac_usuario?.id }, "Usuarios")

                    }}
                    error={"/img/nouser.jpg"}
                    imagen={`https://crud.eksuite.com/api/GetFile/ft_usuario/${data?.eureka_ac_usuario?.foto}/imagen`} />
            </PerfilDetalleSection>
            <PerfilDetalleSection titulo={"Lugar del evento"}>
                <ChipContact texto={data?.eureka_bs_sucursale?.nombre}
                    onClick={() => {
                        Push("lugar", { perfilEstadio: data?.eureka_bs_sucursale?.id }, "Lugares")
                    }}
                    error={"/img/noimage.jpg"}
                    imagen={`https://crud.eksuite.com/api/GetFile/ft_estadios/${data?.eureka_bs_sucursale?.portada}/imagen`} />
            </PerfilDetalleSection>

        </PerfilDetalleInfo>
        <PerfilDetalleInfo titulo="Eventos del grupo"
        >
            {
                data?.eureka_bs_agrupaciones?.map((item, index) => {

                    return <ChipContact
                        key={index}
                        texto={item.eureka_bs_evento?.nombre}
                        onClick={() => {
                            Push("evento", { perfilEvento: item.eureka_bs_evento.id }, "Eventos")
                        }}
                        error={"/img/noimage.jpg"}
                        imagen={`https://crud.eksuite.com/api/GetFile/ft_eventos/${item?.eureka_bs_evento?.eureka_cr_multimedia_por?.archivo}`}
                    />
                })
            }
        </PerfilDetalleInfo>
    </PerfilCard>

    )
}
