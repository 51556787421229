import { Tabla, buildData, ChipEstatus, Miniatura, LoaderCircle, ModalForm, Input, ImageInput } from "eureka-design"
import { useGetCategoriasQuery, useGetProductosQuery, useGetCategoriaQuery, useCrearCategoriaMutation, useCrearCategoriaRutaMutation, useUploadImageMultimediaMutation } from "../../js/service/productos"
import { useState, useEffect } from "react";
import { config } from "../../config/config";
import { handlerMutation } from "../../js/util.js"

export default function Productos({ UpProps, Push, ChangeScene, busqueda, usuario, franquicia, SelectCategoria }) {
    const [search, setSearch] = useState(busqueda)
    const [Categoria, setCategoria] = useState(SelectCategoria);
    const [Historial, setHistorial] = useState([])
    const [ModalCategorias, setModalCategorias] = useState({ idCategoria: null, idRuta: null, nombre: "", fotoPrev: null, show: false })

    const { data: Categorias, isLoading: LoadingCategorias, isFetching: FetchingCategorias } = useGetCategoriasQuery({
        user: usuario.id,
        franquicia: franquicia,
        categoria: Categoria
    });

    const { data: MainCategoria, isLoading: LoadingMainCategoria, isFetching: FetchingMainCategoria } = useGetCategoriaQuery({ categoria: Categoria });
    const { data: Productos, isLoading: LoadingProductos, isFetching: FetchingProductos } = useGetProductosQuery({
        search: search, franquicia: franquicia,
        categoria: Categoria ?? null
    });
    const [CrearCategoria, { isLoading: LoadingCrearCategoria, isSuccess: SuccessCrearCategoria, isFetching: FetchingCrearCategoria }] = useCrearCategoriaMutation();
    const [CrearCategoriaRuta, { isLoading: LoadingCrearCategoriaRuta, isFetching: FetchingCrearCategoriaRuta }] = useCrearCategoriaRutaMutation();
    const [uploadFoto, { isLoading: LoadingUploadFoto, isFetching: FetchingUploadFoto }] = useUploadImageMultimediaMutation();

    // useEffect(() => {
    //     console.log(ModalCategorias)
    // }, [ModalCategorias]);
    const handlerInput = (value, nombre) => {
        handlerMutation(CrearCategoria, { id: ModalCategorias.idCategoria, body: { [nombre]: value, usuario: usuario.id, sucursal: franquicia } }, (r) => {
            if (r?.mensaje?.id) {
                setModalCategorias(old => { return { ...old, idCategoria: r?.mensaje?.id } })
                handlerMutation(CrearCategoriaRuta, { id: ModalCategorias.idRuta, body: { padre: MainCategoria.id, hijo: r?.mensaje?.id } }, (r) => {
                    if (r?.mensaje?.id) {
                        setModalCategorias(old => { return { ...old, idRuta: r?.mensaje?.id } })
                        // setCategoria(r?.mensaje?.id)
                        // UpProps({ SelectCategoria: r?.mensaje?.id })
                    }
                })
            }
        })
    }

    const handlerImage = (file) => {
        handlerMutation(uploadFoto, { id: null, file: file, carpeta: "ft_categorias", nombre: "archivo" }, (r) => {
            if (r?.mensaje?.id) {
                setModalCategorias({
                    ...ModalCategorias,
                    fotoFile: file,
                    //  `https://crud.eksuite.com/api/GetFile/ft_categorias/${ModalCategorias?.fotoPortada?.archivo ?? ""}`
                })
                handlerInput(r.mensaje.id, "portada")
            }
        })
    }

    const handlerBotonesCategoria = () => {
        let botones = [];
        if (Categoria) {
            botones.push({
                onClick: () => {
                    if (!LoadingMainCategoria || !FetchingMainCategoria) {
                        const historial = [...Historial]
                        historial.pop();
                        setHistorial(historial)
                        setCategoria(historial[historial.length - 1])
                    }
                },
                icono: " fa-arrow-left",
                tooltip: "Regresar"
            })
            botones.push({
                onClick: () => {
                    if (Categoria && Categoria !== null) {
                        setModalCategorias({
                            idCategoria: Categoria,
                            idRuta: MainCategoria?.eureka_st_nivele?.id ?? null,
                            nombre: MainCategoria?.nombre ?? "",
                            fotoPrev: `https://crud.eksuite.com/api/GetFile/ft_categorias/${MainCategoria?.fotoPortada?.archivo ?? ""}`,
                            show: true
                        })
                    }
                },
                icono: " fa-pen",
                tooltip: "Editar Categoría"
            })
        }
        botones.push(
            {
                onClick: () => {
                    setModalCategorias({ idCategoria: null, idRuta: null, nombre: null, fotoPrev: null, fotoFile: null, show: true })
                },
                icono: " fa-plus",
                tooltip: "Agregar categoría"
            },
        )
        return botones;
    }
    return <>
        {ModalCategorias.show ? <ModalForm
            isLoading={LoadingCrearCategoria}
            isSuccess={SuccessCrearCategoria}
            show={ModalCategorias.show}
            onClose={() => { setModalCategorias({ idCategoria: null, idRuta: null, nombre: "", show: false }) }}
            titulo={`Agrega una nueva categoría ${Categoria ? `con "` + MainCategoria?.nombre + `" como padre` : ""}`}>
            <ImageInput
                width={250}
                height={250}
                changeFunction={(e) => {
                    handlerImage(e.target.files[0])
                }}
                file={ModalCategorias?.fotoFile ?? null}
                preview={ModalCategorias?.fotoPrev ?? null}
            />
            <Input
                nombre="nombre"
                min={1}
                max={22}
                value={ModalCategorias?.nombre ?? ""}
                changeFunction={handlerInput}
                placeholder="Nombre de la categoría"
            />

        </ModalForm> : undefined}
        <Tabla
            checkbox={false}
            categorias={{
                titulo: MainCategoria?.nombre ?? "Categorías",
                left: () => {
                    if (LoadingMainCategoria || FetchingMainCategoria) {
                        return <div key={0} style={{ margin: 0, width: "max-content" }}><LoaderCircle size="small" /></div>

                    } else {
                        return <Miniatura key={0} size="small" error="/img/noimage.jpg" src={`https://crud.eksuite.com/api/GetFile/ft_categorias/${MainCategoria?.fotoPortada?.archivo ?? ""}`} />
                    }
                },
                categorias: Categorias?.map((item, index) => {
                    return {
                        onClick: () => {
                            if (!LoadingMainCategoria || !FetchingMainCategoria) {
                                setHistorial([...Historial, item.id])
                                setCategoria(item.id)
                            }
                        },
                        left: () => < Miniatura key={index} error="/img/noimage.jpg" size="small" src={`https://crud.eksuite.com/api/GetFile/ft_categorias/${item.fotoPortada?.archivo ?? ""}`} />,
                        text: item?.nombre ?? "",
                    }
                }) ?? [],
                botones: handlerBotonesCategoria()
            }}
            busqueda={{
                placeholder: "Buscar producto...",
                value: search,
                fn: (value) => {
                    setSearch(value === "" ? null : value)
                    UpProps({ busqueda: value === "" ? null : value })
                }
            }}
            filtros={[
                {
                    text: "Todos",
                    icono: "fa-solid fa-filter",
                    filtro: () => true
                },

            ]}
            botonesHead={[
                {
                    icono: "fa-solid fa-plus",
                    tooltip: "Agregar producto",
                    onClick: (item) => {
                        Push("formProductos", {
                            nombre: null,
                            clave: null,
                            costo: null,
                            prevUrl: null,
                            prevFile: null,
                            franquicia: franquicia,
                            categoria: Categoria
                        })
                    }
                }
            ]}
            botonesRow={[
                {
                    icono: "fa-solid fa-file-edit",
                    tooltip: "Editar",
                    onClick: (item) => {
                        item = item.item;
                        Push('formProductos', {
                            producto: item.id,
                            nombre: item.nombre,
                            clave: item.clave,
                            costo: item.costo,
                            prevFile: undefined,
                            prevUrl: `https://crud.eksuite.com/api/GetFile/ft_productos/${item.eureka_cr_multimedia?.archivo ?? ""}/imagen`,
                            franquicia: franquicia,
                            categoria: item.nivel
                        })
                    },
                },
            ]}
            headers={[
                { text: "", key: "portada", type: "text", size: "small" },
                { text: "Nombre", key: "nombre", type: "text" },
                { text: "Costo", key: "costo", type: "text" },
                { text: "Categoría", key: "categoria", type: "text" },
                { text: "", key: "estatus", type: "text" }
            ]}
            data={buildData(Productos ?? [], [{
                key: "portada", size: "small", type: "text", text: (item) => {
                    return <Miniatura
                        error={"/img/noimage.jpg"}
                        src={item?.eureka_cr_multimedia?.archivo ? `${config.crufdekFileURL}/ft_productos/${item?.eureka_cr_multimedia?.archivo}/imagen` : "/img/noimage.jpg"}
                    />
                },

            }, {
                key: "nombre", type: "text"
            }, {
                key: "costo", type: "precio",
            }, {
                key: "categoria", type: "text",
                value: (item) => {
                    return item.eureka_st_nivele?.nombre ?? "Sin categoría"
                },
                text: (item) => {
                    return item.eureka_st_nivele?.nombre ?? "Sin categoría"
                }
            }, {
                key: "estatus", type: "text", text: (item) => {
                    if (item.estatus === "activo") {
                        return <ChipEstatus texto="Activo" tipo="Aceptado" />
                    }
                    if (item.estatus === "pendiente") {
                        return <ChipEstatus texto="Por aceptar" tipo="Pendiente" />
                    }
                    if (item.estatus === "inactivo") {
                        return <ChipEstatus texto="Inactivo" tipo="Error" />
                    }
                }
            }

            ])}
        />
    </>
}