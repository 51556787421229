import { Tabla, buildData, ChipEstatus, ChipContact } from "eureka-design"
import { useGetEventosQuery } from "../../js/service/eventos"
import { useState, useEffect } from "react"

export default function Eventos({ UpProps, Push, getPermiso, busqueda = null, roles, usuario }) {
    const [search, setSearch] = useState(busqueda)
    const { data, isLoading, isFetching } = useGetEventosQuery({ search: search, roles, usuario })
    const [sinEstadiosKey, setSinEstadiosKey] = useState(0);
    const [pendientesKey, setPendientesKey] = useState(0);
    const [canceladosKey, setCanceladosKey] = useState(0);
    const [activosKey, setActivosKey] = useState(0);

    useEffect(() => {
        if (data) {
            const sinEstadios = data.filter((item) => item.eureka_bs_sucursale === null)
            if (sinEstadios.length > 0) {
                setSinEstadiosKey(sinEstadios.length)
            }
            const pendientes = data.filter((item) => item.estatus === "pendiente")
            if (pendientes.length > 0) {
                setPendientesKey(pendientes.length)
            }
            const cancelados = data.filter((item) => item.estatus === "inactivo")
            if (cancelados.length > 0) {
                setCanceladosKey(cancelados.length)
            }
            const activos = data.filter((item) => item.estatus === "activo" && item.eureka_bs_sucursale)
            if (activos.length > 0) {
                setActivosKey(activos.length)
            }
        }
    }, [data, setSinEstadiosKey, setPendientesKey, setCanceladosKey])

    const getTotalVentas = (item) => {
        let total = 0;
        item?.eureka_in_contadores?.forEach((contador) => {
            if (contador.nombre === "tot_ventas") {
                total = contador.contador;
            }
        })
        return total;
    }

    const BotonesRow = () => {
        const botones = [];
        if (getPermiso("cu_eventos")) {
            botones.push([
                {
                    icono: "fa-solid fa-file-edit",
                    tooltip: "Editar",
                    mutacion: (item) => {
                        if (roles.RolGeneral) {
                            return true;
                        } else if (roles.RolLugares || roles.RolEventos) {
                            return item.usuario === usuario.id;
                        }
                    },
                    onClick: (item) => {
                        item = item.item;
                        Push('formEventos', {
                            sucursal: item.eureka_bs_sucursale?.id ?? null,
                            evento: item.id, portada: null, nombre: item.nombre, descripcion: item.descripcion,
                            fechas: item.fecha_inicio && item.fecha_termino ? { date1: item.fecha_inicio, date2: item.fecha_termino } : null,
                            estadioName: item.eureka_bs_sucursale?.nombre ?? null,
                            prevFile: undefined,
                            usuario: { id: item.eureka_ac_usuario?.id ?? null },
                            prevUrl: `https://crud.eksuite.com/api/GetFile/${item.eureka_cr_multimedia_por?.carpeta}/${item.eureka_cr_multimedia_por?.archivo}`
                        })
                    },
                }
            ])
        }
        botones.push({
            icono: "fa-solid fa-user-check",
            tooltip: "Asistencias",
            onClick: (item) => {
                item = item.item;
                Push('asistencias', { evento: item.id })
            },
        })
        return botones;
    }

    return <Tabla
        rowSize="small"
        busqueda={{
            placeholder: "Buscar evento",
            value: search,
            fn: (value) => {
                setSearch(value === "" ? null : value)
                UpProps({ busqueda: value === "" ? null : value })
            }
        }}
        checkbox={false}
        onClickRow={(item) => {
            Push("perfilEvento", { evento: item.item.id })
        }}
        keys={[
            {
                text: "Todos",
                detalle: data && data.length > 0 ? data.length : 0,
                filtro: () => true
            },
            {
                text: "Sin estadios",
                detalle: sinEstadiosKey,
                filtro: (item) => {
                    return !item.item.eureka_bs_sucursale;
                }
            },
            {
                text: "Pendientes",
                detalle: pendientesKey,
                filtro: item => {
                    return item.item.estatus === "pendiente";
                }
            },
            {
                text: "Cancelados",
                detalle: canceladosKey,
                filtro: item => {
                    return item.item.estatus === "inactivo";
                }
            },
            {
                text: "Activos",
                detalle: activosKey,
                filtro: item => {
                    return item.item.estatus === "activo" && item.item.eureka_bs_sucursale;
                }
            },
        ]}
        botonesHead={getPermiso("cu_eventos") ? [
            {
                icono: "fa-solid fa-plus",
                tooltip: "Agregar evento",
                onClick: (item) => {
                    Push("formEventos", { sucursal: null, evento: null, portada: null, nombre: null, descripcion: null, fechas: null, prevFile: undefined, estadioName: null, prevUrl: null })
                }
            }
        ] : []}
        headers={[
            { text: "Titulo", key: "nombre", type: "text" },
            { text: "Tot. Ventas", key: "ventas", type: "precio" },
            // { text: "Fecha de inicio", key: "fecha_inicio", type: "date" },
            // { text: "Fecha de fin", key: "fecha_termino", type: "date" },
            { text: "Organizador", key: "usuarios", type: "text" },
            { text: "Estadio", key: "sucursales", type: "text" },
            { text: "", key: "estatus", type: "text" }
        ]}
        botonesRow={BotonesRow()}
        data={buildData(data ?? [], [
            { key: "nombre", type: "text" },
            {
                key: "ventas", type: "precio", value: (item) => {
                    return getTotalVentas(item)
                }, text: (item) => {
                    return getTotalVentas(item)
                }
            },
            // { key: "fecha_inicio", type: "date", formato: "{DD} de {MM} {YYYY} {HH}:{Mm}" },
            // { key: "fecha_termino", type: "date", formato: "{DD} de {MM} {YYYY} {HH}:{Mm}" },
            {
                key: "usuarios", type: "text", value: (item) => item?.eureka_ac_usuario?.nombre ?? "",
                text: (item) => {
                    if (item?.eureka_ac_usuario) {
                        return <ChipContact texto={item?.eureka_ac_usuario?.nombre}
                            onClick={() => {
                                Push("usuario", { perfilUser: item?.eureka_ac_usuario?.id }, "Usuarios")

                            }}
                            error={"/img/nouser.jpg"}
                            imagen={`https://crud.eksuite.com/api/GetFile/ft_usuario/${item.eureka_ac_usuario?.foto}/imagen`} />
                    }
                }
            },
            {
                key: "sucursales", type: "text", text: (item) => {
                    if (item?.eureka_bs_sucursale) {
                        return <ChipContact texto={item?.eureka_bs_sucursale?.nombre}
                            onClick={() => {
                                Push("lugar", { perfilEstadio: item?.eureka_bs_sucursale?.id }, "Lugares")
                            }}
                            error={"/img/noimage.jpg"}
                            imagen={`https://crud.eksuite.com/api/GetFile/ft_estadios/${item.eureka_bs_sucursale?.portada}/imagen`} />
                    }
                },
                value: (item) => item?.eureka_bs_sucursale?.nombre ?? ""
            },
            {
                key: "estatus", type: "text",
                value: (item) => {
                    let estatus = item.estatus;

                    if (!item.eureka_bs_sucursale) {
                        estatus = "Sin estadio"
                    }
                    return estatus;
                },
                text: (item) => {
                    let chip;
                    if (item.estatus === "activo") {
                        chip = <ChipEstatus texto={"Aceptado"} tipo="Aceptado" />
                    }

                    if (item.estatus === "inactivo") {
                        chip = <ChipEstatus texto={"Cancelado"} tipo="Error" />
                    }

                    if (item.estatus === "pendiente") {
                        chip = <ChipEstatus texto={"Pendiente"} tipo="Pendiente" />
                    }

                    if (!item.eureka_bs_sucursale) {
                        chip = <ChipEstatus texto={"Sin estadio"} tipo="Pendiente" />
                    }
                    return chip;
                }
            }

        ])}
    >

    </Tabla >
}
