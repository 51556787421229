import { Tabla, buildData, ChipEstatus, Miniatura, ChipContact } from "eureka-design"
import { useGetEstadiosQuery } from "../../js/service/lugares"
import { config } from "../../config/config";
import { useState, useEffect } from "react"

export default function Lugares({ UpProps, Push, getPermiso, ChangeScene, busqueda, usuario, roles }) {
    const [search, setSearch] = useState(busqueda)
    const { data, isLoading, isFetching } = useGetEstadiosQuery({ search: busqueda, usuario, roles });
    const [pendientesKey, setPendientesKey] = useState(0);
    const [canceladosKey, setCanceladosKey] = useState(0);
    const [activosKey, setActivosKey] = useState(0);

    useEffect(() => {
        if (data) {
            const pendientes = data.filter((item) => item.estatus === "nuevo")
            if (pendientes.length > 0) {
                setPendientesKey(pendientes.length)
            }
            const cancelados = data.filter((item) => item.estatus === "inactivo")
            if (cancelados.length > 0) {
                setCanceladosKey(cancelados.length)
            }
            const activos = data.filter((item) => item.estatus === "activo")
            if (activos.length > 0) {
                setActivosKey(activos.length)
            }
        }
    }, [data, setPendientesKey, setCanceladosKey])

    const BotonesRow = () => {
        const botones = [];
        if (roles.RolGeneral || roles.RolLugares) {
            botones.push([
                {
                    icono: "fa-solid fa-file-edit",
                    tooltip: "Editar",
                    mutacion: (item) => {
                        if (roles.RolGeneral) {
                            return true;
                        } else if (roles.RolLugares) {
                            return item.usuario === usuario.id;
                        }
                    },
                    onClick: (item) => {
                        item = item.item;
                        Push('formLugares', {
                            lugar: item.id, portada: null, nombre: item.nombre,
                            domicilio: item.eureka_in_domicilio?.id ?? null,
                            calle: item.eureka_in_domicilio?.calle ?? null,
                            interior: item.eureka_in_domicilio?.interior ?? null,
                            entrecalles: item.eureka_in_domicilio?.entrecalles ?? null,
                            referencias: item.eureka_in_domicilio?.referencias ?? null,
                            usuario: { id: item.eureka_ac_usuario?.id ?? null },
                            prevFile: undefined,
                            prevUrl: `https://crud.eksuite.com/api/GetFile/ft_estadios/${item.portada}`
                        })
                    },
                }
            ])
        }
        botones.push({
            icono: "fa-solid fa-map-marker-alt",
            tooltip: "Mapa",
            onClick: (item) => {
                item = item.item;
                Push('mapas', { lugar: item.id })
            },
        });
        botones.push({
            icono: "fa-users",
            tooltip: "Acceso",
            onClick: (item) => {
                item = item.item;
                Push('accesoUsuarios', { lugar: item.id })
            },
        });
        return botones;
    }

    const BotonesHead = () => {
        const botones = [];
        if (getPermiso("cu_lugares")) {
            botones.push(
                {
                    icono: "fa-plus",
                    tooltip: "Agregar lugar",
                    onClick: (item) => {
                        Push('formLugares', { lugar: null, portada: null, nombre: null, domicilio: null, calle: null, interior: null, entrecalles: null, referencias: null, usuario: { id: usuario.id }, prevFile: undefined, prevUrl: null })
                    },
                }
            )
        }
        return botones;
    }

    return <Tabla
        rowSize="small"
        busqueda={{
            placeholder: "Buscar lugar...",
            value: search,
            fn: (value) => {
                setSearch(value === "" ? null : value)
                UpProps({ busqueda: value === "" ? null : value })
            }
        }}
        onClickRow={(item) => {
            Push("lugar", { perfilEstadio: item.item.id })
        }}
        checkbox={false}
        keys={[
            {
                text: "Todos",
                detalle: data && data.length > 0 ? data.length : 0,
                filtro: () => true
            },
            {
                text: "Pendientes",
                detalle: pendientesKey,
                filtro: (item) => {
                    return item.item.estatus === "nuevo";
                }
            },
            {
                text: "Aceptados",
                detalle: activosKey,
                filtro: (item) => item.item.estatus === "activo"
            },
            {
                text: "Cancelados",
                detalle: canceladosKey,
                filtro: (item) => item.item.estatus === "inactivo"
            }
        ]}
        botonesHead={BotonesHead()}
        botonesRow={BotonesRow()}
        headers={[
            // { text: "", key: "portada", type: "text", size: "small" },
            { text: "Nombre", key: "nombre", type: "text" },
            { text: "Domicilio", key: "dom", type: "text" },
            { text: "Tot. eventos", key: "eventos", type: "text" },
            { text: "Propietario", key: "usuario", type: "text" },
            { text: "", key: "estatus", type: "text" }
        ]}

        data={buildData(data ?? [], [
            // {
            //     key: "portada", size: "small", type: "text", text: (item) => {
            //         return <Miniatura
            //             avatar={true}
            //             error={"/img/noimage.jpg"}
            //             src={item?.portada ? `${config.crufdekFileURL}/ft_estadios/${item?.portada}/imagen` : "/img/noimage.jpg"}
            //         />
            //     },
            // },
            {
                key: "nombre", type: "text", text: (item) => {
                    if (!item.nombre || item.nombre.length === 0) {
                        return "---"
                    } else {
                        return item.nombre
                    }
                },
                value: (item) => {
                    return item.nombre;
                },
            },
            {
                key: "dom", type: "text",
                value: (item) => {
                    let dom = "";
                    if (item.eureka_in_domicilio) {
                        dom += item.eureka_in_domicilio.calle;
                        if (item.eureka_in_domicilio.interior != null) {
                            dom += ", #" + item.eureka_in_domicilio.interior;
                        }
                    } else dom = "---";
                    return dom;
                }, text: (item) => {
                    let dom = "";
                    if (item.eureka_in_domicilio) {
                        dom += item.eureka_in_domicilio.calle;
                        if (item.eureka_in_domicilio.interior != null) {
                            dom += ", #" + item.eureka_in_domicilio.interior;
                        }
                    } else dom = "---";
                    return dom;
                }
            },
            {
                key: "eventos", type: "text", text: (item) => {
                    return item.eureka_bs_eventos.length + " - eventos";
                },
                value: (item) => {
                    return item.eureka_bs_eventos.length;
                },
            },
            {
                key: "usuario", type: "text", text: (item) => {
                    if (item.eureka_ac_usuario) {
                        return <ChipContact
                            texto={item.eureka_ac_usuario.nombre}
                            onClick={() => {
                                Push("usuario", { perfilUser: item.eureka_ac_usuario.id }, "Usuarios")
                            }}
                            error={"/img/nouser.jpg"}
                            imagen={`https://crud.eksuite.com/api/GetFile/ft_usuario/${item.eureka_ac_usuario.foto}`}
                        />
                    }
                },
                value: (item) => {
                    return item.eureka_ac_usuario.nombre;
                },
            },
            {
                key: "estatus", type: "text",
                value: (item) => {
                    let estatus = item.estatus;
                    return estatus;
                },
                text: (item) => {
                    let chip;
                    if (item.estatus === "activo") {
                        chip = <ChipEstatus texto={"Aceptado"} tipo="Aceptado" />
                    }

                    if (item.estatus === "inactivo") {
                        chip = <ChipEstatus texto={"Cancelado"} tipo="Error" />
                    }

                    if (item.estatus === "nuevo") {
                        chip = <ChipEstatus texto={"Pendiente"} tipo="Pendiente" />
                    }


                    return chip;
                }
            }
        ])}
    >

    </Tabla >
}
