import { Formulario, Input, ImageInput, Chip, LoaderCircle } from "eureka-design"
import { useCrearUsuarioMutation, useUploadImageUsuariosMutation, useGetRolesQuery, useAgregarRolMutation, useRemoverRolMutation } from "../../js/service/usuarios"
import { useEffect, useState } from 'react';
import { handlerMutation } from "../../js/util.js"


export default function FormUsuarios({ UpProps, userid, foto, prevUrl, prevFile, nombre, correo, telefono }) {
    const [idUsuario, setIdUsuario] = useState(userid);
    // const [idImage, setIdImage] = useState(foto);

    const [crearUsuario, { isLoading: inputIsloading, isError: inputIsError, isSuccess: inputIsSuccess }] = useCrearUsuarioMutation();
    const [uploadFoto, { isLoading: imageisLoading, isError: imageIsError, isSuccess: imageIsSuccess }] = useUploadImageUsuariosMutation();
    const [agregarRol, { isLoading: rolLoading, isError: rolError, isSuccess: rolSusccess }] = useAgregarRolMutation();
    const [removerRol, { isLoading: rolRemLoading, isError: rolRemError, isSuccess: rolRemSusccess }] = useRemoverRolMutation();
    const { data: Roles, isLoading: getRolesLoading } = useGetRolesQuery({ usuario: idUsuario });

    const handlerInput = (value, nombre, rol) => {
        let fecha1 = new Date()
        fecha1 = `${fecha1.getFullYear()}-${fecha1.getMonth() + 1}-${fecha1.getDate()} ${fecha1.getHours()}:${fecha1.getMinutes()}:00`
        handlerMutation(crearUsuario, { id: idUsuario, body: { [nombre]: value, fecha_registro: fecha1 } }, (r) => {
            if (r?.mensaje?.id) {
                setIdUsuario(r.mensaje.id)
                UpProps({ evento: r.mensaje.id })
                if (rol) {
                    agregarRol({ rol: rol, usuario: r.mensaje.id })
                }
            }
        })
        UpProps({ [nombre]: value })
    }

    const handlerPhoto = (e) => {
        handlerMutation(uploadFoto, { id: idUsuario, file: e.target.files[0], carpeta: "ft_usuario", nombre: "foto" }, (r) => {
            if (r?.mensaje?.id) {
                setIdUsuario(r.mensaje.id)
                UpProps({ userid: r.mensaje.id })
            }
        })
        UpProps({ prevFile: e.target.files[0] })
    }

    return (
        <Formulario titulo="Agrega un nuevo usuario"
            isLoading={[inputIsloading, imageisLoading, rolLoading, rolRemLoading]}
            isError={[inputIsError, imageIsError, rolError, rolRemError]}
            isSuccess={[inputIsSuccess, imageIsSuccess, rolSusccess, rolRemSusccess]}>
            <ImageInput
                width={250}
                height={250}
                changeFunction={handlerPhoto}
                preview={prevUrl}
                file={prevFile}
            >
            </ImageInput>
            <Input
                min={5}
                changeFunction={handlerInput}
                isLoading={inputIsloading}
                placeholder={"Nombre"}
                nombre={"nombre"}
                type={"text"}
                value={nombre}
                iconLeft={"fa-user"}
            />
            <Input
                changeFunction={handlerInput}
                isLoading={inputIsloading}
                placeholder={"Correo"}
                nombre={"correo"}
                type={"text"}
                value={correo}
                iconLeft={"fa-envelope"}
            />
            <Input
                min={10}
                max={10}
                changeFunction={handlerInput}
                isLoading={inputIsloading}
                placeholder={"Telefono"}
                nombre={"telefono"}
                type={"telefono"}
                value={telefono}
                iconLeft={"fa-phone-alt"}
            />

            <h4>Roles de usuario</h4>
            <div style={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
                flexWrap: "wrap"
            }}>
                {!Roles && getRolesLoading ? <LoaderCircle /> :
                    Roles?.map((item, index) => {
                        let select = false;
                        let onClick;

                        if (item.eureka_cr_rol_usuarios.length > 0) {
                            select = true;
                            onClick = (upSelect) => {
                                upSelect(false)
                                if (!rolRemLoading && !rolLoading && !getRolesLoading) removerRol({ rol: item.id, usuario: idUsuario })
                            }
                        } else {
                            onClick = (upSelect) => {
                                upSelect(true)
                                if (idUsuario !== null && !rolLoading && !rolRemLoading && !getRolesLoading) {
                                    agregarRol({ rol: item.id, usuario: idUsuario })
                                } else if (!rolLoading && !rolRemLoading && !getRolesLoading) {
                                    handlerInput("nombre", "", item.id)
                                }
                            }
                        }
                        return <Chip key={index} selected={select} texto={item.nombre} onClick={onClick} icono={"fa-user-tag"}></Chip>
                    })
                }
            </div>
        </Formulario>
    )
}
