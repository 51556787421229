import { Formulario, Input, ImageInput, Miniatura, InputSearch } from "eureka-design"
import { useCrearGrupoEventosMutation, useUploadImageEventosMutation } from "../../js/service/eventos.js"
import { useSearchEstadiosQuery } from "../../js/service/franquicias.js";
import { useEffect, useState } from 'react';
import { handlerMutation } from "../../js/util.js"


export default function FormEventosGrupos({ Push, UpProps, ShowAlert, grupo, portada, prevUrl, prevFile, sucursal, nombre, precio, descripcion, estadioName, usuario, roles }) {
    const [idGrupo, setIdGrupo] = useState(grupo);
    const [idImage, setIdImage] = useState(portada);
    const [estadio, setEstadio] = useState(sucursal);
    const [searchEstadio, setSearchEstadio] = useState(null)
    const [valueEstadio, setValueEstadio] = useState(estadioName);

    const [CrearGrupo, { isLoading: inputIsloading, isError: inputIsError, isSuccess: inputIsSuccess }] = useCrearGrupoEventosMutation();
    const [uploadFoto, { isLoading: imageisLoading, isError: imageIsError, isSuccess: imageIsSuccess }] = useUploadImageEventosMutation();
    const { data: Estadios, isLoadingEstadios } = useSearchEstadiosQuery({ search: searchEstadio, is_acceso: usuario.id })

    const handlerInput = (value, nombre) => {
        handlerMutation(CrearGrupo, { id: idGrupo, body: { [nombre]: value, usuario: usuario.id } }, (r) => {
            if (r?.mensaje?.id) {
                setIdGrupo(r.mensaje.id)
                UpProps({ grupo: r.mensaje.id })
            }
        })
        UpProps({ [nombre]: value })
    }

    const handlerPhoto = (e) => {
        handlerMutation(uploadFoto, { id: idImage, file: e.target.files[0], carpeta: "ft_bonos", nombre: "archivo" }, (r) => {
            if (r?.mensaje?.id) {
                setIdImage(r.mensaje.id)
                UpProps({ portada: r.mensaje.id })
                handlerInput(r.mensaje.id, "portada")
            }
        })
        UpProps({ prevFile: e.target.files[0] })
    }


    const handlerEstadios = () => {
        let estadios = Estadios?.map((item, index) => {
            return {
                value: item,
                text: item.nombre,
                icono: () => {
                    return <Miniatura
                        key={index}
                        avatar={true}
                        error={"/img/noimage.jpg"}
                        src={`https://crud.eksuite.com/api/GetFile/ft_bonos/${item.portada}`}
                    />
                }
            }
        });
        return estadios;
    }

    const getBotonesForm = () => {
        let botones = [];
        botones.push({
            iconLeft: "fa-calendar-day",
            texto: "Eventos del grupo",
            onClick: () => {
                if (estadio && estadio > 0) {
                    Push("agrupacionesEventos", { grupo: idGrupo })
                } else {
                    ShowAlert({
                        titulo: "Espera...",
                        icono: "warning",
                        mensaje: "Debes seleccionar un estadio o lugar para continuar",
                        time: 5
                    })
                }


            }
        })
        return botones;
    }

    return (
        <Formulario titulo="Agrega un nuevo grupo de eventos"
            isLoading={[inputIsloading, imageisLoading]}
            isError={[inputIsError, imageIsError]}
            isSuccess={[inputIsSuccess, imageIsSuccess]}
            botones={getBotonesForm()}>
            <ImageInput
                width={"100%"}
                height={"100%"}
                radio={[21, 9]}
                recomendado={[1000, 350]}
                changeFunction={handlerPhoto}
                preview={prevUrl}
                file={prevFile}
            >
            </ImageInput>
            <Input
                min={5}
                changeFunction={handlerInput}
                isLoading={inputIsloading}
                placeholder={"Nombre"}
                nombre={"nombre"}
                type={"text"}
                value={nombre}
                iconLeft={"fa-file-contract"}
            />
            <Input
                max={95000}
                changeFunction={handlerInput}
                isLoading={inputIsloading}
                placeholder={"Precio"}
                nombre={"precio"}
                type={"precio"}
                value={precio}
                iconLeft={"fa-dollar-sign"}
            />

            <Input
                placeholder={"Descripción"}
                changeFunction={handlerInput}
                isLoading={inputIsloading}
                nombre={"descripcion"}
                value={descripcion}
                type={"textarea"}
                iconLeft={"fa-file-contract"}
            >
            </Input>
            <InputSearch
                value={valueEstadio}
                changeFunction={(value) => {
                    setSearchEstadio(value)
                }}
                OpcionSelect={(item) => {
                    setValueEstadio(item.nombre)
                    setEstadio(item.id)
                    handlerInput(item.id, "lugar")
                }}
                isLoading={isLoadingEstadios}
                opciones={Estadios ? handlerEstadios() : []}
                iconLeft={"fa-solid fa-map-marker"}
                placeholder={"Estadio o lugar"}
            />

        </Formulario>
    )
}
