
export function handler_rol(first_roles: any[] = []): [(permiso: String) => boolean, () => boolean] {
    let final_rol: any[] = [];
    let is_admin = true;

    const handler_build_roles = (roles: any[]) => {
        let build_rol: any[] = [];
        roles.forEach((rol: any) => {
            let rol_obj: any = {
                rol: rol.eureka_cr_rol?.nombre ?? "",
                permisos: get_cr_roles(rol.eureka_cr_rol?.eureka_cr_roles ?? [])
            };
            build_rol.push(rol_obj);
        });
        final_rol = build_rol;
    }

    function get_cr_roles(roles: any[]): object {
        let cr_roles = {}
        roles.forEach((rol: any) => {
            cr_roles[rol.pantalla] = []
        })
        roles.forEach((rol: any) => {
            cr_roles[rol.pantalla].push({
                nombre: rol.nombre,
                estatus: rol.autorizar == "autorizado" ? true : false,
                permiso: rol.crud
            })
            if (rol.autorizar !== "autorizado") {
                is_admin = false
            }
        })
        return cr_roles
    }

    // function getPermiso(permiso: String): boolean {
    //     let result = false;
    //     final_rol.map((rol: any) => {
    //         Object.entries(rol.permisos).map(([key, value]: any[]) => {
    //             return value.map((perm: any) => {
    //                 if (perm.permiso === permiso) {
    //                     if (permiso == "r_usuarios") console.log("Estatus", perm.estatus)
    //                     result = perm.estatus
    //                     return true
    //                 }
    //             })
    //         })
    //     })
    //     return result;
    // }

    function getPermiso(permiso: String): boolean {
        let result = false;
        final_rol.forEach((rol: any) => {
            if (result) return; // Detener si result es verdadero
            Object.entries(rol.permisos).forEach(([key, value]: any[]) => {
                if (result) return; // Detener si result es verdadero
                value.forEach((perm: any) => {
                    if (perm.permiso === permiso) {
                        if (permiso == "r_usuarios") console.log("Estatus", perm.estatus);
                        result = perm.estatus;
                        return; // Salir del forEach interno
                    }
                });
            });
        });
        return result;
    }

    function BuildRoles(roles: any[]) {
        handler_build_roles(roles);
    }

    function isAdmin(): boolean {
        return is_admin;
    }

    BuildRoles(first_roles);

    return [getPermiso, isAdmin];
}