import { Formulario, Input, Select, Chip, LoaderCircle } from "eureka-design"
import { useCrearDispositivoMutation, useGetZonasQuery } from "../../js/service/lugares.js";
import { useEffect, useState } from 'react';
import { handlerMutation } from "../../js/util.js"


export default function FormPuertas({ UpProps, lugar, puerta, nombre, area = "", areas, usuario }) {
    const [idPuerta, setIdPuerta] = useState(puerta);
    const [CrearPuerta, { isLoading: inputIsloading, isError: inputIsError, isSuccess: inputIsSuccess }] = useCrearDispositivoMutation();
    const { data } = useGetZonasQuery({ lugar: lugar, nivel: "seccion" })
    const [zonas, setZonas] = useState([])
    const [Secciones, setSecciones] = useState(areas ?? [])

    const handlerInput = (value, nombre) => {
        handlerMutation(CrearPuerta, { id: idPuerta, body: { [nombre]: value, sucursal: lugar } }, (r) => {
            if (r?.mensaje?.id) {
                setIdPuerta(r.mensaje.id)
                UpProps({ puerta: r.mensaje.id })
            }
        })
        UpProps({ [nombre]: value })
    }

    useEffect(() => {
        if (data) {
            let nuevas_zonas = [];
            data.forEach(item => {
                nuevas_zonas.push({ value: item.id, text: item.nombre })
            });
            setZonas(nuevas_zonas)
        }
    }, [data]);

    useEffect(() => {
        if (areas) {
            setSecciones(areas)
        }
    }, [Secciones]);

    const handlerSecciones = (item) => {
        let newSecciones = [...Secciones]
        let seleccion = false;
        Secciones.forEach((element, index) => {

            if (element.id === item.id) {
                seleccion = true
                newSecciones.splice(index, 1)
            }
        })
        if (!seleccion) {
            newSecciones.push(item)
        }
        let areasRequest = newSecciones.map(item => {
            return {
                id: item.id,
                nombre: item.nombre
            }
        })
        handlerInput(areasRequest, "areas")
        setSecciones(newSecciones)
    }

    return (
        <Formulario titulo="Agrega una puerta"
            isLoading={[inputIsloading]}
            isError={[inputIsError]}
            isSuccess={[inputIsSuccess]}>

            <Input
                min={5}
                max={255}
                changeFunction={handlerInput}
                isLoading={inputIsloading}
                placeholder={"Nombre"}
                nombre={"nombre"}
                type={"text"}
                value={nombre}
                iconLeft={"fa-file-contract"}
            />
            {/* <Select
                isLoading={inputIsloading}
                value={area}
                nombre="area"
                placeholder="Zona de la puerta"
                iconLeft=" fa-solid fa-map-marker"
                changeFunction={(value, nombre) => {
                    // handlerInput(value.value, nombre)
                    setSecciones(old => {
                        let newSecciones = [...old]
                        newSecciones.push({
                            id: value.value,
                            nombre: value.text
                        })
                        return newSecciones
                    })
                }}
                options={zonas}
            /> */}

            <h4>Secciones a las que pertenece</h4>
            <div style={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
                flexWrap: "wrap"
            }}>
                {
                    data?.map((item, index) => {
                        let select = false;
                        Secciones.forEach(element => {
                            if (element.id === item.id) {
                                select = true;
                            }
                        });
                        return <Chip key={index} selected={select} texto={item.nombre} onClick={e => {
                            handlerSecciones(item)
                        }} icono={" fa-map-marker"}></Chip>
                    })
                }
            </div>
        </Formulario>
    )
}
