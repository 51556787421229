import { PerfilCard, PerfilDetalleSection, PerfilDetalleInfo, ChipEstatus, ChipContact } from "eureka-design"
import { useGetEstadioQuery } from "../../js/service/lugares"
import { useEffect, useState } from 'react';

export default function Lugar({ perfilEstadio, Push }) {
    const { data: perfil = {}, isLoading, isFetching } = useGetEstadioQuery({ estadio: perfilEstadio })
    const [Portada, setPortada] = useState()

    useEffect(() => {
        if (perfil) {
            if (perfil?.portada) {
                setPortada(`https://crud.eksuite.com/api/GetFile/ft_estadios/${perfil?.portada}`)
            }
        }
    }, [perfil]);

    const Estatus = () => {
        let chip;
        if (perfil?.estatus === "activo") {
            chip = <ChipEstatus texto={"Aceptado"} tipo="Aceptado" />
        }

        if (perfil?.estatus === "inactivo") {
            chip = <ChipEstatus texto={"Cancelado"} tipo="Error" />
        }

        if (perfil?.estatus === "pendiente") {
            chip = <ChipEstatus texto={"Pendiente"} tipo="Pendiente" />
        }
        return chip;
    }

    return <PerfilCard
        titulo={perfil?.nombre}
        errorImage="/img/noimage.jpg"
        portada={Portada}
        aspectRadio={"21/9"}
    >
        <PerfilDetalleInfo titulo="Domicilio del lugar"
            boton={
                {
                    texto: "Editar",
                    icono: "fa-solid fa-file-edit",
                    fn: () => {
                        Push('formLugares', {
                            lugar: perfil.id, portada: null, nombre: perfil.nombre,
                            domicilio: perfil.eureka_in_domicilio?.id ?? null,
                            calle: perfil.eureka_in_domicilio?.calle ?? null,
                            interior: perfil.eureka_in_domicilio?.interior ?? null,
                            entrecalles: perfil.eureka_in_domicilio?.entrecalles ?? null,
                            referencias: perfil.eureka_in_domicilio?.referencias ?? null,
                            usuario: { id: perfil.eureka_ac_usuario?.id ?? null },
                            prevFile: undefined,
                            prevUrl: `https://crud.eksuite.com/api/GetFile/ft_estadios/${perfil.portada}`
                        }, "Lugares")
                    }
                }
            }>
            <PerfilDetalleSection titulo={"Calle"}>
                <span>{perfil?.eureka_in_domicilio?.calle ?? "Sin Calle"}</span>
            </PerfilDetalleSection>
            <PerfilDetalleSection titulo={"Numero exterior"}>
                <span>{perfil?.eureka_in_domicilio?.interior ?? "Sin numero int."}</span>
            </PerfilDetalleSection>
            <PerfilDetalleSection titulo={"Referencias"}>
                <span>{perfil?.eureka_in_domicilio?.referencias ?? "Sin referencias"}</span>
            </PerfilDetalleSection>
            <PerfilDetalleSection titulo={"Entre calles"}>
                <span>{perfil?.eureka_in_domicilio?.entrecalles ?? "Sin entre calles"}</span>
            </PerfilDetalleSection>


        </PerfilDetalleInfo>
        <PerfilDetalleInfo titulo="Información del lugar"
            boton={
                {
                    texto: "Editar",
                    icono: "fa-solid fa-file-edit",
                    fn: () => {
                        Push('formLugares', {
                            lugar: perfil.id, portada: null, nombre: perfil.nombre,
                            domicilio: perfil.eureka_in_domicilio?.id ?? null,
                            calle: perfil.eureka_in_domicilio?.calle ?? null,
                            interior: perfil.eureka_in_domicilio?.interior ?? null,
                            entrecalles: perfil.eureka_in_domicilio?.entrecalles ?? null,
                            referencias: perfil.eureka_in_domicilio?.referencias ?? null,
                            usuario: { id: perfil.eureka_ac_usuario?.id ?? null },
                            prevFile: undefined,
                            prevUrl: `https://crud.eksuite.com/api/GetFile/ft_estadios/${perfil.portada}`
                        })
                    }
                }
            }>

            <PerfilDetalleSection titulo={"Dueño del lugar"}>
                <ChipContact texto={perfil?.eureka_ac_usuario?.nombre}
                    onClick={() => {
                        Push("usuario", { perfilUser: perfil?.eureka_ac_usuario?.id }, "Usuarios")

                    }}
                    error={"/img/nouser.jpg"}
                    imagen={`https://crud.eksuite.com/api/GetFile/ft_usuario/${perfil?.eureka_ac_usuario?.foto}/imagen`} />
            </PerfilDetalleSection>
            <PerfilDetalleSection titulo={"Estatus actual del evento"}>
                {
                    Estatus()
                }
            </PerfilDetalleSection>
        </PerfilDetalleInfo>
    </PerfilCard>


    // return !isLoading ? <Perfil
    //     titulo={""}
    //     foto={`${perfil.portada !== null ? `https://crud.eksuite.com/api/GetFile/ft_estadios/${perfil.portada}` : "/img/noimage.jpg"}`}>
    //     <PerfilInfo titulo="Domicilio del estadio">
    //         <PerfilSection titulo="Calle" text={perfil?.eureka_in_domicilio?.calle ?? ""}></PerfilSection>
    //         <PerfilSection titulo="Num int." text={`#${perfil?.eureka_in_domicilio?.interior ?? ""}`}></PerfilSection>
    //         <PerfilSection titulo="Referencias" text={perfil?.eureka_in_domicilio?.referencias ?? ""}></PerfilSection>
    //         <PerfilSection titulo="Entre calles" text={perfil?.eureka_in_domicilio?.entrecalles ?? ""}></PerfilSection>
    //     </PerfilInfo>
    // </Perfil> : <div style={{
    //     width: "100%",
    //     height: "50vh",
    //     display: "grid",
    //     placeperfils: "center"
    // }}>
    //     <LoaderCircle size="large"></LoaderCircle>
    // </div>
}