import { PerfilCard, PerfilDetalleSection, PerfilDetalleInfo, ChipEstatus, ChipContact } from "eureka-design"
import { useGetEventoQuery } from "../../js/service/eventos"
import { useState, useEffect } from "react"


export default function FormEventos({ Push, evento }) {
    const { data, isLoading, isFetching } = useGetEventoQuery({ evento })
    const [Portada, setPortada] = useState()

    useEffect(() => {
        if (data) {
            if (data?.eureka_cr_multimedia_por?.archivo) {
                setPortada(`https://crud.eksuite.com/api/GetFile/ft_eventos/${data?.eureka_cr_multimedia_por?.archivo}`)
            }
        }
    }, [data]);

    const EstatusEvento = () => {

        let chip;
        if (data?.estatus === "activo") {
            chip = <ChipEstatus texto={"Aceptado"} tipo="Aceptado" />
        }

        if (data?.estatus === "inactivo") {
            chip = <ChipEstatus texto={"Cancelado"} tipo="Error" />
        }

        if (data?.estatus === "pendiente") {
            chip = <ChipEstatus texto={"Pendiente"} tipo="Pendiente" />
        }

        if (!data?.eureka_bs_sucursale) {
            chip = <ChipEstatus texto={"Sin estadio"} tipo="Pendiente" />
        }
        return chip;

    }

    return (<PerfilCard
        titulo={data?.nombre}
        errorImage="/img/noimage.jpg"
        portada={Portada}
        aspectRadio={"21/9"}
    >
        <PerfilDetalleInfo titulo="Información del evento"
            boton={
                {
                    texto: "Editar",
                    icono: "fa-solid fa-file-edit",
                    fn: () => {
                        Push('formEventos', {
                            sucursal: data?.eureka_bs_sucursale?.id ?? null,
                            evento: data?.id, portada: null, nombre: data?.nombre, descripcion: data?.descripcion,
                            fechas: data?.fecha_inicio && data?.fecha_termino ? { date1: data?.fecha_inicio, date2: data?.fecha_termino } : null,
                            prevFile: undefined,
                            estadioName: data?.eureka_bs_sucursale?.nombre ?? null,
                            usuario: { id: data?.eureka_ac_usuario?.id ?? null },
                            prevUrl: `https://crud.eksuite.com/api/GetFile/${data?.eureka_cr_multimedia_por?.carpeta}/${data?.eureka_cr_multimedia_por?.archivo}`
                        })
                    }
                }
            }>
            <PerfilDetalleSection titulo={"Fecha de inicio"}>
                <span>{data?.inicio_diff ?? "Sin fecha fijada"}</span>
            </PerfilDetalleSection>
            <PerfilDetalleSection titulo={"Fecha de termino"}>
                <span>{data?.termino_diff ?? "Sin fecha fijada"}</span>
            </PerfilDetalleSection>
            <PerfilDetalleSection titulo={"Descripción"}>
                <span>{data?.descripcion ?? "Sin descripción"}</span>
            </PerfilDetalleSection>
            <PerfilDetalleSection titulo={"Estatus actual del evento"}>
                {
                    EstatusEvento()
                }
            </PerfilDetalleSection>
            <PerfilDetalleSection titulo={"Creador del evento"}>
                <ChipContact texto={data?.eureka_ac_usuario?.nombre}
                    onClick={() => {
                        Push("usuario", { perfilUser: data?.eureka_ac_usuario?.id }, "Usuarios")

                    }}
                    error={"/img/nouser.jpg"}
                    imagen={`https://crud.eksuite.com/api/GetFile/ft_usuario/${data?.eureka_ac_usuario?.foto}/imagen`} />
            </PerfilDetalleSection>
            <PerfilDetalleSection titulo={"Lugar del evento"}>
                <ChipContact texto={data?.eureka_bs_sucursale?.nombre}
                    onClick={() => {
                        Push("lugar", { perfilEstadio: data?.eureka_bs_sucursale?.id }, "Lugares")
                    }}
                    error={"/img/noimage.jpg"}
                    imagen={`https://crud.eksuite.com/api/GetFile/ft_estadios/${data?.eureka_bs_sucursale?.portada}/imagen`} />
            </PerfilDetalleSection>

        </PerfilDetalleInfo>
    </PerfilCard>

    )
}
