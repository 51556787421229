import { createSlice } from "@reduxjs/toolkit";


export const HandlerQuerySlice = createSlice({
    name: "handlerQuery",
    initialState: {
        productos: {
            page: 1,
            limit: 40,
            search: "",
            orden: "",
            franquicia: null,
        },
        formProductos: {
            idProducto: null,
            idImage: null,
            preview: null,
            prevUrl: null,
            nombre: null,
            clave: null,
            costo: null,
            categoria: null,
            franquicia: null,
        },
        formCategorias: {
            idCategorias: null,
            preview: null,
            prevUrl: null,
            nombre: null,
            franquicia: null,
        },
        eventos: {
            page: 1,
            limit: 40,
            search: "",
            orden: "",
            user: false
        },
        asistenciasEventos: {
            page: 1,
            limit: 40,
            search: "",
            orden: "",
            user: false,
            evento: null,
            boletos: 1,
            usuario: {},
        },
        asignacionesSucursal: {
            page: 1,
            limit: 40,
            search: "",
            orden: "",
            sucursal: null,
            usuario: {},
        },
        formEventos: {
            idEvento: null,
            idImage: null,
            preview: null,
            prevUrl: null,
            nombre: null,
            fecha: null,
            sucursal: {},
            descripcion: null
        },
        usuarios: {
            page: 1,
            limit: 40,
            search: "",
            orden: ""
        },
        formUsuarios: {
            idUsuario: null,
            idRol: null,
            preview: null,
            prevUrl: null,
            nombre: null,
            correo: null,
            telefono: null,
            rol: null,
        },
        categorias: {
            page: 1,
            limit: 40,
            search: "",
            orden: ""
        },
        formCategorias: {
            idCategoria: null,
            nombre: null,
            tipo: null
        },
        estadios: {
            page: 1,
            limit: 40,
            search: "",
        },
        formEstadios: {
            idEstadio: null,
            idDom: null,
            preview: null,
            prevUrl: null,
            nombre: null,
            calle: null,
            interior: null,
            entrecalles: null,
            referencias: null,
            tipo: "Estadio"
        },
        franquicias: {
            page: 1,
            limit: 40,
            search: "",
            orden: ""
        },
        formFranquicias: {
            idFranquicia: null,
            idDom: null,
            preview: null,
            prevUrl: null,
            nombre: null,
            calle: null,
            interior: null,
            entrecalles: null,
            referencias: null,
            giro: null,
            tipo: "Franquicia"
        },
        sucursales: {
            page: 1,
            limit: 40,
            search: "",
            orden: "",
            franquicia: null,
        },
        formSucursales: {
            idSucursal: null,
            preview: null,
            prevUrl: null,
            local: null,
            cobertura: null,
            nombre: null,
            giro: null,
            localSucursal: {},
            franquiciaSucursal: {},
            tipo: "Sucursal"
        },
        lugarMapa: {
            eventoId: null,
            sucursalId: null
        },
        sesion: {
            corrobora: true,
        },

    },
    reducers: {
        setHandler: (state, actions) => {
            const payload = actions.payload;
            state[payload.state] = {
                ...state[payload.state],
                ...payload.content
            };
        },
        setHandlerQueryProductos: (state, actions) => {
            const payload = actions.payload;
            state.productos = {
                ...state.productos,
                ...payload
            };
        },
        setFormProductos: (state, actions) => {
            const payload = actions.payload;
            state.formProductos = {
                ...state.formProductos,
                ...payload
            };
        },
        setHandlerQueryEventos: (state, actions) => {
            const payload = actions.payload;
            state.eventos = {
                ...state.eventos,
                ...payload
            };
        },
        setFormEventos: (state, actions) => {
            const payload = actions.payload;
            state.formEventos = {
                ...state.formEventos,
                ...payload
            };
        }
    }
})

export const { setHandlerQueryProductos, setHandlerQueryEventos, setFormProductos, setFormEventos, setHandler } = HandlerQuerySlice.actions
export default HandlerQuerySlice.reducer