import { MiPerfil, PerfilCard, PerfilDetalleSection, PerfilDetalleInfo, ChipEstatus, Chip } from "eureka-design"
import { useGetUsuarioQuery } from "../../js/service/usuarios"
import { useEffect, useState } from 'react';
import { obtenerFoto, obtenerRol } from "../../js/util"

export default function Usuario({ perfilUser, Push }) {
    const { data: perfil = {}, isLoading, isFetching } = useGetUsuarioQuery({ usuario: perfilUser })
    const [Portada, setPortada] = useState()

    useEffect(() => {
        if (perfil) {
            if (perfil?.foto) {
                setPortada(`https://crud.eksuite.com/api/GetFile/ft_usuario/${perfil?.foto}`)
            }
        }
    }, [perfil]);

    const Estatus = () => {
        let chip;
        if (perfil?.estatus === "activo") {
            chip = <ChipEstatus texto={"Aceptado"} tipo="Aceptado" />
        }

        if (perfil?.estatus === "inactivo") {
            chip = <ChipEstatus texto={"Cancelado"} tipo="Error" />
        }

        if (perfil?.estatus === "pendiente") {
            chip = <ChipEstatus texto={"Pendiente"} tipo="Pendiente" />
        }
        return chip;
    }

    return (<PerfilCard
        avatar={Portada}
        titulo={perfil?.nombre}
        errorImage="/img/noimage.jpg"
        aspectRadio={"7/2"}
    >
        <PerfilDetalleInfo titulo="Información del usuario"
            boton={
                {
                    texto: "Editar",
                    icono: "fa-solid fa-file-edit",
                    fn: () => {
                        Push('formUsuarios', {
                            userid: perfil.id,
                            foto: null,
                            nombre: perfil.nombre,
                            correo: perfil.correo,
                            telefono: perfil.telefono,
                            prevFile: undefined,
                            prevUrl: `https://crud.eksuite.com/api/GetFile/ft_usuario/${perfil.foto}`
                        }, "Usuarios")

                    }
                }
            }>
            <PerfilDetalleSection titulo={"Correo"}>
                <span>{perfil?.correo ?? "Sin correo"}</span>
            </PerfilDetalleSection>
            <PerfilDetalleSection titulo={"Teléfono"}>
                <span>{perfil?.telefono ?? "Sin teléfono"}</span>
            </PerfilDetalleSection>
            <PerfilDetalleSection titulo={"Estatus actual del usuario"}>
                {
                    Estatus()
                }
            </PerfilDetalleSection>
            <PerfilDetalleSection subTile={"Roles a los que pertenece"}>

            </PerfilDetalleSection>
            <div style={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
                flexWrap: "wrap"
            }}>          {perfil?.eureka_cr_rol_usuarios?.map((item, index) => {
                return <Chip key={index} texto={item.get_rol?.nombre} icono={"fa-user-tag"}></Chip>
            })}

            </div>

        </PerfilDetalleInfo>
    </PerfilCard>)


}