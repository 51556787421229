import { Formulario, Input, ImageInput, Miniatura, InputSearch } from "eureka-design"
import { useCrearEventoMutation, useUploadImageEventosMutation } from "../../js/service/eventos"
import { useSearchEstadiosQuery, useSearchFranquiciasQuery } from "../../js/service/franquicias.js";
import { useEffect, useState } from 'react';
import { handlerMutation } from "../../js/util.js"


export default function FormEventos({ Push, UpProps, ShowAlert, evento, portada, prevUrl, prevFile, sucursal, nombre, fechas = null, descripcion, estadioName, usuario, roles }) {
    const [idEvento, setIdEvento] = useState(evento);
    const [idImage, setIdImage] = useState(portada);
    const [estadio, setEstadio] = useState(sucursal);
    const [searchEstadio, setSearchEstadio] = useState(null)
    const [valueEstadio, setValueEstadio] = useState(estadioName);
    const [fechaInput, setFechaInput] = useState(fechas !== null ? { date1: fechas?.date1 ?? null, date2: fechas?.date2 ?? null } : null)

    const [CrearEvento, { isLoading: inputIsloading, isError: inputIsError, isSuccess: inputIsSuccess }] = useCrearEventoMutation();
    const [uploadFoto, { isLoading: imageisLoading, isError: imageIsError, isSuccess: imageIsSuccess }] = useUploadImageEventosMutation();
    const { data: Estadios, isLoadingEstadios } = useSearchEstadiosQuery({ search: searchEstadio, is_acceso: usuario.id })

    const handlerInput = (value, nombre) => {
        if (nombre === 'fecha_inicio') {
            let fecha1 = new Date(value.date1)
            let fecha2 = new Date(value.date2)
            fecha1 = `${fecha1.getFullYear()}-${fecha1.getMonth() + 1}-${fecha1.getDate()} ${fecha1.getHours()}:${fecha1.getMinutes()}:00`
            fecha2 = `${fecha2.getFullYear()}-${fecha2.getMonth() + 1}-${fecha2.getDate()} ${fecha2.getHours()}:${fecha2.getMinutes()}:00`
            handlerMutation(CrearEvento, { id: idEvento, body: { fecha_inicio: fecha1, usuario: usuario.id } }, (r) => {
                setIdEvento(r?.mensaje?.id ?? idEvento)
                UpProps({ evento: r?.mensaje?.id ?? idEvento })
                handlerMutation(CrearEvento, { id: r?.mensaje?.id ?? idEvento, body: { fecha_termino: fecha2, usuario: usuario.id } })
            })
            UpProps({ ['fecha_inicio']: fecha1, ['fecha_termino']: fecha2 })
            return null;
        }

        handlerMutation(CrearEvento, { id: idEvento, body: { [nombre]: value, usuario: usuario.id } }, (r) => {
            if (r?.mensaje?.id) {
                setIdEvento(r.mensaje.id)
                UpProps({ evento: r.mensaje.id })
            }
        })
        UpProps({ [nombre]: value })
    }

    const handlerPhoto = (e) => {
        handlerMutation(uploadFoto, { id: idImage, file: e.target.files[0], carpeta: "ft_eventos", nombre: "archivo" }, (r) => {
            if (r?.mensaje?.id) {
                setIdImage(r.mensaje.id)
                UpProps({ portada: r.mensaje.id })
                handlerInput(r.mensaje.id, "portada")
            }
        })
        UpProps({ prevFile: e.target.files[0] })
    }


    const handlerEstadios = () => {
        let estadios = Estadios?.map((item, index) => {
            return {
                value: item,
                text: item.nombre,
                icono: () => {
                    return <Miniatura
                        key={index}
                        avatar={true}
                        error={"/img/noimage.jpg"}
                        src={`https://crud.eksuite.com/api/GetFile/ft_estadios/${item.portada}`}
                    />
                }
            }
        });
        return estadios;
    }

    const getBotonesForm = () => {
        let botones = [];
        botones.push({
            iconLeft: " fa-dollar-sign",
            texto: "Precio por zonas",
            onClick: () => {
                if (estadio && estadio > 0) {
                    Push("mapas", { eventoId: idEvento, lugar: estadio }, "Lugares")
                } else {
                    ShowAlert({
                        titulo: "Espera...",
                        icono: "warning",
                        mensaje: "Debes seleccionar un estadio o lugar para continuar",
                        time: 5
                    })
                }


            }
        })
        return botones;
    }
    return (
        <Formulario titulo="Agrega un nuevo evento"
            isLoading={[inputIsloading, imageisLoading]}
            isError={[inputIsError, imageIsError]}
            isSuccess={[inputIsSuccess, imageIsSuccess]}
            botones={getBotonesForm()}>
            <ImageInput
                width={"100%"}
                height={"100%"}
                radio={[21, 9]}
                recomendado={[1000, 350]}
                changeFunction={handlerPhoto}
                preview={prevUrl}
                file={prevFile}
            >
            </ImageInput>
            <Input
                min={5}
                changeFunction={handlerInput}
                isLoading={inputIsloading}
                placeholder={"Nombre"}
                nombre={"nombre"}
                type={"text"}
                value={nombre}
                iconLeft={"fa-file-contract"}
            />
            <Input
                placeholder={"Fecha de inicio y fin"}
                nombre={"fecha_inicio"}
                changeFunction={handlerInput}
                isLoading={inputIsloading}
                type={"date"}
                value={fechaInput}
                dateConfig={{
                    timePicker: true,
                    rangePicker: true,
                    formato: "{S} {DD} de {MM} del {YYYY} {HH}:{Mm}",
                }}
                iconLeft={"fa-calendar"}
            ></Input>
            <Input
                placeholder={"Descripción"}
                changeFunction={handlerInput}
                isLoading={inputIsloading}
                nombre={"descripcion"}
                value={descripcion}
                type={"textarea"}
                iconLeft={"fa-file-contract"}
            >
            </Input>
            <InputSearch
                value={valueEstadio}
                changeFunction={(value) => {
                    setSearchEstadio(value)
                }}
                OpcionSelect={(item) => {
                    setValueEstadio(item.nombre)
                    setEstadio(item.id)
                    handlerInput(item.id, "sucursal")
                }}
                isLoading={isLoadingEstadios}
                opciones={Estadios ? handlerEstadios() : []}
                iconLeft={"fa-solid fa-map-marker"}
                placeholder={"Estadio o lugar"}
            />

        </Formulario>
    )
}
